import {ReactElement, useContext, useEffect, useState} from "react";
import {AuthContext, AuthStatus} from "../contexts/AuthContext";
import mixpanel from "mixpanel-browser";
import {MIX_PANEL_PROJECT_ID} from "../constants/MixPanel";

export function MixPanelWrapper(props: {children: ReactElement | ReactElement[]}): ReactElement {
    const {user, authStatus} = useContext(AuthContext);
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        mixpanel.init(MIX_PANEL_PROJECT_ID);
        setInitialized(true);
    }, []);

    useEffect(() => {
        if(!initialized) {
            return;
        }

        if (user !== null && authStatus === AuthStatus.SignedIn) {
            mixpanel.identify(user.id);
            mixpanel.people.set({
                "$name": `${user.firstName} ${user.lastName}`,
                "$email": user.email,
                "companyId": user.clientId,
                "company": user.clientName
            });
            return () => {
                mixpanel.reset();
            };
        }
    }, [initialized, user, authStatus]);


    return <>{props.children}</>;
}