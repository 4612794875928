import {ReactElement, useContext, useEffect, useState} from "react";
import Intercom from "@intercom/messenger-js-sdk";
import {INTERCOM_APP_ID} from "../constants/Intercom";
import {AuthContext, AuthStatus} from "../contexts/AuthContext";

export function IntercomWrapper(props: {children: ReactElement | ReactElement[]}): ReactElement {
    const {user, authStatus} = useContext(AuthContext);
    const [intercomInitialized, setIntercomInitialized] = useState<boolean>(false);

    useEffect(() => {
        Intercom({
            app_id: INTERCOM_APP_ID
        });
        setIntercomInitialized(true);
    }, []);

    useEffect(() => {
        if(!intercomInitialized) {
            return;
        }

        if (user !== null && authStatus === AuthStatus.SignedIn && window.Intercom !== undefined) {
            // We have to use the direct `window.Intercom` here. The "wrapped methods" they provide
            // keep a ref that holds the wrong object when reloading the page. This causes Intercom
            // to not properly reset.
            // I don't know why it happens, but it does.
            window.Intercom("shutdown");
            window.Intercom("boot", {
                app_id: INTERCOM_APP_ID,
                user_id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                company: {
                    id: user.clientId,
                    name: user.clientName,
                },
                email: user.email,
                clientId: user.clientId,
                companyName: user.clientName
            });
            return () => {
                if(window.Intercom !== undefined) {
                    window.Intercom("shutdown");
                    window.Intercom("boot", {
                        app_id: INTERCOM_APP_ID
                    });
                }
            };
        }
    }, [intercomInitialized, user, authStatus]);


    return <>{props.children}</>;
}