import React, {useEffect, useState} from 'react';
import {BudgetingType} from '../../BudgetingType';
import {VarianceType} from "../../VarianceType";
import {BudgetComponentType, GetFinancialValuesQuery, useGetFinancialValuesLazyQuery} from '../../__generated__/generated_types';
import {Line, LineProps} from './Line';
import {fillLineData} from './Helpers';
import './AnnualSummary.scss';
import {useProperties} from '../../contexts/properties/PropertiesContext';
import Loading from '../loading/Loadiing';
import YearSelector from '../year-selector/YearSelector';
import useYearDefinitions from './YearDefinitions';
import {useSettings} from '../../contexts/settings/SettingsContext';
import useGetMonths from '../../hooks/UseGetMonths';
import {FinancialEntity, useChartOfAccounts} from '../../contexts/chartofaccounts/ChartOfAccountsContext';
import {useVersions, VersionsState} from '../../contexts/versions/VersionsContext';
import {createRequestVariables} from '../../contexts/chartofaccounts/CreateFinancialValuesQueryVariables';


export interface AnnualSummaryProps {
    tab: BudgetingType,
    refreshTriggered: boolean;
    refreshCompletedCallback: () => void;
}


export const AnnualSummary: React.FC<AnnualSummaryProps> = (props: AnnualSummaryProps) => {

    const months = useGetMonths();
    const [varianceSelectedValue, setVarianceSelectedValue] = useState(VarianceType.VARIANCE_PERCENT);
    const isReforecast = props.tab === BudgetingType.REFORECAST;
    const {currentProperty: property} = useProperties();
    const {year: currentYear, startReforecastMonthIndex} = useSettings();
    const {getModelVersions} = useVersions();
    const {currentDefinition: referenceYear} = useYearDefinitions();
    const {chartOfAccounts} = useChartOfAccounts();

    const [getFinancialValues, {loading, error, data}] = useGetFinancialValuesLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!chartOfAccounts || !property) {
            return;
        }

        getFinancialValues({
            variables: createRequestVariables(chartOfAccounts.concat(chartOfAccounts.flatMap(row => row.children)), property.id, startReforecastMonthIndex, currentYear, getModelVersions)
        });
    }, [chartOfAccounts, property]
    );

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <>{error}</>;
    }

    function toLineProps(data: GetFinancialValuesQuery): LineProps[] {
        const lineProps: LineProps[] = [];

        if (!chartOfAccounts || loading) {
            return [];
        }
        const {actualVersionId, reforecastVersionId, nextYearBudgetVersionId, currentYearTotalVersionId} = getModelVersions(currentYear);
        const valuesByEntityAndVersion = data.financialValues.financialValues.groupBy(row => row.entityId + "#" + row.versionId);
        const totalValuesByEntityAndVersion = data.financialValues.financialTotalValues.groupBy(row => row.entityId + "#" + row.versionId);
        chartOfAccounts.forEach(component => {
            const isPositiveGood = component.budgetComponentType === BudgetComponentType.Income;
            let previousTotal = Number(totalValuesByEntityAndVersion[component.id + "#" + referenceYear.versionId]?.firstElement?.value ?? "0");
            if(referenceYear.versionId === currentYearTotalVersionId) {
                const reforecastValues = [
                    ...(valuesByEntityAndVersion[component.id + "#" + actualVersionId] ?? []),
                    ...(valuesByEntityAndVersion[component.id + "#" + reforecastVersionId] ?? [])
                ];
                previousTotal = reforecastValues.map(rv => Number(rv.value ?? 0.0)).sum();
            }

            const values = isReforecast ?
                [
                    ...(valuesByEntityAndVersion[component.id + "#" + actualVersionId] ?? []),
                    ...(valuesByEntityAndVersion[component.id + "#" + reforecastVersionId] ?? [])
                ]
                :
                valuesByEntityAndVersion[component.id + "#" + nextYearBudgetVersionId] ?? [];
            const total = values.map(v => Number(v.value ?? 0.0)).sum();
            const lineData = fillLineData(
                values.map(row => ({monthIndex: row.monthIndex, value: Number(row.value)})),
                total,
                previousTotal,
                component
            );

            component.children.forEach(category => {
                let previousTotal = Number(totalValuesByEntityAndVersion[category.id + "#" + referenceYear.versionId]?.firstElement?.value ?? "0");
                if(referenceYear.versionId === currentYearTotalVersionId) {
                    const reforecastValues = [
                        ...(valuesByEntityAndVersion[category.id + "#" + actualVersionId] ?? []),
                        ...(valuesByEntityAndVersion[category.id + "#" + reforecastVersionId] ?? [])
                    ];
                    previousTotal = reforecastValues.map(rv => Number(rv.value ?? 0.0)).sum();
                }

                const values = isReforecast ? [
                    ...(valuesByEntityAndVersion[category.id + "#" + actualVersionId] ?? []),
                    ...(valuesByEntityAndVersion[category.id + "#" + reforecastVersionId] ?? [])
                ]
                    :
                    valuesByEntityAndVersion[category.id + "#" + nextYearBudgetVersionId] ?? [];
                const total = values.map(v => Number(v.value ?? 0.0)).sum();
                const lineData = fillLineData(
                    values.map(row => ({monthIndex: row.monthIndex, value: Number(row.value)})),
                    total,
                    previousTotal,
                    category
                );


                if (lineData) {
                    // lineData.categoryId = category.id;
                    lineData.financialEntity = category;

                    lineProps.push({
                        isPositiveGood,
                        data: lineData,
                        tab: props.tab,
                        varianceType: varianceSelectedValue,
                        refreshTriggered: props.refreshTriggered,
                        refreshCompletedCallback: props.refreshCompletedCallback,
                    });
                }
            });
            if (lineData) {
                // lineData.isComponent = true;
                // lineData.componentId = component.id;
                lineData.financialEntity = component;
                lineProps.push({
                    isPositiveGood,
                    data: lineData,
                    tab: props.tab,
                    varianceType: varianceSelectedValue,
                    refreshTriggered: props.refreshTriggered,
                    refreshCompletedCallback: props.refreshCompletedCallback,
                });
            }

        });

        return lineProps;
    }

    return (
        <div className="as-table-wrapper">
            <table className='table-annual-summary'>
                <thead className="table-annual-summary-head">
                    <tr className="as-trow">
                        <th className="th-description"> Description </th>
                        {months.map((month, index) =>
                            <th key={index} className="th-months">
                                {month}
                            </th>
                        )}
                        <th className="as-header-total"> Total </th>
                        <th className="as-header-reference-total">
                            <YearSelector variant="standard" />
                        </th>
                        <th>
                            <select className="annual-summary-select" onChange={(event) => setVarianceSelectedValue(event.target.value as VarianceType)}>
                                <option value={VarianceType.VARIANCE_PERCENT}> Var % </option>
                                <option value={VarianceType.VARIANCE_AMOUNT}> Var $ </option>
                            </select>
                        </th>
                        <th style={{textAlign: "center"}}>Notes</th>
                    </tr>

                </thead>
                <tbody className="as-tbody">
                    {data && ((() => {
                        const lineProps = toLineProps(data);

                        return lineProps.map((line, index) =>
                            <Line
                                key={index}
                                data={line.data}
                                isPositiveGood={line.isPositiveGood}
                                tab={line.tab}
                                varianceType={varianceSelectedValue}
                                refreshTriggered={props.refreshTriggered}
                                refreshCompletedCallback={props.refreshCompletedCallback}
                            />
                        );
                    })()
                    )}
                </tbody>
            </table>
        </div>

    );
};



