import React, {ReactElement, useState} from 'react';
import BudgetCategoriesTree, {AddCategoryAction, TreeNode}
  from '../../../components/budget-category-tree/BudgetCategoriesTree';
import Footer from '../../../components/footer/Footer';
import ProgressList from '../../../components/progress-list/ProgressList';
import ModalWindow from '../../../components/modal/ModalWindow';
import './BudgetCategories.css';
import { useRoutingUtils } from '../../../routes/RoutingUtils';

const REFORECAST_DATA: TreeNode[] = [
  {
    id: 1000,
    title: 'Income',
    categoryAction: AddCategoryAction.CATEGORY,
    children: [
      {
        id: 1100,
        title: 'Effective Rental Income',
        categoryAction: AddCategoryAction.SUBCATEGORY,
        children: [
          {
            id: 1110,
            title: 'Gross Potential Rent',
            categoryAction: AddCategoryAction.NONE,
          },
          {
            id: 1120,
            title: 'Rent Concessions & Discounts',
            categoryAction: AddCategoryAction.NONE,
          },
        ],
      },
      {
        id: 1200,
        title: 'Total Commercial Income',
        categoryAction: AddCategoryAction.NONE,
        children: [
          {
            id: 1210,
            title: 'Gross Potential Rent',
            categoryAction: AddCategoryAction.NONE,
          },
          {
            id: 1220,
            title: 'Rent',
            categoryAction: AddCategoryAction.NONE,
          },
        ],
      },
      {
        id: 1300,
        title: 'Total Other Property Income',
        categoryAction: AddCategoryAction.SUBCATEGORY,
        children: [
          {
            id: 1310,
            title: 'Gross Potential Rent',
            categoryAction: AddCategoryAction.NONE,
          },
          {
            id: 1320,
            title: 'Rent',
            categoryAction: AddCategoryAction.NONE,
          },
          {
            id: 1330,
            title: 'Expenses',
            categoryAction: AddCategoryAction.NONE,
          },
        ],
      },
    ],
  },
  {
    id: 2000,
    title: 'Expenses',
    categoryAction: AddCategoryAction.CATEGORY,
    children: [
      {
        id: 2100,
        title: 'Expenses',
        categoryAction: AddCategoryAction.NONE,
      },
      {
        id: 2200,
        title: 'Rent',
        categoryAction: AddCategoryAction.NONE,
      },
    ],
  },
  {
    id: 3000,
    title: 'Net operating income',
    categoryAction: AddCategoryAction.CATEGORY,
    children: [
      {
        id: 3100,
        title: 'Netincome',
        categoryAction: AddCategoryAction.NONE,
      },
    ],
  },
];

/**
 * Budget categories component
 *
 * @return {ReactElement}
 */
export default function BudgetCategories(): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const {goto} = useRoutingUtils();

  return(
    <div>
      <ProgressList selected={2}/>
      <div className="container text-grey20">
          <div className="budget-categories-wrapper w-75 bg-white px-4 py-4">
              <div className="pb-3 fs-4"> Define Budget Categories </div>
              <BudgetCategoriesTree nodes={REFORECAST_DATA}/>
          </div>
      </div>
      <Footer backText="Back" nextText="Next Step" needGoBack={() => goto("/admin/setup/budget")}/>
      <ModalWindow isOpen={isModalOpen} primaryText="Create" title={`Add category to `} onClose={closeModal} canConfirm={true}>
          <div className="text-start pb-3"> Category Name (required) </div>
          <input type="text" className="form-control" placeholder="Category Name"/>
      </ModalWindow>
    </div>
  );
}

