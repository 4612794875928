import React, {ReactElement, useState} from 'react';
import {FaEquals, FaMinus} from 'react-icons/fa';
import {BsPlus} from 'react-icons/bs';
import ProgressList from '../../../components/progress-list/ProgressList';
import Footer from '../../../components/footer/Footer';
import ModalWindow from '../../../components/modal/ModalWindow';
import { useRoutingUtils } from '../../../routes/RoutingUtils';

/**
 * Define budget page
 * @return {ReactElement}
 */
export default function DefineBudget(): ReactElement {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(!isModalOpen);
  const {goto} = useRoutingUtils();

  return (
    <div>
      <ProgressList selected={1}/>
      <div className="container">
          <div className="basic-details bg-white pt-3 pb-2 ps-5">
              <div className="text-start pt-2 pb-4 fs-4"> Define Budget Components </div>
              <div className="mb-3 w-25 fs-5">
                  <input type="text" className="form-control bg-bg-grey fs-5" value="Income"/>
                  <label className="form-label py-2 ps-2"> <FaMinus className="bg-bg-grey rounded-circle fs-5 px-1"/> minus </label>
                  <input type="text" className="form-control bg-bg-grey fs-5" value="Expenses"/>
                  <label className="form-label py-2 ps-2"> <FaEquals className="bg-bg-grey rounded-circle fs-5 px-1"/> equal </label>
                  <input type="text" className="form-control bg-bg-grey fs-5" value="Net operating income"/>
                  <button className="border-0 bg-white text-primary mt-4 mb-0" onClick={openModal}> <BsPlus/> add component </button>
              </div>
          </div>
      </div>
      <Footer
        backText="Back"
        nextText="Next Step"
        needGoBack={goto("/admin/setup/basic")}
        needGoNext={goto("/admin/setup/categories")}/>
      <ModalWindow isOpen={isModalOpen} primaryText="Create" title="Create new component" onClose={closeModal} canConfirm={true}>
          <div className="text-start pb-3"> Component Name (required) </div>
          <input type="text" className="form-control fs-5" placeholder="Component Name"/>
      </ModalWindow>
    </div>
  );
}
