import {useEffect, useState} from "react";
import {AccountsTopCardTagsModel, TopCardTagType, useGetAccountsTopCardTagsLazyQuery, useSetAccountsTopCardTagsMutation} from "../../../../__generated__/generated_types";

export type TTopCardTagSettings = {
    data: AccountsTopCardTagsModel[],
    loading: boolean,
    initialized: boolean,
    fetch: (accountIds: string[], topCardTagTypes: TopCardTagType[]) => void,
    set: (accountIds: string[], topCardTagType: TopCardTagType) => void,
}

export default function useTopCardTagSettings(): TTopCardTagSettings {

    const [initialized, setInitialized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    let requestDebounce: (ReturnType<typeof setTimeout> | null) = null;

    const [_fetch, {data, loading: fetchLoading}] = useGetAccountsTopCardTagsLazyQuery({
        fetchPolicy: "network-only",
        onCompleted: () => {
            setInitialized(true);
        }
    });
    const [_set, {loading: updateLoading}] = useSetAccountsTopCardTagsMutation({
        onCompleted: () => {
            fetch([], []);
        }
    });

    function fetch(accountIds: string[], topCardTagTypes: TopCardTagType[]) {
        if (requestDebounce) {
            clearTimeout(requestDebounce);
        }

        requestDebounce = setTimeout(() => {
            _fetch({
                variables: {
                    accountIds,
                    topCardTagTypes,
                }
            });
        }, 1000);
    }

    function set(accountIds: string[], topCardTagType: TopCardTagType) {
        if (requestDebounce) {
            clearTimeout(requestDebounce);
        }

        requestDebounce = setTimeout(() => {
            _set({
                variables: {
                    accountIds,
                    topCardTagType,
                }
            });
        }, 1000);
    }

    useEffect(
        () => {
            setLoading(fetchLoading || updateLoading);
        },
        [fetchLoading, updateLoading]
    );

    return {
        data: data?.getAccountsTopCardTags ?? [],
        loading,
        initialized,
        fetch,
        set,
    };
}