import React, {ReactElement, useEffect, useState} from 'react';
import StaffDetails from './sections/StaffDetails';
import TabPositionDetails from "./sections/TabPositionDetails";
import TabEarnings from "./sections/TabEarnings";
import TabBenefits from "./sections/TabBenefits";
import TabTaxesAndInsurance from "./sections/TabTaxesAndInsurance";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Modal as ReactModal} from "react-bootstrap";
import styles from "./AnalystPayrollDrawer.module.scss";
import {Close as ClosedRN, Notification, Title} from '@zendeskgarden/react-notifications';
import {
    CreateEmployeeMutationVariables,
    GetPayrollCompensationItemsQuery,
    PayrollBonusFrequency,
    PayrollBonusType,
    PayrollCompensationItemType,
    PayrollCompensationType,
    PayrollCreateEmployeeBonusInput,
    PayrollCreateEmployeeCompItemInput,
    PayrollCreateEmployeeOvertimeInput,
    PayrollCreateEmployeeRaiseInput,
    PayrollEmployeeClassification,
    PayrollModeledEmployeeType,
    PayrollRaiseFrequency,
    PayrollRaiseType,
    useGetPayrollCompensationItemsQuery,
    useGetPayRolPositionsQuery
} from "../../__generated__/generated_types";
import {Col, Grid, Row} from '@zendeskgarden/react-grid';
import {Tab, TabList, TabPanel, Tabs} from '@zendeskgarden/react-tabs';
import {useGetEmployee} from "./data/UseGetEmployee";
import {
    DEFAULT_NEW_EMPLOYEE_ID,
    LocalPayrollEmployeeCompItemModel,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys,
    PayrollDrawerSections,
    PayrollDrawerSectionsDisplayName
} from "./helpers/types";
import { Property, useProperties } from "../../contexts/properties/PropertiesContext";
import useCreateUpdateDeleteEmployee from "./data/UseCreateUpdateDeleteEmployee";
import {dateUtcNow} from "./date-helpers";
import {usePayrollDefaults} from "./data/UsePayrollDefaults";
import { TListPayrollPositions } from '../../contexts/account/data/utils';
import { TLockedProperties } from '../../pages/bulk-update/BulkUpdate';


interface AnalystPayrollDrawerProps {
    employeeID: string, // This needs to a blank string in case of add new employee
    // Used for multi-year, will need to be changed to plan id for plan budgeting.
    budgetYear: number,
    show: boolean,
    handleClose: (wasAnyUpdatesPersistedOnBE: boolean) => void,
    lockedProperties: TLockedProperties,
    filterPropertyIds: string[] | undefined, // filter out properties for relevant cases
}

export default function AnalystPayrollDrawer(props: AnalystPayrollDrawerProps): ReactElement {
    const [internalShow, setInternalShow] = useState<boolean>(props.show);
    const [showAlert, setShowAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showEmployeeCreateUpdateNotification, setShowEmployeeCreateUpdateNotification] = useState<boolean>(false);
    const [employeeCreateUpdateNotificationMessage, setEmployeeCreateUpdateNotificationMessage] = useState<string>("");
    const [showEmployeeFormValidationNotification, setShowEmployeeFormValidationNotification] = useState<boolean>(false);
    const [employeeFormValidationNotificationMessage, setEmployeeFormValidationNotificationMessage] = useState<string>("");
    const [employeeFormValidationNotificationTimeoutHandler, setEmployeeFormValidationNotificationTimeoutHandler] = useState<any|undefined>(undefined);
    const {properties: propertiesData, loaded: propertiesDataLoaded} = useProperties();
    const [localProperties, setLocalProperties] = useState<Property[]>([]);
    const [wasAnyUpdatesPersistedOnBE, setWasAnyUpdatesPersistedOnBE] = useState<boolean>(false);
    const {
        data: payrollPositionsData,
        loading: payrollPositionsDataLoading,
        error: payrollPositionsDataError
    } = useGetPayRolPositionsQuery();

    const [localPayrollPositionsData, setLocalPayrollPositionsData] = useState<TListPayrollPositions>();

    const {
        data: payrollCompensationItemsData,
        loading: payrollCompensationItemsDataLoading,
        error: payrollCompensationItemsDataError
    } = useGetPayrollCompensationItemsQuery();

    const [localPayrollCompensationItems, setLocalPayrollCompensationItems] = useState<GetPayrollCompensationItemsQuery["listCompensationItems"] | undefined>(undefined);

    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(undefined);
    const [localEmployeeData, setLocalEmployeeData] = useState<LocalPayrollEmployeeModel | undefined>(undefined);

    // I am inclined to run this every render cycle rather than adding another useEffect
    // to see "are we ready to call payroll defaults". This nasty alternative would also
    // mean we would want to expose controls of defaults fetch to the client code.
    const payrollDefaults = usePayrollDefaults({
        shouldLoadDefaults: selectedEmployeeId == "", // "" means new employee mode. DUH!
        propertyIds: localEmployeeData?.properties.map(p => p.id) ?? [],
        positionId: localEmployeeData?.position?.id
    });
    const {isDataReady: isEmployeeDataReady, refreshEmployeeData, getData: getEmployeeData} = useGetEmployee();

    const payrollEmployeeDrawerData = useCreateUpdateDeleteEmployee();
    const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<PayrollDrawerSections>(PayrollDrawerSections.positionDetails);

    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                setInternalShow(true);
            }, 600);
        } else {
            // This timeout is set to match the time
            // for the animation. This prevents, the
            // hard disappearance before the animation
            // completes.
            setTimeout(() => {
                setInternalShow(false);
            }, 600);
        }
    }, [props.show]);

    useEffect(() => {
        if (payrollPositionsDataLoading && !payrollPositionsData && payrollPositionsDataError) {
            return;
        }
        // console.info(`listPayrollPositions: ${JSON.stringify(payrollPositionsData?.listPayrollPositions)}`);
        setLocalPayrollPositionsData(payrollPositionsData?.listPayrollPositions);
    }, [payrollPositionsDataLoading, payrollPositionsData, payrollPositionsDataError]);

    useEffect(() => {
        if (payrollCompensationItemsDataLoading && !payrollCompensationItemsData && payrollCompensationItemsDataError) {
            return;
        }
        setLocalPayrollCompensationItems(payrollCompensationItemsData?.listCompensationItems);
    }, [payrollCompensationItemsDataLoading, payrollCompensationItemsData, payrollCompensationItemsDataError]);

    useEffect(() => {
        if (!propertiesDataLoaded || !propertiesData) {
            return;
        }
        const propertiesFilter = props.filterPropertyIds;
        if (propertiesFilter != undefined) {
            setLocalProperties(propertiesData.filter(row => propertiesFilter.includes(row.id)));
        }
        else {
            setLocalProperties(propertiesData);
        }
    }, [propertiesDataLoaded, propertiesData]);

    useEffect(() => {
        setSelectedEmployeeId((props.employeeID));
    }, [props.employeeID]);

    useEffect(() => {
        if (payrollEmployeeDrawerData.employeeId !== "") {
            setSelectedEmployeeId((payrollEmployeeDrawerData.employeeId));
        }
    }, [payrollEmployeeDrawerData.employeeId]);

    useEffect(() => {
        if (!propertiesDataLoaded || !propertiesData || !localPayrollPositionsData || !localPayrollCompensationItems) {
            return;
        }
        if (selectedEmployeeId === undefined) {
            console.info(`The props.employeeID: ${props.employeeID} has not properly been set yet`);
        } else if (typeof selectedEmployeeId === 'string' && selectedEmployeeId.length === 36) {
            // console.info(`The non blank value of selectedEmployeeId means an existing employee.`);
            // console.info(`Going to refresh employeeData for ${selectedEmployeeId}`);
            refreshEmployeeData(selectedEmployeeId);
        } else if (selectedEmployeeId === "") {
            // console.info(`The blank value of selectedEmployeeId means to add a brand new employee.`);
            // create a fresh new localEmployeeData
            setLocalEmployeeData({
                id: DEFAULT_NEW_EMPLOYEE_ID,
                firstName: "",
                firstNameFieldTouched: false,
                lastName: "",
                externalSystemEmployeeId: "",
                type: undefined,
                typeTouched: false,
                position: undefined,
                positionTouched: false,
                classification: undefined,
                startDate: undefined,
                terminationDate: undefined,
                properties: [],
                propertiesTouched: false,
                baseComp: {
                    annualCompensation: "",
                    compensationType: PayrollCompensationType.Annual,
                    hourlyCompensation: "",
                    expectedHoursPerWeek: ""
                },
                raises: [],
                bonuses: [],
                overtimes: [],
                compItems: localPayrollCompensationItems?.reduce((acc: Record<PayrollCompensationItemType, Array<LocalPayrollEmployeeCompItemModel>>, currItem) => {
                    acc[currItem.type] = [];
                    return acc;
                }, {} as Record<PayrollCompensationItemType, Array<LocalPayrollEmployeeCompItemModel>>) ?? {}
            });
        }
    }, [propertiesDataLoaded, propertiesData, localPayrollPositionsData, localPayrollCompensationItems, selectedEmployeeId]);

    useEffect(() => {
        if (!isEmployeeDataReady || !localPayrollCompensationItems) {
            return;
        }
        const employeeData = getEmployeeData();
        // console.info(`New EmployeeData: ${JSON.stringify(employeeData)}`);
        if (employeeData) {
            const newLocalEmployeeData: LocalPayrollEmployeeModel = {
                id: employeeData.id,
                firstName: employeeData.firstName,
                firstNameFieldTouched: false,
                lastName: employeeData.lastName ?? "",
                externalSystemEmployeeId: employeeData.externalSystemEmployeeId,
                type: employeeData.type,
                typeTouched: false,
                position: {
                    id: employeeData.position.id,
                    name: employeeData.position.name,
                },
                positionTouched: false,
                classification: employeeData.classification,
                startDate: employeeData.startDate,
                terminationDate: employeeData.terminationDate,
                properties: employeeData.properties.map((property: Pick<Property, 'id' | 'name'>): Pick<Property, 'id' | 'name'> => {
                    return {
                        id: property.id,
                        name: property.name
                    };
                }),
                propertiesTouched: false,
                baseComp: {
                    annualCompensation: employeeData.baseComp?.annualCompensation,
                    compensationType: employeeData.baseComp?.compensationType,
                    hourlyCompensation: employeeData.baseComp?.hourlyCompensation,
                    expectedHoursPerWeek: employeeData.baseComp?.expectedHoursPerWeek
                },
                raises: employeeData.raises.map(raise => {
                    return {
                        amount: raise.amount,
                        frequency: raise.frequency,
                        raiseType: raise.raiseType,
                        raiseDay: raise.raiseDay?.toString() ?? "",
                        raiseMonth: raise.raiseMonth?.toString() ?? "",
                        effectiveStartDate: raise.effectiveStartDate ?? undefined,
                        onetimeRaiseDate: raise.onetimeRaiseDate ?? undefined,
                    };
                }),
                bonuses: employeeData.bonuses.map(bonus => {
                    return {
                        amount: bonus.amount,
                        frequency: bonus.frequency,
                        bonusType: bonus.bonusType,
                        bonusDay: bonus.bonusDay?.toString() ?? "",
                        bonusMonth: bonus.bonusMonth?.toString() ?? "",
                        effectiveStartDate: bonus.effectiveStartDate ?? undefined,
                        onetimeBonusDate: bonus.onetimeBonusDate ?? undefined,
                    };
                }),
                overtimes: employeeData.overtime.map(ot => {
                    return {
                        effectiveStartDate: ot.effectiveStartDate ?? undefined,
                        effectiveEndDate: ot.effectiveEndDate ?? undefined,
                        expectedHoursPerWeek: ot.expectedHoursPerWeek ?? "",
                        hourlyCompensation: ot.hourlyCompensation ?? ""
                    };
                }),
                compItems: employeeData.compItems.reduce((acc: Record<PayrollCompensationItemType, Array<LocalPayrollEmployeeCompItemModel>>, compItem) => {
                    const type = compItem.compensationItem.type;
                    acc[type] = acc[type] ?? [];
                    acc[type].push({
                        id: compItem.id,
                        compensationItem: {
                            id: compItem.compensationItem.id,
                            type: compItem.compensationItem.type,
                            customName: compItem.compensationItem.customName ?? ""
                        },
                        annualCostCap: compItem.annualCostCap,
                        costPerPayPeriod: compItem.costPerPayPeriod,
                        effectiveStartDate: compItem.effectiveStartDate,
                        calculationType: compItem.calculationType
                    });
                    return acc;
                }, {} as Record<PayrollCompensationItemType, Array<LocalPayrollEmployeeCompItemModel>>)
            };
            setLocalEmployeeData(newLocalEmployeeData);
            // console.info(`localEmployeeData: ${JSON.stringify(newLocalEmployeeData.raises)}`);
        }
    }, [isEmployeeDataReady, localPayrollCompensationItems]);


    useEffect(() => {
        // We only care to update employee in-memory state when there are payroll defaults
        // and we deal with new employee creation (selectedEmployeeId == "")
        if (!payrollDefaults.isReady || selectedEmployeeId != "" || !localEmployeeData) {
            return;
        }

        const updated = payrollDefaults.applyDefaults(localEmployeeData);
        setLocalEmployeeData(updated);
    }, [payrollDefaults.isReady, selectedEmployeeId, localEmployeeData == undefined]);


    const onTabChange = (item: PayrollDrawerSections) => {
        if (selectedTab === PayrollDrawerSections.positionDetails && item !== PayrollDrawerSections.positionDetails) {
            updateLocalEmployeeData(LocalPayrollEmployeeModelKeys.attemptTabChangeFromPositionDetails, null);
            if (localEmployeeData?.firstName && localEmployeeData?.type && localEmployeeData.position && localEmployeeData.properties && localEmployeeData.properties.length !== 0) {
                // console.info(`Tab change allowed`);
                setSelectedTab(item);
            } else {
                // console.info(`Tab change not allowed ${employeeFormValidationNotificationTimeoutHandler}`);
                // TODO merge it with the enum PayrollDrawerSections and keep a consolidated type
                let newTabDisplayName:PayrollDrawerSectionsDisplayName;
                if (item === PayrollDrawerSections.earnings) {
                    newTabDisplayName = PayrollDrawerSectionsDisplayName.earnings;
                } else if(item === PayrollDrawerSections.benefits) {
                    newTabDisplayName = PayrollDrawerSectionsDisplayName.benefits;
                } else {
                    newTabDisplayName = PayrollDrawerSectionsDisplayName.taxesAndInsurance;
                }
                setEmployeeFormValidationNotificationMessage(`Please complete the required fields before moving on to ${newTabDisplayName}.`);
                setShowEmployeeFormValidationNotification(true);
                if (employeeFormValidationNotificationTimeoutHandler) {
                    clearTimeout(employeeFormValidationNotificationTimeoutHandler);
                }
                const newTimeoutHandler = setTimeout(() => {
                    setShowEmployeeFormValidationNotification(false);
                }, 10000);
                setEmployeeFormValidationNotificationTimeoutHandler(newTimeoutHandler);
            }
        } else {
            setSelectedTab(item);
        }
    };

    useEffect(() => {
        /*console.info(`useEffect
            payrollEmployeeDrawerData.createApiStatus: ${payrollEmployeeDrawerData.createApiStatus}
            payrollEmployeeDrawerData.updateApiStatus: ${payrollEmployeeDrawerData.updateApiStatus}
            payrollEmployeeDrawerData.deleteApiStatus: ${payrollEmployeeDrawerData.deleteApiStatus}
        `);*/
        if((payrollEmployeeDrawerData.createApiStatus === "INIT" || payrollEmployeeDrawerData.createApiStatus === "COMPLETED") &&
            (payrollEmployeeDrawerData.updateApiStatus === "INIT" || payrollEmployeeDrawerData.updateApiStatus === "COMPLETED") &&
            (payrollEmployeeDrawerData.deleteApiStatus === "INIT" || payrollEmployeeDrawerData.deleteApiStatus === "COMPLETED")) {
            setHasUnsavedData(false);
        }
    }, [payrollEmployeeDrawerData.createApiStatus, payrollEmployeeDrawerData.updateApiStatus, payrollEmployeeDrawerData.deleteApiStatus]);

    useEffect(() => {
        /*console.info(`useEffect
            payrollEmployeeDrawerData.createApiStatus: ${payrollEmployeeDrawerData.createApiStatus}`);*/
        if(payrollEmployeeDrawerData.createApiStatus === "IN_PROGRESS") {
            setEmployeeCreateUpdateNotificationMessage("Staff member was successfully created.");
        } else if(payrollEmployeeDrawerData.createApiStatus === "COMPLETED") {
            setShowEmployeeCreateUpdateNotification(true);
            setTimeout(() => {
                setShowEmployeeCreateUpdateNotification(false);
            }, 10000);
        }
    }, [payrollEmployeeDrawerData.createApiStatus]);

    useEffect(() => {
        /*console.info(`useEffect
            payrollEmployeeDrawerData.updateApiStatus: ${payrollEmployeeDrawerData.updateApiStatus}`);*/
        if(payrollEmployeeDrawerData.updateApiStatus === "IN_PROGRESS") {
            setEmployeeCreateUpdateNotificationMessage("Staff member was successfully updated.");
        } else if(payrollEmployeeDrawerData.updateApiStatus === "COMPLETED") {
            setShowEmployeeCreateUpdateNotification(true);
            setTimeout(() => {
                setShowEmployeeCreateUpdateNotification(false);
            }, 10000);
        }
    }, [payrollEmployeeDrawerData.updateApiStatus]);

    const clearStates = () => {
        setShowAlert(false);
        setShowDeleteAlert(false);
        setShowEmployeeCreateUpdateNotification(false);
        setEmployeeCreateUpdateNotificationMessage("");
        setSelectedEmployeeId("");
        setLocalEmployeeData(undefined);
        setLocalProperties([]);
        setHasUnsavedData(false);
        setWasAnyUpdatesPersistedOnBE(false);
    };

    // Modal functions
    const handleClose = (force = false) => {
        // console.info(`handleClose, force: ${force}, hasUnsavedData: ${hasUnsavedData}`);
        if (force) {
            setShowAlert(false);
            clearStates();
            props.handleClose(wasAnyUpdatesPersistedOnBE);
        } else {
            if (hasUnsavedData) {
                setShowAlert(true);
            } else {
                setShowAlert(false);
                clearStates();
                props.handleClose(wasAnyUpdatesPersistedOnBE);
            }
        }
    };
    const handleDeleteClose = (force = false) => {
        // console.info(`handleDeleteClose, force: ${force}`);
        if (force) {
            setWasAnyUpdatesPersistedOnBE(true);
            payrollEmployeeDrawerData.deleteEmployee({
                employeeIds: localEmployeeData?.id ?? ""
            });
            setShowDeleteAlert(false);
            clearStates();
            props.handleClose(true);
        }
    };
    const handleDelete = () => {
        // console.log(`Attempt to delete employee`);
        setShowDeleteAlert(true);
    };

    const updateLocalEmployeeData = (key: LocalPayrollEmployeeModelKeys, value: any) => {
        // console.info(`new employee data on change of field ${key}: ${Stringify(value)} of type: ${typeof value}`);
        setHasUnsavedData(true);
        // I want to avoid erasing changes made by user to employee data when user decides to switch position (and/or property)
        // So anything user touched is ignored with payroll defaults update.
        payrollDefaults.disableDefaultsForKey(key);
        setLocalEmployeeData((prevState: LocalPayrollEmployeeModel | undefined): LocalPayrollEmployeeModel => {
            // console.info(`prevState: ${Stringify(prevState)}`);
            let raisesWithFixedAmount = [];
            const nextState: LocalPayrollEmployeeModel = {
                ...prevState
            } as LocalPayrollEmployeeModel;
            switch (key) {
                case LocalPayrollEmployeeModelKeys.firstName:
                    nextState.firstName = value;
                    nextState.firstNameFieldTouched = true;
                    break;
                case LocalPayrollEmployeeModelKeys.lastName:
                    nextState.lastName = value;
                    break;
                case LocalPayrollEmployeeModelKeys.externalSystemEmployeeId:
                    nextState.externalSystemEmployeeId = value;
                    break;
                case LocalPayrollEmployeeModelKeys.attemptTabChangeFromPositionDetails:
                    nextState.firstNameFieldTouched = true;
                    nextState.typeTouched = true;
                    nextState.positionTouched = true;
                    nextState.propertiesTouched = true;
                    break;
                case LocalPayrollEmployeeModelKeys.type:
                    nextState.type = value;
                    break;
                case LocalPayrollEmployeeModelKeys.position:
                    nextState.position = value;
                    break;
                case LocalPayrollEmployeeModelKeys.classification:
                    nextState.classification = value;
                    break;
                case LocalPayrollEmployeeModelKeys.startDate:
                    nextState.startDate = value;
                    break;
                case LocalPayrollEmployeeModelKeys.terminationDate:
                    nextState.terminationDate = value;
                    break;
                case LocalPayrollEmployeeModelKeys.properties:
                    nextState.properties = value;
                    break;
                case LocalPayrollEmployeeModelKeys.baseCompensationType:
                    nextState.baseComp.compensationType = value;
                    raisesWithFixedAmount = nextState.raises.filter(r => r.raiseType === PayrollRaiseType.FixedAmount);
                    raisesWithFixedAmount.forEach(r => {
                        r.amount = "0";
                    });
                    break;
                case LocalPayrollEmployeeModelKeys.baseCompensationAnnualizedComponent:
                    nextState.baseComp.annualCompensation = value;
                    break;
                case LocalPayrollEmployeeModelKeys.baseCompensationHourlyCompensation:
                    nextState.baseComp.hourlyCompensation = value;
                    break;
                case LocalPayrollEmployeeModelKeys.baseCompensationExpectedHoursPerWeek:
                    nextState.baseComp.expectedHoursPerWeek = value;
                    break;
                case LocalPayrollEmployeeModelKeys.raises:
                    nextState.raises = value;
                    break;
                case LocalPayrollEmployeeModelKeys.bonuses:
                    nextState.bonuses = value;
                    break;
                case LocalPayrollEmployeeModelKeys.overtimes:
                    nextState.overtimes = value;
                    break;
                case LocalPayrollEmployeeModelKeys.medicalCompItems:
                    nextState.compItems[PayrollCompensationItemType.Medical] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.dentalCompItems:
                    nextState.compItems[PayrollCompensationItemType.Dental] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.visionCompItems:
                    nextState.compItems[PayrollCompensationItemType.Vision] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.retirement401KCompItems:
                    nextState.compItems[PayrollCompensationItemType.Retirement_401KMatch] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.medicareCompItems:
                    nextState.compItems[PayrollCompensationItemType.MedicareTax] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.socialSecurityTax:
                    nextState.compItems[PayrollCompensationItemType.SocialSecurityTax] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.federalUnemploymentTax:
                    nextState.compItems[PayrollCompensationItemType.FederalUnemploymentTax] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.stateUnemploymentTax:
                    nextState.compItems[PayrollCompensationItemType.StateUnemploymentTax] = value;
                    break;
                case LocalPayrollEmployeeModelKeys.workersCompensation:
                    nextState.compItems[PayrollCompensationItemType.WorkersCompensation] = value;
                    break;
                default:
                    console.warn(`Unhandled key: ${key}, value: ${JSON.stringify(value)}`);
            }
            // console.info(`nexState: ${Stringify(nextState)}`);
            return nextState;
        });
    };

    const handleSave = (budgetYear: number) => {
        // console.log(`Save Employee localEmployeeData: ${Stringify(localEmployeeData)}`);
        updateLocalEmployeeData(LocalPayrollEmployeeModelKeys.attemptTabChangeFromPositionDetails, null);
        if (localEmployeeData && localEmployeeData.id && localEmployeeData.firstName &&
            localEmployeeData?.type && localEmployeeData.position && localEmployeeData.properties && localEmployeeData.properties.length !== 0) {
            const newFormattedCompItems: any[] = [];
            const compItemsCategories = Object.keys(localEmployeeData.compItems);
            compItemsCategories.forEach((category) => {
                const covertedCategory: PayrollCompensationItemType = category as PayrollCompensationItemType;
                const currentItems: Array<LocalPayrollEmployeeCompItemModel> = localEmployeeData.compItems[covertedCategory];
                currentItems.forEach(item => {
                    newFormattedCompItems.push({
                        compensationItemId: item.compensationItem.id,
                        effectiveStartDate: item.effectiveStartDate ?? dateUtcNow().toISOString(),
                        costPerPayPeriod: item.costPerPayPeriod || "0",
                        annualCostCap: item.annualCostCap || null,
                        calculationType: item.calculationType
                    } as PayrollCreateEmployeeCompItemInput);
                });
            });
            // console.info(`newFormattedCompItems: ${Stringify(newFormattedCompItems)}`);
            const commonPayload: CreateEmployeeMutationVariables = {
                budgetYear: budgetYear,
                firstName: localEmployeeData.firstName,
                lastName: localEmployeeData.lastName,
                externalSystemEmployeeId: localEmployeeData.externalSystemEmployeeId,
                type: localEmployeeData.type ?? PayrollModeledEmployeeType.Actual,
                positionId: localEmployeeData.position ? localEmployeeData.position.id : "",
                classification: localEmployeeData.classification ?? PayrollEmployeeClassification.Employee,
                startDate: localEmployeeData.startDate ?? dateUtcNow().toISOString(),
                terminationDate: localEmployeeData.terminationDate ?? undefined,
                propertyIds: localEmployeeData.properties.map(property => property.id),
                baseComp: {
                    compensationType: localEmployeeData.baseComp.compensationType ?? PayrollCompensationType.Annual,
                    annualCompensation: isNaN(parseFloat(localEmployeeData.baseComp.annualCompensation)) ? "0" : localEmployeeData.baseComp.annualCompensation.toString(),
                    hourlyCompensation: isNaN(parseFloat(localEmployeeData.baseComp.hourlyCompensation)) ? "0" : localEmployeeData.baseComp.hourlyCompensation.toString(),
                    expectedHoursPerWeek: isNaN(parseFloat(localEmployeeData.baseComp.expectedHoursPerWeek)) ? "0" : localEmployeeData.baseComp.expectedHoursPerWeek.toString()
                },
                raises: localEmployeeData.raises.map(raise => {
                    return {
                        amount: raise.amount || "0",
                        frequency: raise.frequency ?? PayrollRaiseFrequency.Annually,
                        raiseType: raise.raiseType ?? PayrollRaiseType.PercentOfSalary,
                        effectiveStartDate: raise.effectiveStartDate ?? dateUtcNow().toISOString(),
                        raiseMonth: isNaN(parseInt(raise.raiseMonth)) ? 0 : parseInt(raise.raiseMonth),
                        raiseDay: isNaN(parseInt(raise.raiseDay)) ? 1 : parseInt(raise.raiseDay),
                        onetimeRaiseDate: raise.frequency === PayrollRaiseFrequency.Once ? (raise.onetimeRaiseDate ?? "")  : (raise.effectiveStartDate ?? dateUtcNow().toISOString()) ,
                    } as PayrollCreateEmployeeRaiseInput;
                }) ?? [],
                bonuses: localEmployeeData.bonuses.map(bonus => {
                    return {
                        amount: bonus.amount || "0",
                        frequency: bonus.frequency ?? PayrollBonusFrequency.Annually,
                        bonusType: bonus.bonusType ?? PayrollBonusType.PercentOfSalary,
                        effectiveStartDate: bonus.effectiveStartDate ?? dateUtcNow().toISOString(),
                        bonusMonth: isNaN(parseInt(bonus.bonusMonth)) ? 0 : parseInt(bonus.bonusMonth),
                        bonusDay: isNaN(parseInt(bonus.bonusDay)) ? 1 : parseInt(bonus.bonusDay),
                        onetimeBonusDate: bonus.frequency === PayrollBonusFrequency.Once ? (bonus.onetimeBonusDate ?? "") : (bonus.effectiveStartDate ?? dateUtcNow().toISOString()),
                    } as PayrollCreateEmployeeBonusInput;
                }) ?? [],
                overtime: localEmployeeData.overtimes.map(overtime => {
                    return {
                        hourlyCompensation: overtime.hourlyCompensation || "0",
                        expectedHoursPerWeek: overtime.expectedHoursPerWeek || "40",
                        effectiveStartDate: overtime.effectiveStartDate ?? dateUtcNow().toISOString(),
                        effectiveEndDate: overtime.effectiveEndDate ?? ""
                    } as PayrollCreateEmployeeOvertimeInput;
                }) ?? [],
                items: newFormattedCompItems ?? []
            };
            setWasAnyUpdatesPersistedOnBE(true);
            if (localEmployeeData.id.startsWith(DEFAULT_NEW_EMPLOYEE_ID)) {
                payrollEmployeeDrawerData.createEmployee({
                    ...commonPayload
                });
            } else if (localEmployeeData.id.length > 0) {
                payrollEmployeeDrawerData.updateEmployee({
                    ...commonPayload,
                    id: localEmployeeData.id,
                });
            }
        } else {
            console.warn(`============== The localEmployeeData: ${JSON.stringify(localEmployeeData)} is not valid.`);
        }
    };

    const modalRootProbe = document.getElementById("modal-alert-root");
    const modalRoot = modalRootProbe == null ? undefined : modalRootProbe;

    if (!internalShow) {
        return (
            <></>
        );
    }

    if (!localEmployeeData || !localPayrollPositionsData) {
        return (
            <></>
        );
    }

    /*console.info(`Before render of the main drawer
        localEmployeeData.id: ${Stringify(localEmployeeData.id)},
        drawerData.EmployeeId: ${Stringify(payrollEmployeeDrawerData.employeeId)}
        `);*/

    return (
        <>
            {showAlert && (
                <Modal
                    onClose={() => setShowAlert(false)}
                    appendToNode={modalRoot}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Discard unsaved changes?</Header>
                    <Body>
                        Your unsaved changes will be lost.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setShowAlert(false)} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => handleClose(true)}>
                                Discard
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
            {showDeleteAlert && (
                <Modal
                    onClose={() => setShowDeleteAlert(false)}
                    appendToNode={modalRoot}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Confirm Delete</Header>
                    <Body>
                        Are you sure you want to delete this Staff member? All payroll data for this Staff member will
                        be permanentaly deleted.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setShowDeleteAlert(false)} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => handleDeleteClose(true)}>
                                Delete
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
            <ReactModal
                show={internalShow}
                animation={false}
                backdropClassName="vizibly-analyst-drawer-backdrop"
                dialogClassName={`vizibly-analyst-drawer-dialog ${props.show ? 'slide-in-right' : 'slide-out-right'} ${styles.analystPayrollModal}`}
                contentClassName="vizibly-analyst-drawer-content"
                onHide={() => handleClose()}
            >
                <ReactModal.Body>
                    <div id="modal-alert-root"/>
                        <div className={styles.analystPayrollDrawer}>
                            <Grid>
                                <Row justifyContent="center">
                                    <Col size={3}>
                                        <StaffDetails
                                            employeeData={localEmployeeData}
                                            updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                                updateLocalEmployeeData(key, value);
                                            }}
                                            handleDelete={() => {
                                                handleDelete();
                                            }}
                                        />
                                    </Col>
                                    <Col size={9}>
                                        <div className={styles.header}>
                                            <div className={styles.headerText}>
                                                <h4>Payroll</h4>
                                                <button className={styles.closeButton + " btn btn-close"}
                                                        onClick={() => handleClose()}></button>
                                            </div>
                                        </div>
                                        <Tabs selectedItem={selectedTab} onChange={(item) => {
                                            onTabChange(item);
                                        }}>
                                            <TabList>
                                                <Tab item={PayrollDrawerSections.positionDetails}>Position Details</Tab>
                                                <Tab item={PayrollDrawerSections.earnings}>Earnings</Tab>
                                                <Tab item={PayrollDrawerSections.benefits}>Benefits</Tab>
                                                <Tab item={PayrollDrawerSections.taxesAndInsurance}>Taxes &
                                                    Insurance</Tab>
                                            </TabList>
                                            <TabPanel item={PayrollDrawerSections.positionDetails}
                                                      className={styles.tabPanels}>
                                                <TabPositionDetails
                                                    propertiesData={localProperties}
                                                    payrollPositionsData={localPayrollPositionsData}
                                                    employeeData={localEmployeeData}
                                                    lockedProperties={props.lockedProperties}
                                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                                        updateLocalEmployeeData(key, value);
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel item={PayrollDrawerSections.earnings}
                                                      className={styles.tabPanels}>
                                                <TabEarnings
                                                    employeeData={localEmployeeData}
                                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                                        updateLocalEmployeeData(key, value);
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel item={PayrollDrawerSections.benefits}
                                                      className={styles.tabPanels}>
                                                <TabBenefits
                                                    employeeData={localEmployeeData}
                                                    compensationItems={localPayrollCompensationItems ?? []}
                                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                                        updateLocalEmployeeData(key, value);
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel item={PayrollDrawerSections.taxesAndInsurance}
                                                      className={styles.tabPanels}>
                                                <TabTaxesAndInsurance
                                                    employeeData={localEmployeeData}
                                                    compensationItems={localPayrollCompensationItems ?? []}
                                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                                        updateLocalEmployeeData(key, value);
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size={12}>
                                        <div className={styles.footerActionsBar}>
                                            <Button isBasic className={styles.footerActionsBarCancelButton}
                                                    onClick={() => handleClose()}>Cancel</Button>
                                            <Button disabled={!hasUnsavedData} className={styles.footerActionsBarSaveButton}
                                                    isPrimary onClick={() => {
                                                        handleSave(props.budgetYear);
                                                    }}>Save</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                            {
                                showEmployeeCreateUpdateNotification &&
                                <Notification type="success" style={{
                                    width: "360px",
                                    position: "fixed",
                                    left: "15px",
                                    bottom: "15px"
                                }}>
                                    <Title>Success</Title>
                                    {employeeCreateUpdateNotificationMessage}
                                    <ClosedRN aria-label="Close Notification" onClick={() => {
                                        setShowEmployeeCreateUpdateNotification(false);
                                    }}/>
                                </Notification>
                            }
                            {
                                showEmployeeFormValidationNotification &&
                                <Notification type="warning" style={{
                                    width: "360px",
                                    position: "fixed",
                                    left: "15px",
                                    bottom: "15px"
                                }}>
                                    <Title>Warning</Title>
                                    {employeeFormValidationNotificationMessage}
                                    <ClosedRN aria-label="Close Notification" onClick={() => {
                                        setShowEmployeeFormValidationNotification(false);
                                    }}/>
                                </Notification>
                            }
                        </div>
                </ReactModal.Body>
            </ReactModal>
        </>
    );
}
