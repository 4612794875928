import ExcelJS from "exceljs";
import { BudgetingType } from "../../../../../BudgetingType";
import { AppConfig } from "../../../../../hooks/useConfig";

export enum FormatHint {
    NUMBER = "NUMBER",
    PERCENT = "PERCENT",
    DECIMALS = "DECIMALS"
}

export type DataRow = {
    elements: (string | null) [];
    formatHint: FormatHint;
}

export async function formatForExcel(
    { tab, data, config }:
        {
            tab: BudgetingType;
            data: DataRow[];
            config: AppConfig;
        }
): Promise<Blob> {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Vizibly";
    workbook.lastModifiedBy = "Vizibly";
    workbook.created = new Date();
    workbook.modified = new Date();

    const reportYear = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;

    const tabCaption = `${reportYear} Revenue Assumptions`;
    // create a sheet where the grid lines are hidden
    const sheet = workbook.addWorksheet(
        tabCaption,
        {
            views: [{
                showGridLines: false,
                state: "frozen",
                xSplit: 1,
                ySplit: 4
            }]
        }
    );

    const metricHeaderColumnWidth = 27;
    const monthColumnWidth = 15;
    const yearTotalColumnWidth = 20;

    sheet.columns = [
        {
            width: metricHeaderColumnWidth
        },
        ...new Array(12).fill(0).map(_ => ({
            width: monthColumnWidth
        })),
        {
            width: yearTotalColumnWidth
        }
    ];

    let addedRow = sheet.addRow([config.properties.currentProperty?.name]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A1:N1");

    addedRow = sheet.addRow([tabCaption]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A2:N2");


    // empty row
    addedRow = sheet.addRow([]);
    addedRow.height = 16;
    sheet.mergeCells("A3:N3");

    const headerRow = [
        "",
        ...new Array(12).fill(0).map((_, month) => new Date(Date.UTC(reportYear, month, 1))),
        "Total/Average"
    ];

    addedRow = sheet.addRow(headerRow);
    addedRow.height = 30;
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 14
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle"
    };

    addedRow.border = {
        ...(addedRow.border ?? {}),
        bottom: {
            style: "thin",
            color: {
                argb: "45000000"
            }
        }
    };

    // col index is 1 based
    addedRow.eachCell((cell, col) => {
        if (col >= 2 && col <= 13) {
            cell.numFmt = "mmm yy";
        }
        if (col >= 3) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "right"
            };
        }
    });

    for (const row of data) {
        const addedDataRow = sheet.addRow(row.elements.map(e => Number.isNaN(e) ? 0 : e));
        addedDataRow.height = 30;
        addedDataRow.font = {
            name: "Helvetica",
            size: 14
        };
        addedDataRow.alignment = {
            ...(addedDataRow.alignment ?? {}),
            vertical: "middle"
        };
        addedDataRow.border = {
            ...(addedRow.border ?? {}),
            bottom: {
                style: "thin",
                color: {
                    argb: "BFBFBF"
                }
            }
        };

        // col index is 1 based
        let numFmt = "_(* #,##0_);_(* (#,##0);_(* -??_);_(@_)";
        if (row.formatHint == FormatHint.PERCENT) {
            numFmt = "0.0%";
        } else if (row.formatHint == FormatHint.DECIMALS) {
            numFmt = "0.00";
        }
        addedDataRow.eachCell((cell, col) => {
            if (col == 1) {
                cell.font = {
                    ...(cell.font ?? {}),
                    name: "Helvetica",
                    bold: true,
                };
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "left"
                };
            }
            if (col >= 2 && col <= 14) {
                cell.numFmt = numFmt;
            }
            if (col >= 2) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "right"
                };
            }
        });
    }

    const result = await workbook.xlsx.writeBuffer();

    return new Blob([result]);
}
