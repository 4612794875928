import { Button } from "@zendeskgarden/react-buttons";
import { ReactElement } from "react";

import * as css from "./styles/userSelectionBar.module.scss";

export interface IUserSelectionBar {
    selectedUsers: string[],
    onEdit: () => void,
    onDelete: () => void,
}

export default function UserSelectionBar(props: IUserSelectionBar): ReactElement {
    return (
        <div className={`${css.userSelectionBar} ${props.selectedUsers.length ? css.userSelectionBarVisible : ''}`}>
            <div className={css.userSelectionBarCount}><span>{props.selectedUsers.length}</span></div>
            <h4>{props.selectedUsers.length == 1 ? 'User' : 'Users'} selected</h4>

            {/* until bulk editing is ready, hide the edit button for bulk operations */}
            <Button className={`${css.userSelectionButton} ${props.selectedUsers.length > 1 ? css.userSelectionButtonHidden : ''}`} onClick={props.onEdit} disabled={props.selectedUsers.length > 1}>
                {props.selectedUsers.length == 1 ? "Edit User" : "Bulk Edit"}
            </Button>

            <Button className={css.userSelectionButton} onClick={props.onDelete}>
                {props.selectedUsers.length == 1 ? "Delete User" : "Bulk Delete"}
            </Button>
        </div>
    );
}