import {ReactElement, useEffect} from "react";
import {useLocation} from "react-router-dom";
import mixpanel from "mixpanel-browser";

export function MixPanelRouteWatcher(props: {children?: ReactElement | ReactElement[]}): ReactElement {
    // This only functions when loaded under the Router element from react-router-dom, so this
    // component must be put under that.
    const location = useLocation();

    useEffect(() => {
        mixpanel.track_pageview();
    }, [location]);

    return <>{props.children ? props.children : <></>}</>;
}