/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Brand from "../../assets/icons/brand/brand.png";
import useAppStore from "../../hooks/useAppStore";

function AppStarter() {
    const appStore = useAppStore();
    if(!appStore.isLoading) return null;

    return (
        <div className='_modal' style={{background: "white", zIndex: 100}}>
            {/* <img style={{filter: "grayscale(1)", opacity: 0.4}} src={Brand} alt="Loading App" /> */}
            <div className='_modal app-starter'>
                <div className='as-content'>
                    <div className='app-img'>
                        <img src={Brand} alt="Loading App" />
                    </div>
                    <div className='as-text'><span>Vizibly is starting</span></div>
                </div>
            </div>
        </div>
    );
}

export default AppStarter;