import React, { ReactElement, Fragment } from 'react';
import './App.css';
import './styles.scss';
import './main.scss';
import './styles/handsontable/handsontable.vizibly.full.scss';

import AppStarter from './components/loading/AppStarter';
import AuthProvider, { AuthIsNotSignedIn, AuthIsSignedIn } from './contexts/AuthContext';
import { ChartOfAccountsProvider } from './contexts/chartofaccounts/ChartOfAccountsContext';
import { MainRoute } from './routes/main/MainRoute';
import { PropertiesProvider } from './contexts/properties/PropertiesContext';
import { SettingsProvider } from './contexts/settings/SettingsContext';
import { SignInRoute } from './routes/auth/SignInRoute';
import { VersionsProvider } from './contexts/versions/VersionsContext';
import { AuthorizationContextProvider } from "./authorization/AuthorizationContext";
import { AccountContextProvider } from "./contexts/account/AccountContext";
import { FeatureFlagContextProvider } from './feature-flag/FeatureFlagContextProvider';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import {IntercomWrapper} from "./intercom/IntercomWrapper";
import {MixPanelWrapper} from "./mixpanel/MixPanelWrapper";

const LDClientKey = process.env['REACT_APP_LAUNCH_DARKLY_CLIENT_KEY'];

const App = ():ReactElement => (<Fragment>
    <AppStarter />
    <AuthProvider>
        <IntercomWrapper>
            <MixPanelWrapper>
                <AuthIsSignedIn>
                    <AuthorizationContextProvider>
                        <FeatureFlagContextProvider>
                            <VersionsProvider>
                                <PropertiesProvider>
                                    <ChartOfAccountsProvider>
                                        <SettingsProvider>
                                            <AccountContextProvider>
                                                <MainRoute />
                                            </AccountContextProvider>
                                        </SettingsProvider>
                                    </ChartOfAccountsProvider>
                                </PropertiesProvider>
                            </VersionsProvider>
                        </FeatureFlagContextProvider>
                    </AuthorizationContextProvider>
                </AuthIsSignedIn>
                <AuthIsNotSignedIn>
                    <SignInRoute />
                </AuthIsNotSignedIn>
            </MixPanelWrapper>
        </IntercomWrapper>
    </AuthProvider>
</Fragment>);

export default withLDProvider({
    clientSideID: LDClientKey ?? 'LaunchDarkly client ID not found',
})(App);
