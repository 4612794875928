import {ReactElement} from 'react';
import {BsArrowLeftShort} from 'react-icons/bs';
import './ProgressList.css';


const CHART_OF_ACCOUNTS = ['Basic Set Up', 'Define Budget Components', 'Define Budget Categories', 'Categorize your Accounts'];

interface Props {
    selected: number;
}

export default function ProgressList(props: Props): ReactElement {
  return (
    <div>
      <button className="border-0 bg-bg-grey text-primary mt-2">
        <BsArrowLeftShort className="fs-2"/> Back
      </button>
      <div className="container">
        <div className="text-start pb-3 py-4 fs-3"> Chart of Accounts </div>
        <div className="progress-list d-flex justify-content-around text-center bg-white py-4 mb-4 px-3">
          {CHART_OF_ACCOUNTS.map((account, index) => {
            let progressClass = '';
            if (props.selected === index) {
              progressClass = 'selected';
            } else if (props.selected > index) {
              progressClass = 'completed';
            }

            return (
              <div className={'step d-flex align-items-baseline small ' + progressClass}>
                <div className="bullet"> {index + 1} </div>
                <div className="ms-2"> {account} </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
