import {ReactElement, useState} from 'react';
import {GrDrag} from 'react-icons/gr';
import {BsPlus} from 'react-icons/bs';
import {RiDeleteBin6Line, RiEditLine} from 'react-icons/ri';
import {HiOutlineDotsVertical} from 'react-icons/hi';
import '../sidebar/SideBar.css';


export enum AddCategoryAction {
    CATEGORY = 'CATEGORY',
    SUBCATEGORY = 'SUBCATEGORY',
    NONE = 'NONE'
}

export interface TreeNode {
    title: string;
    children?: TreeNode[];
    id: number;
    isDraggable?: boolean;
    categoryAction: AddCategoryAction;
}

export interface Props {
    nodes: TreeNode[];
    className?: string;
}

function TreeNode(node: TreeNode): ReactElement {
  const [isOpenToEdit, setIsOpenToEdit] = useState(false);
  const openToEdit = () => setIsOpenToEdit(!isOpenToEdit);

  return (

    <div className="wrapper">
      <div className="d-flex justify-content-between fs-5">
        <div>
          {node.categoryAction === AddCategoryAction.NONE && <GrDrag className="text-secondary"/>}
          {node.categoryAction === AddCategoryAction.SUBCATEGORY && <GrDrag/>}
          <span className="ms-2">
            {node.title}
          </span>
        </div>
        <div>
          {node.categoryAction === AddCategoryAction.CATEGORY && <button className="border-0 bg-white text-primary"> <BsPlus/> add category </button>}
          {node.categoryAction === AddCategoryAction.SUBCATEGORY && <button className="border-0 bg-white text-primary"> <BsPlus/> add sub-category </button>}
          {node.categoryAction === AddCategoryAction.SUBCATEGORY &&
                        <div className="dropdown d-inline-block">
                          <button className="border-0 bg-white" onClick={openToEdit} type="button" id={`tree action + ${node.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <HiOutlineDotsVertical/>
                          </button>
                          <div className={'dropdown-menu ' + (isOpenToEdit ? 'show' : 'hide')} aria-labelledby={`tree action + ${node.id}`}>
                            <button className="dropdown-item" type="button"><RiEditLine className="fs-5"/> Edit category</button>
                            <button className="dropdown-item" type="button"><RiDeleteBin6Line className="fs-5"/> Remove category</button>
                          </div>
                        </div>
          }
          {node.categoryAction === AddCategoryAction.NONE &&
                        <div className="dropdown">
                          <button className="border-0 bg-white" onClick={openToEdit} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <HiOutlineDotsVertical/>
                          </button>
                        </div>
          }
        </div>

      </div>
      {node.children && <BudgetCategoriesTree className="text-grey20" nodes={node.children}/>}
    </div>
  );
}

export default function BudgetCategoriesTree(props: Props): ReactElement {
  return (
    <ul className={`list-group list-group-flush border-top py-1 ${props.className}`}>
      {props.nodes.map((node) => (
        <li className="list-group-item text-grey20 ">
          <TreeNode {...node} />
        </li>
      ))}
    </ul>
  );
}
