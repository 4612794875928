import {HotTable} from "@handsontable/react";
import Handsontable from "handsontable";
import {ReactElement, useEffect, useRef, useState} from "react";
import 'handsontable/dist/handsontable.full.css';
import {LICENSES} from "../../constants/Licenses";
import * as css from "./styles/accountTable.module.scss";
import {buildHandsonTableSettings} from "./logic/AccountTableSettings";
import {TAccountTableRowData, parseAccountTableData, placeholderColumns} from "./data/AccountTableData";
import {buildGraphSummary, hotValidUserActions, numberOrZero, recalculateAcctPercentageDriver, recalculateAcctPercentageDriverSimple, recalculateCustomDriver, recalculateOperationalDriver, sanitizeTableInput, setForceReadOnlyToMultiPctAccountRow, toggleHiddenRows, updateRowSummary} from "./logic/AccountTableHelpers";
import * as CellRenderers from "./CellRenderers";
import {Property} from "../../contexts/properties/PropertiesContext";
import {Account} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {AccPercentageDriverAssumptionsUpdateInput, DriverType, ForecastLocks, GetSinglePropertyDriversAndWorksheetItemsQuery, VersionType, useAccountValueOverrideMutation, useBulkUpdateAccPercentageDriverAssumptionsMutation, useDeleteAccountOverrideValueMutation, useSetCustomDriverAssumptionsMutation, useUpdateAccPercentageDriverAssumptionsMutation, useUpdateOperationalDriverAssumptionsMutation, useUpdateWorksheetDriverDataMutation, useUpsertAccountValuesMutation} from "../../__generated__/generated_types";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {IAccountSummaryData} from "../../pages/workflows/account/AccountSummaryContext";
import {formatterDollarUSNoDecimal} from "../../utils/formatters";
import { TLockOverrides } from "../../authorization/AuthorizationCheckers";
import { Button } from "@zendeskgarden/react-buttons";

const MAX_ROWS_TO_PASTE = 3;

export type TAccountTableProps = {
    year: number,
    versionType: VersionType,
    property: Property | undefined,
    account: Account | undefined,
    versionId: string,
    combinedData: {
        driversAndWorksheetData: GetSinglePropertyDriversAndWorksheetItemsQuery,
        financialYearValues: IAccountSummaryData,
    },
    forecastLocks: ForecastLocks | undefined,
    lockOverrides: TLockOverrides | undefined,
    forceDataRefetch?: () => void,
    dataUpdated?: (newFinancialData: IAccountSummaryData) => void,
}

type TOverrideRevertValues = {
    row: number,
    col: number,
    original: number,
    override: number,
}

function isValidPasteValue(text: string): boolean {
    return text
            .replaceAll("\r\n", "	")
            .replaceAll("\n", "	")
            .split("	")
            .findIndex(val => Number.isNaN(+val))
            == -1;
}

export default function AccountTable({year, versionType, property, combinedData, account, versionId, forecastLocks, lockOverrides, forceDataRefetch, dataUpdated}: TAccountTableProps): ReactElement {
    // register our custom renderers with HoT
    const cellRenderers = CellRenderers;
    Object.entries(cellRenderers).forEach(([key, value]) => {
        Handsontable.renderers.registerRenderer(key, value);
    });

    // ref vars
    const hotRef = useRef<HotTable>(null);
    const originals = useRef<{[key: number]: (number | null)}>({});
    const overrides = useRef<{[key: number]: (number | null)}>({});
    const overrideRevertValues = useRef<TOverrideRevertValues[]>();
    const versionSummaryTotals = useRef<{[key: number]: (number | null)}>({});
    const visibleParentRows = useRef<Set<number>>(new Set<number>());
    const copyPasteCache = useRef<{val: string}>({val: ""});
    const hiddenRows = useRef<number[]>([]);
    const isDriven = useRef<boolean>(false);
    const isRevenueDriven = useRef<boolean>(false);
    const trackedFinancialValues = useRef<IAccountSummaryData>({years: {}});
    const overrideSaveDebounce = useRef<(ReturnType<typeof setTimeout> | null)>(null);

    const [accPctSaveDebounceRows, setAccPctSaveDebounceRows] = useState<number[]>([]);
    const isSavingAccPctRows = useRef(false);
    const accPctSaveDebounceRowsIndex = useRef<number | null>(null);

    const [bulkUpdateAccPctCols, setBulkUpdateAccPctCols] = useState<number[]>([]);
    const isForceFetchingAfterBulkUpdateAccPct = useRef(false);
    const bulkUpdateAccPctColsIndex = useRef<number | null>(null);

    // collects [row, col] pairs of updates
    const [customDriverSaveDebounceRows, setCustomDriverSaveDebounceRows] = useState<[number, number][]>([]);

    const [triedPasteRows, setTriedPasteRows] = useState<number>();
    const isReady = useRef<boolean>(false);

    // state vars
    const [showPasteBlockedAlert, setShowPasteBlockedAlert] = useState(false);
    const [data, setData] = useState<TAccountTableRowData[] | undefined>([]);
    const [tableSettings, setTablesettings] = useState<Handsontable.GridSettings | undefined>();
    const [tableLocked, setTableLocked] = useState<boolean>(true);
    const [saveAccountValueOverride] = useAccountValueOverrideMutation({
        ignoreResults: true,
        onCompleted: () => {
            if (isRevenueDriven.current === true && forceDataRefetch != undefined) {
                forceDataRefetch();
            } else {
                sendDataUpdate();
            }
        }
    });
    const [removeAccountValueOverride] = useDeleteAccountOverrideValueMutation({
        ignoreResults: true,
        onCompleted: () => {
            if (isRevenueDriven.current === true && forceDataRefetch != undefined) {
                forceDataRefetch();
            } else {
                sendDataUpdate();
            }
        }
    });
    const [upsertAccountValues] = useUpsertAccountValuesMutation({
        ignoreResults: true,
        onCompleted: sendDataUpdate,
    });
    const [saveOperationalDrivers] = useUpdateOperationalDriverAssumptionsMutation({
        ignoreResults: true,
        onCompleted: sendDataUpdate,
    });
    const [saveCustomDrivers] = useSetCustomDriverAssumptionsMutation({
        ignoreResults: true,
        onCompleted: sendDataUpdate
    });
    const [saveAccPercentageDrivers] = useUpdateAccPercentageDriverAssumptionsMutation({
        ignoreResults: true,
        onCompleted: handleAccPercentageDriverUpdateComplete,
    });
    const [bulkUpdateAccPercentageDrivers] = useBulkUpdateAccPercentageDriverAssumptionsMutation({
        ignoreResults: true,
        onCompleted: () => {
            if (bulkUpdateAccPctColsIndex.current != null) {
                const uptoIdx = bulkUpdateAccPctColsIndex.current;
                setBulkUpdateAccPctCols(prev => {
                    if (prev.length - 1 > uptoIdx) {
                        return prev.slice(uptoIdx - prev.length + 1)
                    }
                    forceDataRefetch?.();
                    const multiAccPctRowIndex = data?.findIndex(x => x.id == "multi_pct_of_account");
                    if (multiAccPctRowIndex != undefined && multiAccPctRowIndex > -1 && hotRef.current?.hotInstance) {
                        isForceFetchingAfterBulkUpdateAccPct.current = true;
                        setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, true);
                        hotRef.current.hotInstance.render();
                    }
                    bulkUpdateAccPctColsIndex.current = null;
                    return [];
                });
            }
        }
    });
    const [updateWorksheetDrivers] = useUpdateWorksheetDriverDataMutation({
        ignoreResults: true,
        onCompleted: sendDataUpdate,
    });

    // if data has been updated, alert the parent so related components can update their data
    function sendDataUpdate(): void {
        if (dataUpdated && data && hotRef.current?.hotInstance && property) {
            const yearValues = buildGraphSummary(hotRef.current.hotInstance, data, versionType, property.reforecastStartMonthIndex);
            if (versionType == VersionType.Reforecast) {
                trackedFinancialValues.current.years[year] = {
                    bdgt: trackedFinancialValues.current.years[year]?.bdgt ?? [],
                    fcst: yearValues.fcst,
                    act: yearValues.act,
                };
            } else {
                trackedFinancialValues.current.years[year] = yearValues;
            }
            dataUpdated(trackedFinancialValues.current);
        }
    }

    function handleAccPercentageDriverUpdateComplete() {
        const uptoIdx = accPctSaveDebounceRowsIndex.current;

        if (uptoIdx == null) {
            return;
        }
        setAccPctSaveDebounceRows(prev => {
            if (prev.length > uptoIdx) {
                return prev.slice(uptoIdx - prev.length);
            }
            isSavingAccPctRows.current = false;
            if (hotRef.current?.hotInstance) {
                const multiAccPctRowIndex = data?.findIndex?.(x => x.id == "multi_pct_of_account");

                if (multiAccPctRowIndex != undefined && multiAccPctRowIndex > -1) {
                    setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, false);
                    hotRef.current.hotInstance.render();
                }
            }
            return [];
        });
        sendDataUpdate();
    }

    // uses the currently active overrideRevertValues data to know which cell we are talking about
    function revertOverride(): void {
        if (overrideRevertValues.current === undefined || overrideRevertValues.current.length == 0 || !hotRef.current?.hotInstance || !account || !property) {
            return;
        }

        const monthIndexes = [];
        for (const toRevert of overrideRevertValues.current) {
            if (toRevert.col >= 0 && toRevert.col < 12) {
                overrides.current[toRevert.col] = null;
                hotRef.current.hotInstance.setDataAtCell(toRevert.row, toRevert.col + 1, toRevert.original, "computedSummary");
                monthIndexes.push(toRevert.col);
            }
        }

        removeAccountValueOverride({
            variables: {
                monthIndexes,
                accountId: account.id,
                propertyId: property.id,
                versionId,
            }
        });
    }

    useEffect(() => {
        let debounce: ReturnType<typeof setTimeout>;
        if (accPctSaveDebounceRows.length > 0 && hotRef.current?.hotInstance) {
            if (!isSavingAccPctRows.current) {
                const multiAccPctRowIndex = data?.findIndex?.(x => x.id == "multi_pct_of_account");

                if (multiAccPctRowIndex != undefined && multiAccPctRowIndex > -1) {
                    isSavingAccPctRows.current = true;
                    setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, true);
                    hotRef.current.hotInstance.render();
                }
            }
            debounce = setTimeout(() => {
                const toUpdate = new Set<number>();
                let uptoIdx = 0;
                while (toUpdate.size < MAX_ROWS_TO_PASTE && uptoIdx < accPctSaveDebounceRows.length) {
                    const row = accPctSaveDebounceRows[uptoIdx];
                    if (row != undefined) {
                        toUpdate.add(row);
                    }
                    uptoIdx++;
                }
                accPctSaveDebounceRowsIndex.current = uptoIdx;
                if (!property || !account || !data || !hotRef.current?.hotInstance) {
                    return;
                }
                //
                // This code handles two cases:
                // - Multi Percent of Account (where exists row with id == "multi_pct_of_account")
                // - Single Percent of Account (where there is single row with acc percentage driver percentages)
                //
                // For the former case all data is in rows starting next to row with id == "multi_pct_of_account"
                // And ending with the first row with rowData.driverType != DriverType.AccPercentage
                //
                // For the latter case all data is in for pointed to by firstRow
                //
                let firstRow = data.findIndex(x => x.id == "multi_pct_of_account");
                let hasMulti = true;
                if (firstRow == -1) {
                    firstRow = Math.min(...Array.from(toUpdate)) - 1;
                    hasMulti = false;
                }
                const items: AccPercentageDriverAssumptionsUpdateInput[] = [];
                let startCol = 1;
                if (versionType == "REFORECAST") {
                    startCol = property.reforecastStartMonthIndex + 1;
                }
                const avgs = new Array(12 - startCol + 1).fill(0);
                let count = 0;
                for (let row = firstRow+1; ; row++) {
                    const rowData = data[row];
                    if (!rowData || rowData.driverType != DriverType.AccPercentage) { // latter is stop condition to only process Account Percentage rows
                        break;
                    }
                    count++;
                    const rowNumbers: number[] = hotRef.current.hotInstance.getData(row, startCol, row, 12).flatMap((a: any[]) => a.map(v => numberOrZero(v)));
                    for (let col = startCol; col <= 12; col++) {
                        avgs[col - startCol] += rowNumbers[col - startCol];
                    }

                    if (toUpdate.has(row)) {
                        if (rowData.summaryFunction) {
                            updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, property.reforecastStartMonthIndex, versionType);
                        }

                        const percentages: Record<string, number> = {};
                        let startCol = 1;
                        if (versionType == "REFORECAST") {
                            startCol = property.reforecastStartMonthIndex + 1;
                        }
                        for (let col = startCol; col <= 12; col++) {
                            percentages[`percentMonth${col - 1}`] = rowNumbers[col - startCol] ?? 0;
                        }
                        items.push({
                            propertyId: property.id,
                            year,
                            versionType,
                            destinationAccountId: account.id,
                            sourceAccountId: rowData.id ?? rowData.parentRowId ?? "",
                            ...percentages
                        });
                    }
                }
                if (hasMulti && count > 0) {
                    for (let col = startCol; col <= 12; col++) {
                        hotRef.current.hotInstance.setDataAtCell(firstRow, col, +(avgs[col - startCol] / count).toFixed(1), "calculation");
                    }
                }
                saveAccPercentageDrivers({variables: {items}});
            }, 500);
        }

        return () => {
            clearTimeout(debounce);
        };
    }, [accPctSaveDebounceRows]);


    useEffect(() => {
        let debounce: ReturnType<typeof setTimeout>;
        if (bulkUpdateAccPctCols.length > 0 && hotRef.current?.hotInstance) {
            debounce = setTimeout(() => {
                const toUpdate = new Set<number>(bulkUpdateAccPctCols);
                const uptoIdx = bulkUpdateAccPctCols.length - 1;
                bulkUpdateAccPctColsIndex.current = uptoIdx;
                if (!property || !account || !data || !hotRef.current?.hotInstance) {
                    return;
                }
                const multiAccPctRowIndex = data.findIndex(x => x.id == "multi_pct_of_account");
                if (multiAccPctRowIndex == -1) {
                    return;
                }

                isForceFetchingAfterBulkUpdateAccPct.current = true;
                setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, true);
                hotRef.current.hotInstance.render();

                const percents: Record<string, number> = {};
                for (const col of Array.from(toUpdate)) {
                    percents[`percentMonth${col - 1}`] = numberOrZero(hotRef.current.hotInstance.getDataAtCell(multiAccPctRowIndex, col));
                }
                bulkUpdateAccPercentageDrivers({
                    variables: {
                        item: {
                            propertyId: property.id,
                            destinationAccountId: account.id,
                            versionType,
                            year,
                            ...percents
                        }
                    }
                });
            }, 1500);
        }

        return () => {
            clearTimeout(debounce);
        };
    }, [bulkUpdateAccPctCols]);


    useEffect(() => {
        let debounce: ReturnType<typeof setTimeout>;
        if (customDriverSaveDebounceRows.length > 0 && hotRef.current?.hotInstance) {
            debounce = setTimeout(() => {
                if (!property || !account || !data || !hotRef.current?.hotInstance) {
                    return;
                }
                const toUpdate = new Map<number, Set<number>>();
                for (const [row, col] of customDriverSaveDebounceRows) {
                    let rowData = toUpdate.get(row);
                    if (!rowData) {
                        rowData = new Set();
                        toUpdate.set(row, rowData);
                    }
                    rowData.add(col);
                }
                const updatesById = {} as Record<string, Record<string, string>>;
                for (const [row, cols] of Array.from(toUpdate.entries())) {
                    const rowData = data[row];
                    if (!rowData
                        || rowData.driverType != "customDriver"
                        || !rowData.parentRowId
                        || !rowData.contextCustomDriver
                        || !("customDriverValueType" in rowData.contextCustomDriver)) {
                        continue;
                    }
                    const id = rowData.parentRowId;
                    let field = undefined;
                    if (rowData.contextCustomDriver["customDriverValueType"] == "amounts") {
                        field = "amount";
                    }
                    else if (rowData.contextCustomDriver["customDriverValueType"] == "percentages") {
                        field = "percent";
                    }
                    else if (rowData.contextCustomDriver["customDriverValueType"] == "counts") {
                        field = "count";
                    }
                    if (!field) {
                        continue;
                    }

                    let idUpdate = updatesById[id];
                    if (!idUpdate) {
                        idUpdate = {};
                        updatesById[id] = idUpdate;
                    }
                    for (const col of Array.from(cols)) {
                        idUpdate[`${field}Month${col - 1}`] = numberOrZero(hotRef.current.hotInstance.getDataAtCell(row, col)).toString();
                    }

                }
                const variables = {
                    propertyId: property.id,
                    destinationAccountId: account.id,
                    versionType: versionType,
                    year: year,
                    items: [] as {
                        id: string,
                        [key: string]: string
                    }[]
                };
                for (const [id, update] of Object.entries(updatesById)) {
                    variables.items.push({
                        id: id,
                        ...update
                    });
                }
                setCustomDriverSaveDebounceRows([]);
                saveCustomDrivers({variables});
           }, 1500);
        }

        return () => {
            clearTimeout(debounce);
        };
    }, [customDriverSaveDebounceRows]);

    // start off by parsing our driver and financial data props
    useEffect(() => {
        isReady.current = false;

        if (!combinedData || !property || !account || !versionType) {
            return;
        }

        trackedFinancialValues.current = combinedData.financialYearValues;

        const {
            accountPercentageDriver,
            operationalMetricDriver,
            payrollDriver,
            renovationsDriver,
            revenueDriver,
            growthDriver,
            customDriver,
            worksheetDriver} = combinedData.driversAndWorksheetData.singlePropertyAccount;

        if (accountPercentageDriver && operationalMetricDriver && payrollDriver && renovationsDriver && revenueDriver && worksheetDriver && growthDriver) {
            isDriven.current = accountPercentageDriver.isDriven
                               || operationalMetricDriver.isDriven
                               || payrollDriver.isDriven
                               || renovationsDriver.isDriven
                               || revenueDriver.isDriven
                               || worksheetDriver.isDriven
                               || customDriver?.isDriven
                               || (growthDriver && growthDriver.length > 0);
            isRevenueDriven.current = revenueDriver.isDriven;
        }

        const parsedData = parseAccountTableData(combinedData.driversAndWorksheetData, combinedData.financialYearValues, property.reforecastStartMonthIndex, isDriven.current);
        setData(parsedData);
    }, [combinedData, property, account, versionType]);

    // make sure we have the rfcstStartMonth for the current property before building HoT settings object
    useEffect(() => {
        if (!property || !data || data.length == 0 || !combinedData.driversAndWorksheetData || !versionType) {
            return;
        }

        const originalValues = combinedData.driversAndWorksheetData.singlePropertyAccount.originalValuesRaw;
        originalValues.forEach((value, index) => {
            originals.current[index] = value != null ? Math.round(value) : 0;
        });

        const overrideValues = combinedData.driversAndWorksheetData.singlePropertyAccount.overrideValues;
        overrideValues.forEach((value, index) => {
            if (versionType == VersionType.Reforecast && index >= property.reforecastStartMonthIndex
                || versionType == VersionType.Budget) {
                overrides.current[index] = value != null ? Math.round(value) : value;
            }
        });

        const summaryTotals = combinedData.driversAndWorksheetData.singlePropertyAccount.accountValues;
        summaryTotals.forEach((value, index) => {
            versionSummaryTotals.current[index] = value;
        });

        data.forEach((row, index) => {
            if (row.id) {
                visibleParentRows.current.add(index);
            }
        });

        let locked = false;

        if (versionType == VersionType.Reforecast && forecastLocks?.reforecastLocked && !lockOverrides?.reforecast) {
            locked = true;
        } else if (versionType == VersionType.Budget && forecastLocks?.budgetLocked && !lockOverrides?.budget) {
            locked = true;
        }

        setTableLocked(locked);
        const settings = buildHandsonTableSettings(
            data,
            overrides.current,
            placeholderColumns,
            property.reforecastStartMonthIndex,
            versionType,
            visibleParentRows.current,
            isDriven.current,
            locked,
        );
        setTablesettings(settings);

        isReady.current = true;

        isForceFetchingAfterBulkUpdateAccPct.current = false;

    }, [data]);


    // This is the main hook that sets up initial state, logic, and HoT event hooks
    useEffect(() => {
        if (!property || !data || data.length == 0 || !account || !versionType || !hotRef.current?.hotInstance || !tableSettings) {
            return;
        }

        const rfcstStartMonth = property.reforecastStartMonthIndex;

        hotRef.current.hotInstance.batch(() => {
            if (!property || !data || data.length == 0 || !account || !versionType || !hotRef.current?.hotInstance || !tableSettings) {
                return;
            }

            data.forEach((rowData, row) => {
                if (rowData.summaryFunction && hotRef.current?.hotInstance) {
                    updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);
                }
            });

            hiddenRows.current = hotRef.current.hotInstance.getPlugin("hiddenRows").getHiddenRows();
            const copyPastePlugin = hotRef.current.hotInstance.getPlugin("copyPaste");

            hotRef.current.hotInstance.updateSettings({
                contextMenu: {
                    items: {
                        "copy": {
                            name: 'Copy',
                            key: 'copy',
                        },
                        "paste": {
                            name: 'Paste',
                            key: 'paste',
                            callback() {
                                navigator.clipboard
                                    .readText()
                                    .then(
                                        (value: string) => {
                                            if (isValidPasteValue(value)) {
                                                copyPastePlugin.paste(value);
                                            }
                                        },
                                        (reason: any) => {
                                            if (JSON.stringify(reason).includes("permission denied")) {
                                                setShowPasteBlockedAlert(true);
                                            }
                                        }
                                    );
                                this.listen();
                            },
                        },
                        "clearOverrides": {
                            key: "removeOverrides",
                            callback() {
                                revertOverride();
                            },
                            disabled() {
                                return !overrideRevertValues.current;
                            },
                            renderer(_, wrapper) {
                                const overridesToRevert = overrideRevertValues.current ?? [];
                                // hacky but restores default item behavior with hover highligting and pointer cursor
                                wrapper.parentElement?.classList.add(css.menuItem);
                                wrapper.parentElement?.classList.remove("htCustomMenuRenderer");
                                if (overridesToRevert.length > 0) {
                                    if (isRevenueDriven.current) {
                                        wrapper.innerHTML = `Remove override(s) and recalculate revenue drivers`;
                                    }
                                    else if (overridesToRevert.length > 1) {
                                        wrapper.innerHTML = `Remove overrides and recalculate drivers`;
                                    }
                                    else {
                                        const ov = overridesToRevert[0];
                                        if (ov) {
                                            wrapper.innerHTML = `Revert override (fx= ${formatterDollarUSNoDecimal.format(ov.override ?? 0)} --> ${formatterDollarUSNoDecimal.format(ov.original ?? 0)})`;
                                        }
                                    }
                                }
                                else {
                                    wrapper.innerHTML = "Revert overrides (no overrides selected)";
                                }
                                return wrapper;
                            },
                        }
                    },
                },
                afterCopy(_, range) {
                    const rangeData = copyPastePlugin.getRangedCopyableData(range);
                    copyPasteCache.current.val = rangeData;
                },
            });
            hotRef.current.hotInstance.removeHook("beforePaste", () => undefined);
            hotRef.current.hotInstance.addHook("beforePaste", (_data, ranges): boolean => {
                let validationResult = true;
                if (ranges.length > 0) {
                    const range = ranges[0];
                    // Be aware that this works accompanied by HoT setting <selectionMode: "range">,
                    if (range) {
                        const rowsInRange = Math.abs(range.endRow - range.startRow) + 1;
                        if (rowsInRange > MAX_ROWS_TO_PASTE) {
                            setTriedPasteRows(rowsInRange);
                            validationResult = false;
                        }
                    }
                }
                return validationResult;
            });

            hotRef.current.hotInstance.removeHook("beforeAutofill", () => undefined);
            hotRef.current.hotInstance.addHook("beforeAutofill", (selectionData, _sourceRange, targetRange, _direction) => {
                let validationResult = true;
                const rowsInRange = Math.abs(targetRange.to.row - targetRange.from.row) + 1;
                if (rowsInRange > MAX_ROWS_TO_PASTE) {
                    setTriedPasteRows(rowsInRange);
                    validationResult = false;
                }
                return validationResult ? selectionData : false;
            })


            hotRef.current.hotInstance.removeHook("beforeChange", () => undefined);
            hotRef.current.hotInstance.addHook("beforeChange", (changes, _source) => {
                for (let i = 0; i < changes.length; i++) {
                    const change = changes[i];

                    if (!change) {
                        return;
                    }

                    change[3] = sanitizeTableInput(change[3]);
                }
            });

            hotRef.current.hotInstance.removeHook("afterChange", () => undefined);
            hotRef.current.hotInstance.addHook("afterChange", (changes, _source) => {
                const source = _source as string;

                if (!changes) {
                    return;
                }

                for (let i = 0; i < changes.length; i++) {
                    const change = changes[i];

                    if (!change) {
                        continue;
                    }

                    const row = change[0];
                    const col = change[1];
                    const oldVal = change[2];
                    const newVal = change[3];

                    if (row == undefined || col == undefined || oldVal === undefined || newVal == undefined || oldVal === newVal) {
                        continue;
                    }

                    const rowData = data[row];
                    if (!rowData || !hotRef.current?.hotInstance) {
                        continue;
                    }

                    const colIndex = typeof col === 'string' ? hotRef.current.hotInstance.propToCol(col) : col + 1;

                    // TODO combine and debounce for other save calls, like how is done for overrides - jonathan
                    switch (rowData.rowType) {
                        case "versionSummary": {
                            if (hotValidUserActions.includes(source) && rowData.summaryFunction && col != "total") {
                                overrides.current[colIndex - 1] = newVal;
                                if (isDriven.current) {
                                    hotRef.current.hotInstance.setCellMeta(row, colIndex, "className", css.cellColorMagenta);
                                }
                                updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);

                                if (overrideSaveDebounce.current) {
                                    clearTimeout(overrideSaveDebounce.current);
                                }

                                overrideSaveDebounce.current = setTimeout(() => {
                                    const monthIndexes: number[] = [];
                                    const values: string[] = [];

                                    for (const [monthIndex, value] of Object.entries(overrides.current)) {
                                        if (value != null) {
                                            monthIndexes.push(parseInt(monthIndex));
                                            values.push(String(value));
                                        }
                                    }

                                    if (isDriven.current) {
                                        saveAccountValueOverride({
                                            variables: {
                                                monthIndexes,
                                                values,
                                                accountId: account.id,
                                                propertyId: property.id,
                                                versionId,
                                                shouldRevert: false
                                            }
                                        });
                                    } else {
                                        upsertAccountValues({
                                            variables: {
                                                monthIndexes,
                                                values,
                                                versionId: versionId,
                                                accountId: account.id,
                                                propertyId: property.id,
                                            }
                                        });
                                    }
                                }, 500);
                            }
                            break;
                        }
                        case "driver": {
                            if (hotValidUserActions.includes(source)) {
                                const parentRow = data.findIndex(x => x.id == rowData.parentRowId);

                                switch (rowData.driverType) {
                                    case "customDriver": {
                                        if (!rowData.parentRowId || colIndex > 12) {
                                            continue;
                                        }

                                        recalculateCustomDriver(hotRef.current.hotInstance, parentRow, colIndex);
                                        setCustomDriverSaveDebounceRows(prev => [...prev, [row, colIndex]]);
                                        break;
                                    }
                                    case DriverType.Operational: {
                                        if (!rowData.parentRowId || colIndex > 12) {
                                            continue;
                                        }

                                        const newDriverValues = recalculateOperationalDriver(hotRef.current.hotInstance, parentRow, colIndex);
                                        saveOperationalDrivers({
                                            variables: {
                                                items: [
                                                    {
                                                        propertyId: property.id,
                                                        year,
                                                        versionType,
                                                        destinationAccountId: account.id,
                                                        sourceMetricId: rowData.parentRowId,
                                                        [`percentMonth${colIndex - 1}`]: newDriverValues.percentage,
                                                        [`amountMonth${colIndex - 1}`]: newDriverValues.fee,
                                                    }
                                                ]
                                            }
                                        });
                                        break;
                                    }
                                    case DriverType.AccPercentage: {
                                        if (rowData.id == "multi_pct_of_account") {
                                            if (colIndex < 1 || colIndex > 12) {
                                                continue;
                                            }
                                            setBulkUpdateAccPctCols(prev => [...prev, colIndex]);
                                        }

                                        if (!rowData.parentRowId) {
                                            continue;
                                        }

                                        if (rowData.parentRowId != "multi_pct_of_account") {
                                            const augments = combinedData.driversAndWorksheetData.singlePropertyAccount.accountPercentageDriver?.augments;
                                            let usableAugments: {minValue: number | null, maxValue: number | null} = {
                                                minValue: null,
                                                maxValue: null
                                            };
                                            if(augments) {
                                                usableAugments = {
                                                    minValue: augments.minValue !== null ? parseFloat(augments.minValue) : null,
                                                    maxValue: augments.maxValue !== null ? parseFloat(augments.maxValue) : null
                                                };
                                            }
                                            recalculateAcctPercentageDriver(hotRef.current.hotInstance, parentRow, colIndex, usableAugments);
                                        }
                                        setAccPctSaveDebounceRows(prev => [...prev, row]);
                                        break;
                                    }
                                    case DriverType.Worksheet: {
                                        if (!rowData.id) {
                                            continue;
                                        }

                                        updateWorksheetDrivers({
                                            variables: {
                                                creates: [],
                                                updates: [
                                                    {
                                                        lineId: rowData.id,
                                                        lineValues: [
                                                            {
                                                                monthIndex: colIndex - 1,
                                                                value: String(newVal),
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        });
                                        break;
                                    }
                                }

                                if (rowData.summaryFunction && rowData.driverType != DriverType.AccPercentage && rowData.parentRowId != "multi_pct_of_account") {
                                    updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);
                                }
                            }
                        }
                    }

                    const summaryRowIndex = data.findIndex(x => x.rowType == "versionSummary");
                    const colOriginal = originals.current[colIndex - 1];
                    const colOverride = overrides.current[colIndex - 1];

                    if (hotValidUserActions.includes(source) && rowData.contributesToSummary) {
                        if (colOriginal != undefined) {
                             let newColOriginal = colOriginal + (newVal - oldVal);
                             if (rowData.driverType === DriverType.AccPercentage && rowData.parentRowId == "multi_pct_of_account") {
                                 // Acct Percentage is now special. We have min/max values
                                 // that apply to the total of all the account percentage rows.
                                 // That means we have to calculate all the account percentage values
                                 // all the time on any update. Hooray!
                                 // This may be slow depending, but we'll have to deal with it.
                                 // At worst, we have to cache values for each account percentage row.

                                 // Get all the account percentage drivers we aren't operating on :)
                                 const allPercentageDrivers = data
                                         .map((d, idx): [TAccountTableRowData, number] => [d, idx])
                                         .filter(t =>
                                                 t[0].driverType === DriverType.AccPercentage && t[0].id !== "multi_pct_of_account" && t[1] !== row);

                                 let total = 0;
                                 for(const t of allPercentageDrivers) {
                                     const rowData = t[0];
                                     const rowIdx = t[1];
                                     const amount = rowData.context?.["amounts"]?.[colIndex - 1] ?? 0;
                                     const currVal = hotRef.current.hotInstance.getDataAtCell(rowIdx, colIndex);
                                     total += amount * (currVal / 100.0);
                                 }

                                 const amount = rowData.context?.["amounts"]?.[colIndex - 1] ?? 0;
                                 let newTotal = total + (amount * (newVal / 100.0));
                                 let oldTotal = total + (amount * (oldVal / 100.0));

                                 if(Math.abs(newTotal - oldTotal) > 0.001) {
                                     // We have actually changed the total, so we'll proceed with it.
                                     const augments = combinedData.driversAndWorksheetData.singlePropertyAccount.accountPercentageDriver?.augments;
                                     if (augments) {
                                         if (augments.minValue !== null && augments.minValue !== undefined) {
                                             const minValue = parseFloat(augments.minValue);
                                             if (newTotal < minValue) {
                                                 newTotal = minValue;
                                             }
                                             if (oldTotal < minValue) {
                                                 oldTotal = minValue;
                                             }
                                         }
                                         if(augments.maxValue !== null && augments.maxValue !== undefined) {
                                             const maxValue = parseFloat(augments.maxValue);
                                             if (newTotal > maxValue) {
                                                 newTotal = maxValue;
                                             }
                                             if (oldTotal > maxValue) {
                                                 oldTotal = maxValue;
                                             }
                                         }
                                     }

                                     newColOriginal = colOriginal + (newTotal - oldTotal);
                                 } else {
                                     // We have not changed the total, so we just don't bother setting a new value.
                                     newColOriginal = colOriginal;
                                 }
                             }
                             originals.current[colIndex - 1] = newColOriginal;
                        }

                        const newTotal = originals.current[colIndex - 1];

                        if (newTotal != undefined && colOverride == null) {
                            hotRef.current.hotInstance.setDataAtCell(summaryRowIndex, colIndex, Math.round(newTotal), "computedSummary");
                        }
                    }

                    // if the change is in response to one of our recalculations, make sure to update the row summary
                    if (source == "computedDriver" && rowData.summaryFunction) {
                        updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);

                        if (colOriginal != undefined) {
                            originals.current[colIndex - 1] = colOriginal + (newVal - oldVal);
                        }

                        const newTotal = originals.current[colIndex - 1];

                        if (newTotal != undefined && colOverride == null) {
                            hotRef.current.hotInstance.setDataAtCell(summaryRowIndex, colIndex, Math.round(newTotal), "computedSummary");
                        }
                    }

                    if (source == "computedSummary" && rowData.summaryFunction) {
                        updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, 0, versionType);
                    }
                }
            });

            hotRef.current.hotInstance.removeHook("afterOnCellMouseUp", () => undefined);
            hotRef.current.hotInstance.addHook("afterOnCellMouseUp", function(_event, {row, col}, _td) {
                if (!hotRef.current?.hotInstance) {
                    return;
                }

                const rowData = data[row];

                if (rowData && rowData.id && col == 0) {
                    const isVisible = toggleHiddenRows(hotRef.current.hotInstance, data, rowData.id, row);
                    if (isVisible) {
                        visibleParentRows.current.add(row);
                    } else {
                        visibleParentRows.current.delete(row);
                    }
                    hiddenRows.current = hotRef.current.hotInstance.getPlugin('hiddenRows').getHiddenRows();
                    hotRef.current.hotInstance.render();
                }
            });

            hotRef.current.hotInstance.removeHook("beforeOnCellContextMenu", () => undefined);
            // dont add the contextual menu handler if the user does not have edit permission
            if (!tableLocked) {
                hotRef.current.hotInstance.addHook("beforeOnCellContextMenu", function(_event, _, _td) {
                    const hot = hotRef.current?.hotInstance;
                    if (!hot) {
                        return;
                    }
                    const selectedRange = hot.getSelectedRange()?.flatMap(range => range.getAll()) ?? [];
                    const overridesToRevert: TOverrideRevertValues[] = [];

                    for (const {row, col} of selectedRange) {
                        if (row < 0 || col < 0) {
                            continue;
                        }
                        const cell = hot.getCellMeta(row, col);
                        const originalValue = originals.current[col - 1];
                        const overrideValue = overrides.current[col - 1];

                        if (cell.renderer == "ForecastMonthCalculationOverrideable" && overrideValue != null && originalValue != undefined) {

                            overridesToRevert.push({
                                row,
                                col: col - 1,
                                original: originalValue,
                                override: hot.getDataAtCell(row, col),
                            });
                        }
                    }
                    if (overridesToRevert.length > 0) {
                        overrideRevertValues.current = overridesToRevert;
                    }
                    else {
                        overrideRevertValues.current = undefined;
                    }
                });
            }

            hotRef.current.hotInstance.removeHook("beforeUndoStackChange", () => undefined);
            hotRef.current.hotInstance.addHook("beforeUndoStackChange", (_doneActions, source) => {
                if (source && !hotValidUserActions.includes(source)) {
                    return false;
                }
            });

            // This is nasty but I did not find better way of keeping the styling in place while batch updates go through
            hotRef.current.hotInstance.removeHook("afterCellMetaReset", () => undefined);
            hotRef.current.hotInstance.addHook("afterCellMetaReset", () => {
                if (isSavingAccPctRows.current || isForceFetchingAfterBulkUpdateAccPct.current) {
                    const multiAccPctRowIndex = data?.findIndex?.(x => x.id == "multi_pct_of_account");

                    if (multiAccPctRowIndex != undefined && multiAccPctRowIndex > -1 && hotRef.current?.hotInstance) {
                        setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, true);
                    }
                }
            });
        })
    }, [tableSettings, tableLocked, overrideRevertValues.current]);

    return (
        <div className={css.accountTableWrapper}>
            <HotTable
                key={isReady.current ? "blue" : "green"}
                ref={hotRef}
                className={css.accountTable}
                settings={tableSettings}
                licenseKey={LICENSES.HandsOnTable}
                fixedRowsBottom={
                    data?.filter(x => ["versionSummary", "historical", "versionType"].includes(x.rowType)).length ?? 2
                }
            />
            {triedPasteRows != undefined &&
            <Modal onClose={() => setTriedPasteRows(undefined)} isLarge>
                <Header isDanger>
                    Can't update
                </Header>
                <Body>
                    You are attempting to update {triedPasteRows} rows at once which is more than {MAX_ROWS_TO_PASTE} allowed.
                </Body>
                <Footer>
                    <FooterItem>
                        <Button onClick={() => setTriedPasteRows(undefined)} isBasic>
                            Ok
                        </Button>
                    </FooterItem>
                </Footer>
                <Close aria-label="Close modal" />
            </Modal>
            }
            {showPasteBlockedAlert &&
            <Modal onClose={() => setShowPasteBlockedAlert(false)}>
                <Header isDanger>
                    Can't paste
                </Header>
                <Body>
                    To be able to paste into you need to enable clipboard access in your browser settings.
                </Body>
                <Footer>
                    <FooterItem>
                        <Button onClick={() => setShowPasteBlockedAlert(false)}>
                            Ok
                        </Button>
                    </FooterItem>
                </Footer>
                <Close aria-label="Close modal" />
            </Modal>
            }
        </div>
    );
}