import FileSaver from "file-saver";
import { BudgetingType } from "../../../BudgetingType";
import { AppConfig } from "../../../hooks/useConfig";
import { ExportPropertyBudgetQuery, VersionType } from "../../../__generated__/generated_types";
import { YearDefinition } from "../../annualsummary/YearDefinitions";
import { BudgetExportFormat } from "../BudgetExportData";
import {
    formatForExcelFullNoZeros,
    formatForExcelFullNoZerosWithMetrics,
    formatForExcelWithoutRawAccounts
} from "./formatters";

export function useExportExcelHoook() {
    const exportExcel = (
        exportFormat: BudgetExportFormat.EXCEL_FULL_NO_ZEROS
            | BudgetExportFormat.EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS
            | BudgetExportFormat.EXCEL_FULL_NO_ZEROS_WITH_METRICS,
        tab: BudgetingType,
        referenceYear: YearDefinition,
        extraReferenceYear: {
            referenceYear: number,
            referenceValueType: VersionType
        },
        data: ExportPropertyBudgetQuery,
        config: AppConfig
    ) => {
        let formattedDataPromise;
        if (exportFormat == BudgetExportFormat.EXCEL_FULL_NO_ZEROS) {
            formattedDataPromise = formatForExcelFullNoZeros({
                tab: tab,
                referenceYear: referenceYear,
                data: data,
                config: config
            });
        }
        else if (exportFormat == BudgetExportFormat.EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS) {
            formattedDataPromise = formatForExcelWithoutRawAccounts({
                tab: tab,
                compareToYear: referenceYear,
                referenceYear: extraReferenceYear,
                data: data,
                config: config
            });
        }
        else if (exportFormat == BudgetExportFormat.EXCEL_FULL_NO_ZEROS_WITH_METRICS) {
            formattedDataPromise = formatForExcelFullNoZerosWithMetrics({
                tab: tab,
                referenceYear: referenceYear,
                data: data,
                config: config
            });
        }

        if (formattedDataPromise) {
            formattedDataPromise.then(saveData => {
                let year = config.year;
                let periodName = "Reforecast";
                if (tab == BudgetingType.BUDGET) {
                    year = config.year + 1;
                    periodName = "Budget";
                }

                const fileName = `${year} ${periodName} - ${config.properties.currentProperty?.name ?? ""}`;
                FileSaver.saveAs(saveData, `${fileName}.xlsx`);
            });
        }
    };

    return exportExcel;
}