import { ReactElement, useMemo, useState } from "react";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Field as FormField, Label, Input } from "@zendeskgarden/react-forms";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Button } from "@zendeskgarden/react-buttons";
import { Inline } from "@zendeskgarden/react-loaders";
import { DateFormatter } from "../../../components/analyst-payroll-drawer/helpers/utils";

export type BulkDeleteConfirmProps = {
    what: "raises" | "bonuses" | "overtimes"
    onClose: () => void;
    onConfirm: () => void;
    loading: boolean;
}

export function BulkDeleteConfirm(props: BulkDeleteConfirmProps): ReactElement {
    function handleConfirm() {
        props.onConfirm();
    }

    return (
    <Modal onClose={() => props.onClose()} isLarge style={{overflow: "unset"}}>
        <Header>
            Bulk Delete {
                props.what == "raises" ? "Raises"
                : props.what == "bonuses" ? "Bonuses"
                : "Overtimes"
            }
        </Header>
        <Body>
            Are you sure you want to delete <b>{
                props.what == "raises" ? "raise"
                : props.what == "bonuses" ? "bonus"
                : "overtime"
            }</b> data?
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={() => props.onClose()} isBasic>
                    Cancel
                </Button>
            </FooterItem>
            <FooterItem>
                {
                    <Button
                        isPrimary
                        isDanger
                        onClick={() => handleConfirm()}
                        disabled={props.loading}
                    >
                        {
                            props.loading
                                ? <Inline size={24} aria-label="loading"/>
                                :
                                 <span>Confirm</span>
                        }
                    </Button>
                }
            </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
    </Modal>

    );
}