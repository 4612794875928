
import React, { useState, useContext, FormEvent } from 'react';
import { useValidPassword, useValidUsername } from '../../hooks/UseAuthHooks';
import { AuthContext } from '../../contexts/AuthContext';
import { Form, Button } from 'react-bootstrap';
import { BsEye, BsEyeSlash } from "react-icons/bs";

import './SignIn.scss';
import { Link } from 'react-router-dom';
import { FormContainer } from './FormContainer';
import LoginDesign from './LoginDesign';
import logo from "../../assets/icons/brand/logo.svg";
import { isEmailValid, isEmpty } from '../../utils/exports';
import { useGetCaseSensitiveEmailLazyQuery } from '../../__generated__/generated_types';

export interface SignInProps {
    signedIn?: () => void,
    needConfirmUser?: () => void,
    // needRequestCode?: () => void,
    provideResetPasswordRoute?: () => string,
    needSignUp?: () => void,
    // cancelled?: () => void
}

const SignIn: React.FC<SignInProps> = (params: SignInProps) => {

    const [showPassword, setShowPassword] = useState(false);
    // const { username, setUsername, usernameIsValid } = useValidUsername('');
    const [username, setUsername] = useState('');
    const { password, setPassword, passwordIsValid } = useValidPassword('');
    const [error, setError] = useState('');
    const [getEmail, { data, loading }] = useGetCaseSensitiveEmailLazyQuery();
    const [checkToggle, setCheckToggle] = useState(false);
    const [checking, setChecking] = useState(false);

    const isInValid = username.length === 0 || !passwordIsValid || password.length === 0;

    const authContext = useContext(AuthContext);

    const reset = () => {
        setPassword("");
        setChecking(false);
    };

    const sendAndCheck = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // check if email is valid
        if (!isEmailValid(username)) {
            setError('Please enter a valid email address');
            return;
        }

        // send and validate case sensitivity;
        getEmail({
            variables: {
                email: username
            }
        });

        setChecking(true);
        setCheckToggle(!checkToggle);
    };

    const signInClicked = async () => {
        try {

            if (isEmpty(data) || !data) return;
            const validatedEmail = data?.caseSensitiveEmail;

            await authContext.signInWithEmail(validatedEmail, password);
            setChecking(false);
            params.signedIn?.();
        } catch (err) {
            if ((err as unknown as { code: string }).code === 'UserNotConfirmedException') {
                // history.push('verify')
                params.needConfirmUser?.();
            } else {
                setError((err as unknown as { message: string }).message);
            }
        }
        reset();
    };

    React.useEffect(() => {
        if (!loading && data?.caseSensitiveEmail) {
            signInClicked()
                .catch(err => console.log(err));
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {
        };
    }, [data, checkToggle]);



    return (
        <FormContainer>
            <Form onSubmit={sendAndCheck}>
                <div className="left-side ">
                    <div className="left-side-container">
                        <Form.Label className="mb-3">
                            <span className="welcome-back-text"> Welcome back to <br /> </span>
                            <img className="mt-2" src={logo} alt="vizibly-logo" />
                        </Form.Label>
                        <div className="login-text mb-3"> Log in to access your account. </div>
                        {error &&
                            <p className="error-message">
                                The username and password you entered did not match our records. Please try again.
                            </p>
                        }
                        <div className="login-wrapper ">

                            <div className={"login-box " + (error !== "" ? "error-border" : username.length > 0 ? "focused-border" : "default-border")}>

                                <label className={"login-email " + (error !== "" ? "error-text" : username.length > 0 ? "focused-text" : "default-text")}> Email </label>
                                <div className="login-input-wrapper">
                                    <input
                                        className="login-input"
                                        type="email"
                                        value={username}
                                        required
                                        onChange={(value) => setUsername(value.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="login-wrapper mt-4">
                            <div className={"login-box " + (error !== "" ? "error-border" : password.length > 0 ? "focused-border" : "default-border")}>
                                <label className={"login-password " + (error !== "" ? "error-text" : password.length > 0 ? "focused-text" : "default-text")}> Password </label>
                                <span className="login-input-wrapper">
                                    <input
                                        className={"login-input " + (error ? "error-text" : "")}
                                        type={showPassword ? "text" : "password"}
                                        value={password || ""}
                                        onChange={(value) => setPassword(value.target.value)}
                                    />
                                    {password.length > 0 &&
                                        <div className="eye-icon-wrapper d-flex align-items-center">
                                            {!showPassword ?
                                                <BsEye onClick={() => setShowPassword(true)} />
                                                :
                                                <BsEyeSlash onClick={() => setShowPassword(false)} />
                                            }
                                        </div>
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="login-form-bottom">
                            <Link className="login-forgot-password " to={(params.provideResetPasswordRoute?.()) ?? '#'}>Forgot your password?</Link>
                            <Button
                                type="submit"
                                className="bt-block"
                                variant="primary"
                                disabled={isInValid || checking}
                            >
                                Log In
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
            <LoginDesign />
        </FormContainer>
    );
};

export default SignIn;
