import React, {ReactElement, useEffect, useState} from "react";
import GC from "@grapecity/spread-sheets";
import "@grapecity/spread-sheets-charts";
import useAppStore from "../../hooks/useAppStore";
import ShimmerRenderer, {ShimmerPageKey} from "../../components/shimmer/ShimmerRenderer";
import {SpreadSheets, Worksheet} from "@grapecity/spread-sheets-react";

const SpreadJSKey = process.env['REACT_APP_SPREADJS_LICENSE'] ?? 'no license found in ENV';
GC.Spread.Sheets.LicenseKey = SpreadJSKey;

export default function PerformanceMetrics(): ReactElement {

    const [isLoading, setLoading] = useState<boolean>(true);
    const appStore = useAppStore();

    const loadFile = async (fileUri: string) => {
        const response = await fetch(fileUri);
        return await response.json();
    };

    const started = (workbook: GC.Spread.Sheets.Workbook) => {
        loadFile("https://mock-reports.s3.amazonaws.com/PerformanceMetricData.ssjson")
            .then((jsonData) => {
                workbook.suspendPaint();
                workbook.suspendCalcService();
                workbook.fromJSON(jsonData);
                workbook.sheets.forEach(sheet => {
                    sheet.options.rowHeaderVisible = sheet.options.colHeaderVisible = false;
                    sheet.clearSelection();
                });
                workbook.options.scrollbarMaxAlign = true;
                workbook.resumeCalcService(true);
                workbook.resumePaint();
                setLoading(false);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if(appStore.isLoading) {
            appStore.set({isLoading: false});
        }
    });


    return (
        <>
            <div className='vz-wrapper'>
                <div className='vz-container'>
                    <div className='vz-content' style={{top:"0.5em", overflowY:"hidden"}}>
                        {isLoading ? <ShimmerRenderer modal={true} pageKey={ShimmerPageKey.ANNUAL_VIEW} /> : null }
                        <SpreadSheets
                            scrollbarMaxAlign={true}
                            workbookInitialized={started}>
                            <Worksheet></Worksheet>
                        </SpreadSheets>
                    </div>
                </div>
            </div>
        </>
    );
}
