export enum ModelingMethodType {
    LINE_ITEMS,
    MANUAL_ENTRY,
    MIXED_METHODS,
    OP_DRIVER,
    OVERRIDE,
    PAYROLL,
    PCT_OF_ACCOUNT,
    RENOVATION_COSTS,
    REVENUE
}