import React, {ReactElement} from "react";
import styles from '../AnalystPayrollDrawer.module.scss';
import {Field, Input, Label} from '@zendeskgarden/react-forms';
import {Col, Row} from '@zendeskgarden/react-grid';
import {Datepicker} from '@zendeskgarden/react-datepickers';

import {
    AllEmployeeClassifications,
    AllPayrollModeledEmployeeTypes,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys
} from "../helpers/types";
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import { Property } from "../../../contexts/properties/PropertiesContext";
import {DateFormatter} from "../helpers/utils";
import {ensureDateInLocale} from "../date-helpers";
import { TListPayrollPositions } from "../../../contexts/account/data/utils";
import { TLockedProperties } from "../../../pages/bulk-update/BulkUpdate";


interface PositionalDetailsProps {
    propertiesData: Property[]
    employeeData: LocalPayrollEmployeeModel,
    payrollPositionsData: TListPayrollPositions,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void,
    lockedProperties: TLockedProperties,
}

export default function TabPositionDetails(
    {
        propertiesData,
        employeeData,
        payrollPositionsData,
        updateFormState,
        lockedProperties,
    }: PositionalDetailsProps): ReactElement {

    if (!employeeData || !payrollPositionsData) {
        return <></>;
    }

    return (
        <div className={styles.positionDetailsContainer}>
            <div style={{maxWidth: "400px"}}>
                <h4 className={styles.positionDetailsHeader}>Position Details</h4>
                <Field className={styles.field}>
                    <Label className={styles.label}>Status</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            updateFormState(LocalPayrollEmployeeModelKeys.type, Array.isArray(items) ? items[0] : items);
                        }}
                        initialSelectedItems={employeeData.type ? [employeeData.type] : undefined}
                        openDropdownPlaceholder="Select Status"
                        closedDropdownPlaceholder=""
                        options={AllPayrollModeledEmployeeTypes.map(opt => ({value: opt.value, label: opt.label}))}
                        isError={employeeData.typeTouched && employeeData.type === undefined ? true : undefined}
                        onBlur={() => {
                            return undefined;
                        }}
                        noSearchIcon
                    />
                </Field>

                <Field className={styles.field}>
                    <Label className={styles.label}>Position Type</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            const newPositionId: string = Array.isArray(items) ? items[0] : items;
                            updateFormState(LocalPayrollEmployeeModelKeys.position, payrollPositionsData.find(p => p.id === newPositionId));
                        }}
                        initialSelectedItems={employeeData.position ? [employeeData.position.id] : undefined}
                        openDropdownPlaceholder="Select Position"
                        closedDropdownPlaceholder=""
                        options={payrollPositionsData.map(opt => ({value: opt.id, label: opt.name}))}
                        isError={employeeData.positionTouched && employeeData.position === undefined ? true : undefined}
                        onBlur={() => {
                            return undefined;
                        }}
                        noSearchIcon
                    />
                </Field>

                <Field className={styles.field}>
                    <Label className={styles.label}>Work Classification</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            updateFormState(LocalPayrollEmployeeModelKeys.classification, Array.isArray(items) ? items[0] : items);
                        }}
                        initialSelectedItems={employeeData.classification ? [employeeData.classification] : undefined}
                        openDropdownPlaceholder="Select Work Classification"
                        closedDropdownPlaceholder=""
                        options={AllEmployeeClassifications.map(opt => ({value: opt.value, label: opt.label}))}
                        isError={false}
                        onBlur={() => {
                            return undefined;
                        }}
                        noSearchIcon
                    />
                </Field>

                <Row justifyContent="between">
                    <Col size={6}>
                        <Field className={styles.field}>
                            <Label className={styles.label}>Start Date</Label>
                            <Datepicker
                                value={employeeData.startDate ? ensureDateInLocale(employeeData.startDate) : undefined}
                                onChange={(selectedStartDate) => {
                                    updateFormState(LocalPayrollEmployeeModelKeys.startDate, selectedStartDate.toISOString());
                                }}
                                formatDate={date => {
                                    return date ? DateFormatter.format(date) : "";
                                }}
                            >
                                <Input/>
                            </Datepicker>
                        </Field>
                    </Col>
                    <Col size={6}>
                        <Field className={styles.field}>
                            <Label className={styles.label}>End Date</Label>
                            <Datepicker
                                minValue={employeeData.startDate ? ensureDateInLocale(employeeData.startDate) : undefined}
                                value={employeeData.terminationDate ? ensureDateInLocale(employeeData.terminationDate) : undefined}
                                onChange={(selectedTerminationDate) => {
                                    updateFormState(LocalPayrollEmployeeModelKeys.terminationDate, selectedTerminationDate.toISOString());
                                }}
                                formatDate={date => {
                                    return date ? DateFormatter.format(date) : "";
                                }}
                            >
                                <Input disabled={employeeData.startDate === undefined} />
                            </Datepicker>
                        </Field>
                    </Col>
                </Row>

                <Field className={styles.field}>
                    <Label className={styles.label}>Properties (for cost allocation)</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            updateFormState(LocalPayrollEmployeeModelKeys.properties, propertiesData.filter(property => items.indexOf(property.id) !== -1));
                        }}
                        initialSelectedItems={employeeData.properties.map(property => property.id)}
                        openDropdownPlaceholder="Select Properties"
                        closedDropdownPlaceholder=""
                        options={propertiesData.map((opt => ({value: opt.id, label: opt.name, disabled: lockedProperties.reforecastLocked.includes(opt.id) && lockedProperties.budgetLocked.includes(opt.id)})))}
                        isError={employeeData.propertiesTouched && (employeeData.properties === undefined || employeeData.properties.length === 0) ? true : undefined}
                        onBlur={() => {
                            return undefined;
                        }}
                        isMulti={true}
                        noSearchIcon
                    />
                </Field>
            </div>
        </div>
    );
}
