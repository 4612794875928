import { useEffect, useState } from "react";
import { BudgetingType } from "../../BudgetingType";
import { useConfig } from "../../hooks/useConfig";
import { useExportPropertyBudgetLazyQuery, useExportPropertyBudgetT12ReportLazyQuery, VersionType } from "../../__generated__/generated_types";
import useYearDefinitions from "../annualsummary/YearDefinitions";
import { useExportCsvHoook } from "./csv/exportCsvHook";
import { useExportExcelHoook } from "./excel/exportExcelHook";
import { useExportT12ReportHook } from "./excel/exportT12ReportHook";

export enum BudgetExportFormat {
    CSV_REPORT = "CSV_REPORT",
    CSV_YARDI = "CSV_YARDI",
    CSV_YARDI_1_cent = "CSV_YARDI_1_CENT",
    CSV_APPFOLIO = "APPFOLIO",
    EXCEL_FULL_NO_ZEROS = "EXCEL_FULL_NO_ZEROS",
    EXCEL_FULL_NO_ZEROS_WITH_METRICS = "EXCEL_FULL_NO_ZEROS_WITH_METRICS",
    EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS = "EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS",
    EXCEL_T12_REPORT = "EXCEL_T12_REPORT",
}

export default function useBudgetExportData(tab: BudgetingType) {
    const config = useConfig();
    const { currentDefinition: referenceYear } = useYearDefinitions();
    const exportCsv = useExportCsvHoook();
    const exportExcel = useExportExcelHoook();
    const exportT12Report = useExportT12ReportHook();

    const [exportState, setExportState] = useState<{ isReadyToExport: boolean, exportFormat: BudgetExportFormat }>({ isReadyToExport: false, exportFormat: BudgetExportFormat.CSV_REPORT });

    const [loadData, { data, loading }] = useExportPropertyBudgetLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [loadT12ReportData, { data: dataT12Report, loading: loadingT12Report }] = useExportPropertyBudgetT12ReportLazyQuery({
        fetchPolicy: "no-cache"
    });


    function buildExtraReferenceYear() {
        let extraReferenceYear = {
            referenceYear: config.year,
            referenceValueType: VersionType.Budget
        };
        if (tab == BudgetingType.BUDGET) {
            extraReferenceYear = {
                referenceYear: config.year,
                referenceValueType: VersionType.YearTotal // TODO: kill year total!
            };
        }
        return extraReferenceYear;
    }

    useEffect(
        () => {
            if (!loading && config.isReady) {
                setExportState(
                    prev =>
                    ({
                        ...prev,
                        isReadyToExport: true,
                    })
                );
            }
        },
        [config.isReady]
    );

    const exportBudget = (exportFormat: BudgetExportFormat) => {
        if (!config.isReady) {
            return;
        }
        setExportState(
            prev =>
            ({
                ...prev,
                isReadyToExport: false,
                exportFormat: exportFormat
            })
        );

        if (exportFormat == BudgetExportFormat.EXCEL_T12_REPORT) {
            loadT12ReportData({
                variables: {
                    propertyId: config.properties.propertyId
                }
            });
        }
        else {
            let extraReferenceYear = {
                referenceYear: referenceYear.year,
                referenceValueType: referenceYear.type
            };
            let includeExtraReferenceYear = false;

            if (exportFormat == BudgetExportFormat.EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS) {
                includeExtraReferenceYear = true;
                extraReferenceYear = buildExtraReferenceYear();
            }
            loadData({
                variables: {
                    propertyId: config.properties.propertyId,
                    requestValueType: tab == BudgetingType.REFORECAST ? VersionType.Reforecast : VersionType.Budget,
                    referenceYear: referenceYear.year,
                    referenceValueType: referenceYear.type,
                    extraReferenceYear: extraReferenceYear.referenceYear,
                    extraReferenceValueType: extraReferenceYear.referenceValueType,
                    includeExtraReferenceYear: includeExtraReferenceYear
                }
            });
        }
    };

    useEffect(
        () => {
            if (data && !loading) {
                if (exportState.exportFormat == BudgetExportFormat.CSV_REPORT
                    || exportState.exportFormat == BudgetExportFormat.CSV_APPFOLIO
                    || exportState.exportFormat == BudgetExportFormat.CSV_YARDI
                    || exportState.exportFormat == BudgetExportFormat.CSV_YARDI_1_cent
                ) {
                    exportCsv(
                        exportState.exportFormat,
                        tab,
                        referenceYear,
                        data,
                        config
                    );
                }
                if (exportState.exportFormat == BudgetExportFormat.EXCEL_FULL_NO_ZEROS
                    || exportState.exportFormat == BudgetExportFormat.EXCEL_FULL_NO_ZEROS_WITH_METRICS
                    || exportState.exportFormat == BudgetExportFormat.EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS
                ) {
                    const extraReferenceYear = buildExtraReferenceYear();
                    exportExcel(
                        exportState.exportFormat,
                        tab,
                        referenceYear,
                        extraReferenceYear,
                        data,
                        config
                    );
                }
                setExportState(
                    prev =>
                    ({
                        ...prev,
                        isReadyToExport: true,
                    })
                );
            }
        },
        [data, loading]
    );

    useEffect(
        () => {
            if (dataT12Report && !loadingT12Report) {
                exportT12Report(dataT12Report, config);
                setExportState(
                    prev =>
                    ({
                        ...prev,
                        isReadyToExport: true,
                    })
                );
            }
        },
        [dataT12Report, loadingT12Report]
    );

    return {
        isReadyToExport: exportState,
        exportBudget: exportBudget
    };
}