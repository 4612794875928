import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useState } from "react";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/unitTypeManagement.module.scss";
import useAppStore from "../../hooks/useAppStore";

import { Field, MediaInput } from '@zendeskgarden/react-forms';
import { Button } from "@zendeskgarden/react-buttons";

import "native-injects";
import Card from "../simplified-revenue/components/Card";
import { Dropdown, Field as DDField, Select, Menu, Item } from "@zendeskgarden/react-dropdowns";
import { Property, useProperties } from "../../contexts/properties/PropertiesContext";
import { ListUnitTypesQuery, useAddUnitTypeMutation, useDeleteUnitTypeMutation, useListUnitTypesLazyQuery, useUpdateUnitTypeMutation } from "../../__generated__/generated_types";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { ReactComponent as CheckIcon } from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';
import { ReactComponent as PencilIcon } from '@zendeskgarden/svg-icons/src/16/pencil-stroke.svg';
import { ReactComponent as XIcon } from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import { EditUnitType } from "./components/EditUnitType";
import { toast } from "react-toastify";
import DeleteUnitTypeModal from "./components/DeleteUnitTypeModal";


export default function UnitTypeManagement(): ReactElement {
    const appStore = useAppStore();
    const {properties} = useProperties();

    const [getUnitTypes, {data: unitTypesData}] = useListUnitTypesLazyQuery({fetchPolicy: "no-cache"});
    const [deleteUnitType] = useDeleteUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const [updateUnitType] = useUpdateUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const [addUnitType] = useAddUnitTypeMutation({notifyOnNetworkStatusChange: true});


    const [selectedProperty, setSelectedProperty] = useState<Property>();
    const [unitTypes, setUnitTypes] = useState<ListUnitTypesQuery["listUnitTypes"]>([]);
    const [updateUnitTypeData, setUpdateUnitTypeData] = useState<{id: string, name: string}>();
    const [deleteUnitTypeData, setDeleteUnitTypeData] = useState<{id: string, name: string}>();
    const [addUnitTypeData, setAddUnitTypeData] = useState(false);

    function onPropertySelect(option: string) {
        setSelectedProperty(properties?.find(p => p.id == option));
    }

    function handleUpdateUnitType(unitTypeId: string, name: string) {
        updateUnitType({
            variables: {
                unitTypeId: unitTypeId,
                name: name
            }
        })
        .then(
            (data) => {
                setUpdateUnitTypeData(undefined);
                if (data.data?.updateUnitType) {
                    toast.success(`Unit Type updated successfully`);
                }
                if (selectedProperty) {
                    getUnitTypes({variables: {propertyId: selectedProperty.id}});
                }
            }
        );
    }

    function handleDeleteUnitType(unitTypeId: string) {
        deleteUnitType({
            variables: {
                unitTypeId: unitTypeId
            }
        })
        .then(
            (data) => {
                setDeleteUnitTypeData(undefined);
                if (data.data?.deleteUnitType) {
                    toast.success(`Unit Type deleted successfully`);
                }
                if (selectedProperty) {
                    getUnitTypes({variables: {propertyId: selectedProperty.id}});
                }
            }
        );
    }

    function handleAddUnitType(propertyId: string, name: string) {
        addUnitType({
            variables: {
                propertyId: propertyId,
                name: name
            }
        })
        .then(
            (data) => {
                setAddUnitTypeData(false);
                if (data.data?.addUnitType) {
                    toast.success(`Unit Type added successfully`);
                }
                if (selectedProperty) {
                    getUnitTypes({variables: {propertyId: selectedProperty.id}});
                }
            }
        );
    }


    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => undefined;
        },
        []
    );

    useEffect(() => {
        if (properties) {
            if (!selectedProperty) {
                setSelectedProperty(properties[0]);
            }
        }
    }, [properties]);

    useEffect(() => {
        if (selectedProperty) {
            getUnitTypes({variables: {propertyId: selectedProperty.id}});
        }
    }, [selectedProperty]);

    useEffect(() => {
        if (unitTypesData) {
            setUnitTypes([...unitTypesData.listUnitTypes]);
        }
    }, [unitTypesData]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Unit Types Management"}
                    subtitle={"Add/Rename/Delete Unit Types"}
                />
                <Card
                    title={"Unit Types For Property"}
                    actions={
                        <>
                            <Dropdown 
                                selectedItem={selectedProperty}
                                onSelect={(item) => onPropertySelect(item)}
                            >
                                <DDField className={css.propertySelect}>
                                    <Select>
                                        {selectedProperty?.name ?? ""}
                                    </Select>
                                </DDField>
                                <Menu>
                                    {properties?.map(property => (
                                        <Item key={property.id} value={property.id}>
                                            {property.name}
                                        </Item>
                                    ))}
                                </Menu>
                            </Dropdown>
                            <Field className={css.inputBox}>
                                <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                    if (!unitTypesData) {
                                        return;
                                    }
                                    setUnitTypes(unitTypesData.listUnitTypes.filter(p => p.name.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                                }} />
                            </Field>
                            <Button
                                isBasic
                                onClick={() => setAddUnitTypeData(true)}
                            >
                                <PlusIcon />
                            </Button>
                        </>
                    }
                >
                    <div className={css.unitTypesTableWrapper}>
                        <table className={css.unitTypesTable}>
                            <thead>
                                <tr className={css.headerCell}>
                                    <th className={`${css.cell} ${css.nameCell}`}>Name</th>
                                    <th className={`${css.cell} ${css.inUseCell}`}>Is In Use</th>
                                    <th className={`${css.cell} ${css.periodCell}`}>Added On</th>
                                </tr>
                            </thead>

                            <tbody>
                                {unitTypes.sortBy("name").map(unitType => (
                                        <tr key={unitType.id}>
                                            <td className={`${css.cell}`}>
                                                <div className={css.content}>
                                                    { unitType.name }
                                                    <div className={css.controls}>
                                                        <Button
                                                            isBasic
                                                            onClick={() => setUpdateUnitTypeData(unitType)}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                        <Button
                                                            isBasic
                                                            isDanger
                                                            onClick={() => setDeleteUnitTypeData(unitType)}
                                                        >
                                                            <XIcon />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={`${css.cell} ${css.inUseCell}`}>{ unitType.inUse ? <CheckIcon/> : "" }</td>
                                            <td className={`${css.cell} ${css.periodCell}`}>{ unitType.createdAt }</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
            {
            updateUnitTypeData &&
            <EditUnitType
                existingUnitTypes={unitTypesData?.listUnitTypes ?? []}
                initialName={updateUnitTypeData.name}
                unitTypeId={updateUnitTypeData.id}
                title="Edit Unit Type"
                onCancel={() => setUpdateUnitTypeData(undefined)}
                onSubmit={(name: string) => handleUpdateUnitType(updateUnitTypeData.id, name)}
            />
            }
            {
            addUnitTypeData &&
            <EditUnitType
                existingUnitTypes={unitTypesData?.listUnitTypes ?? []}
                title="Add Unit Type"
                onCancel={() => setAddUnitTypeData(false)}
                onSubmit={(name: string) => selectedProperty && handleAddUnitType(selectedProperty.id, name)}
            />
            }
            {deleteUnitTypeData && selectedProperty && (
            <DeleteUnitTypeModal
                unitTypeId={deleteUnitTypeData.id}
                unitTypeName={deleteUnitTypeData.name}
                budgetYear={selectedProperty.budgetYear}
                onClose={() => {
                    setDeleteUnitTypeData(undefined);
                }}
                onConfirm={() => {
                    handleDeleteUnitType(deleteUnitTypeData.id);
                }}
            />
        )}
        </ThemeProvider>
    );
}