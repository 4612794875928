import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { usePayrollEmployeeData, PayrollQueryData } from "./PayrollDataLoader";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import * as css from "./styles/payrollStyles.module.scss";
import { COLORS } from "../../constants/Colors";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { Inline } from "@zendeskgarden/react-loaders";
import { Button } from "@zendeskgarden/react-buttons";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import { AnalystSections } from "../../components/analyst/section-tabs/AnalystSectionTabs";
import { DateFormatter } from "../../components/analyst-payroll-drawer/helpers/utils";
import "native-injects";
import AnalystPayrollDrawer from "../../components/analyst-payroll-drawer/AnalystPayrollDrawer";
import { TLockedProperties } from "../bulk-update/BulkUpdate";
import { FixedSizeList } from "react-window";
import styled from "styled-components";

import { PayrollBonusFrequency, PayrollBonusType, PayrollCompensationItemCalculationType, PayrollCompensationItemModel, PayrollCompensationItemType, PayrollEmployeeBaseCompModel, PayrollEmployeeBonusModel, PayrollEmployeeCompItemModel, PayrollEmployeeModel, PayrollEmployeeOvertimeModel, PayrollEmployeeRaiseModel, PayrollPositionModel, PayrollRaiseFrequency, PayrollRaiseType, Property, useCreatePayrollEmployeeManyBonusesMutation, useCreatePayrollEmployeeManyOvertimesMutation, useCreatePayrollEmployeeManyRaisesMutation, useDeletePayrollEmployeeManyBonusesMutation, useDeletePayrollEmployeeManyOvertimesMutation, useDeletePayrollEmployeeManyRaisesMutation, useSetPayrollEmployeeManyCompItemsMutation, useUpdatePayrollEmployeeManyBonusesMutation, useUpdatePayrollEmployeeManyOvertimesMutation, useUpdatePayrollEmployeeManyRaisesMutation } from "../../__generated__/generated_types";
import { SearchRounded } from "@material-ui/icons";
import { Checkbox, Field, MediaInput, Label } from "@zendeskgarden/react-forms";
import { BulkUpdateBenefitTaxIns } from "./components/BulkUpdateBenefitTaxIns";
import { CustomZDDropdown } from "../../atoms/custom-zd-dropdown/CustomZDDropdown";
import { toast } from "react-toastify";
import { ReactComponent as PencilIcon } from '@zendeskgarden/svg-icons/src/16/pencil-stroke.svg';
import { ReactComponent as XIcon } from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import { DateSelector } from "./components/DateSelector";
import { BulkUpdateBonusRaise, Type as BulkUpdateBonusRaiseType} from "./components/BulkUpdateBonusRaise";
import { dateUtcNow } from "../../utils/helper-funcs";
import { BulkUpdateOvertime } from "./components/BulkUpdateOvertime";
import { BulkDeleteConfirm } from "./components/BulkDeleteConfirm";


const ScrollableTable = styled(Table).attrs({ role: 'presentation' })`
  /* stylelint-disable-next-line */
  display: block !important;
`;

const ScrollableHead = styled(Head)`
  display: block;
`;

const ScrollableHeaderRow = styled(HeaderRow).attrs({ role: 'row' })`
  /* stylelint-disable-next-line */
  display: table !important;
  width: 100%;
  table-layout: fixed;
`;

const ScrollableHeaderCell = styled(HeaderCell).attrs({ role: 'columnheader' })`
  /* stylelint-disable-next-line no-empty-source */
`;

const ScrollableBody = styled(Body)`
  /* stylelint-disable-next-line declaration-no-important */
  display: block !important;
`;

const ScrollableRow = styled(Row).attrs({ role: 'row' })`
  /* stylelint-disable-next-line declaration-no-important */
  display: table !important;
  table-layout: fixed;
`;

const ScrollableCell = styled(Cell).attrs({ role: 'cell' })`
  /* stylelint-disable-next-line no-empty-source */
`;

type CompItem = Pick<PayrollEmployeeCompItemModel, 'id' | 'calculationType' | 'costPerPayPeriod' | 'effectiveStartDate' | 'annualCostCap'>
& { compensationItem: (
  { __typename?: 'PayrollCompensationItemModel' }
  & Pick<PayrollCompensationItemModel, 'id' | 'clientId' | 'type'>)};

type PayrollEmployeeDataRow = {
    empNum: number | undefined;
    empRowNum: number;
    employeeId: string;
    employee: Pick<PayrollEmployeeModel, 'id' | 'externalSystemEmployeeId' | 'firstName' | 'lastName' | 'type' | 'classification' | 'startDate' | 'terminationDate'> | undefined;
    position: Pick<PayrollPositionModel, 'id' | 'name'> | undefined;
    property: Pick<Property, 'id' | 'name'> | undefined;
    baseComp: Pick<PayrollEmployeeBaseCompModel, 'expectedHoursPerWeek' | 'hourlyCompensation' | 'annualCompensation' | 'compensationType'> | null | undefined;
    overtime?: Pick<PayrollEmployeeOvertimeModel, 'id' | 'hourlyCompensation' | 'expectedHoursPerWeek' | 'effectiveStartDate' | 'effectiveEndDate'>;
    bonus?: Pick<PayrollEmployeeBonusModel, 'id' | 'bonusType' | 'frequency' | 'amount' | 'bonusMonth' | 'bonusDay' | 'onetimeBonusDate' | 'effectiveStartDate'>;
    raise?: Pick<PayrollEmployeeRaiseModel, 'id' | 'raiseType' | 'frequency' | 'amount' | 'raiseMonth' | 'raiseDay' | 'onetimeRaiseDate' | 'effectiveStartDate'>;
    medical: CompItem | undefined;
    dental: CompItem | undefined;
    vision: CompItem | undefined;
    r401k: CompItem | undefined;
    medicare: CompItem | undefined;
    socialSecurity: CompItem | undefined;
    futa: CompItem | undefined;
    suta: CompItem | undefined;
    wc: CompItem | undefined;
};

function buildPayrollDataRows(dbData: PayrollQueryData): PayrollEmployeeDataRow[] {
    const rows: PayrollEmployeeDataRow[] = [];
    let empNum = 0;
    for (const emp of dbData.sortBy(row => (row.firstName ?? "") + row.lastName)) {
        empNum++;
        const medical = emp.compItems.filter(ci => ci.compensationItem.type == 'MEDICAL');
        const dental = emp.compItems.filter(ci => ci.compensationItem.type == 'DENTAL');
        const vision = emp.compItems.filter(ci => ci.compensationItem.type == 'VISION');
        const r401k = emp.compItems.filter(ci => ci.compensationItem.type == 'RETIREMENT_401K_MATCH');
        const medicare = emp.compItems.filter(ci => ci.compensationItem.type == 'MEDICARE_TAX');
        const socialSecurity = emp.compItems.filter(ci => ci.compensationItem.type == 'SOCIAL_SECURITY_TAX');
        const futa = emp.compItems.filter(ci => ci.compensationItem.type == 'FEDERAL_UNEMPLOYMENT_TAX');
        const suta = emp.compItems.filter(ci => ci.compensationItem.type == 'STATE_UNEMPLOYMENT_TAX');
        const wc = emp.compItems.filter(ci => ci.compensationItem.type == 'WORKERS_COMPENSATION');
        const maxRows = Math.max(
            1,
            emp.raises.length,
            emp.bonuses.length,
            emp.overtime.length,
            emp.properties.length,
            medical.length,
            dental.length,
            vision.length,
            r401k.length,
            medicare.length,
            socialSecurity.length,
            futa.length,
            suta.length,
            wc.length,
        );
        for (let i = 0; i < maxRows; i++) {
            rows.push({
                empNum: i == 0 ? empNum : undefined,
                empRowNum: i,
                employeeId: emp.id,
                employee: i == 0 ? emp : undefined,
                position: i == 0 ? emp.position : undefined,
                property: emp.properties.length > i ? emp.properties[i] : undefined,
                baseComp: emp.baseComp,
                overtime: emp.overtime.length > i ? emp.overtime[i] : undefined,
                bonus: emp.bonuses.length > i ? emp.bonuses[i] : undefined,
                raise: emp.raises.length > i ? emp.raises[i] : undefined,
                medical: medical.length > i ? medical[i] : undefined,
                dental: dental.length > i ? dental[i] : undefined,
                vision: vision.length > i ? vision[i] : undefined,
                r401k: r401k.length > i ? r401k[0] : undefined,
                medicare: medicare.length > i ? medicare[i] : undefined,
                socialSecurity: socialSecurity.length > i ? socialSecurity[i] : undefined,
                futa: futa.length > i ? futa[i] : undefined,
                suta: suta.length > i ? suta[i] : undefined,
                wc: wc.length > i ? wc[i] : undefined
            });
        }
    }
    return rows;
}

function filterPayrollEmployees({
    dbData,
    propertyId,
    nameSearchString,
    filterPropertyIds,
    filterPositions,
    raiseOnceDateFilter,
    raiseRecurrDateFilter,
    bonusOnceDateFilter,
    bonusRecurrDateFilter,
    overtimeDateFilter
}: {
    dbData: PayrollQueryData,
    propertyId: string | undefined,
    nameSearchString: string | undefined,
    filterPropertyIds: string[] | undefined,
    filterPositions: string[],
    raiseOnceDateFilter: Date | undefined,
    raiseRecurrDateFilter: {raiseMonth: number, raiseDay: number} | undefined,
    bonusOnceDateFilter: Date | undefined,
    bonusRecurrDateFilter: {bonusMonth: number, bonusDay: number} | undefined,
    overtimeDateFilter: Date | undefined
}
): PayrollQueryData {
    // Ideally use dedicated propertyId aware endpoint - just lazy to do it.
    let filteredDbData = dbData;
    if (propertyId) {
        filteredDbData = filteredDbData.filter(row => row.properties.map(p => p.id).includes(propertyId));
    }
    else if (filterPropertyIds) {
        filteredDbData = filteredDbData.filter(row => row.properties.find(p => filterPropertyIds.includes(p.id)));
    }

    if (nameSearchString && nameSearchString.length > 0) {
        const nameSearchStringLowered = nameSearchString.toLowerCase();
        filteredDbData = filteredDbData.filter(row => {
            const employeeName = row.firstName + (row.lastName ? " " + row.lastName : "");
            return employeeName.toLowerCase().includes(nameSearchStringLowered);
        });
    }

    filteredDbData = filteredDbData.filter(row => filterPositions.includes(row.position.name));

    if (raiseOnceDateFilter) {
        const dateFilteredDbData: PayrollQueryData = [];
        for (const emp of filteredDbData) {
            const filteredEmpRaises = emp.raises.filter(raise =>
                raise.frequency == "ONCE"
                && raise.onetimeRaiseDate
                && (new Date(raise.onetimeRaiseDate).getTime() == raiseOnceDateFilter.getTime()));
            if (filteredEmpRaises.length > 0) {
                dateFilteredDbData.push({
                    ...emp,
                    raises: filteredEmpRaises
                });
            }
        }
        filteredDbData = dateFilteredDbData;
    }
    else if (raiseRecurrDateFilter) {
        const dateFilteredDbData: PayrollQueryData = [];
        for (const emp of filteredDbData) {
            const filteredEmpRaises = emp.raises.filter(raise =>
                raise.frequency == "ANNUALLY"
                && raise.raiseMonth == raiseRecurrDateFilter.raiseMonth
                && raise.raiseDay == raiseRecurrDateFilter.raiseDay
            );
            if (filteredEmpRaises.length > 0) {
                dateFilteredDbData.push({
                    ...emp,
                    raises: filteredEmpRaises
                });
            }
        }
        filteredDbData = dateFilteredDbData;
    }

    if (bonusOnceDateFilter) {
        const dateFilteredDbData: PayrollQueryData = [];
        for (const emp of filteredDbData) {
            const filteredEmpBonuses = emp.bonuses.filter(bonus =>
                bonus.frequency == "ONCE"
                && bonus.onetimeBonusDate
                && (new Date(bonus.onetimeBonusDate).getTime() == bonusOnceDateFilter.getTime()));
            if (filteredEmpBonuses.length > 0) {
                dateFilteredDbData.push({
                    ...emp,
                    bonuses: filteredEmpBonuses
                });
            }
        }
        filteredDbData = dateFilteredDbData;
    }
    else if (bonusRecurrDateFilter) {
        const dateFilteredDbData: PayrollQueryData = [];
        for (const emp of filteredDbData) {
            const filteredEmpBonuses = emp.bonuses.filter(bonus =>
                bonus.frequency == "ANNUALLY"
                && bonus.bonusMonth == bonusRecurrDateFilter.bonusMonth
                && bonus.bonusDay == bonusRecurrDateFilter.bonusDay
            );
            if (filteredEmpBonuses.length > 0) {
                dateFilteredDbData.push({
                    ...emp,
                    bonuses: filteredEmpBonuses
                });
            }
        }
        filteredDbData = dateFilteredDbData;
    }

    if (overtimeDateFilter) {
        const dateFilteredDbData: PayrollQueryData = [];
        for (const emp of filteredDbData) {
            const filteredEmpOvertimes = emp.overtime.filter(overtime =>
                overtime.effectiveStartDate
                && (new Date(overtime.effectiveStartDate).getTime() == overtimeDateFilter.getTime()));
            if (filteredEmpOvertimes.length > 0) {
                dateFilteredDbData.push({
                    ...emp,
                    overtime: filteredEmpOvertimes
                });
            }
        }
        filteredDbData = dateFilteredDbData;
    }

    return filteredDbData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SuppressExtraDiv({children}: any): ReactElement {
    return (
        <>
            {children}
        </>
    );
}

function countPayrollEmployees(rows: PayrollEmployeeDataRow[]): number {
    return rows.dedupe("employeeId").length;
}

function getPositions(data: PayrollQueryData): string[] {
    return data.map(emp => emp.position.name).dedupe().sort();
}

export function Payroll(props: {
    setSectionReady: (sectionId: AnalystSections, isReady: boolean) => void
    lockedProperties: TLockedProperties,
    filterPropertyIds: string[] | undefined,
    budgetYear: number,
    showSingleProperty?: string,
}): ReactElement {
    const {
        payrollEmployeeData,
        payrollEmployeeDataLoading,
        payrollEmployeeDataRefetch,
    } = usePayrollEmployeeData({budgetYear: props.budgetYear});
    const [bulkUpdateCompItems, {loading: bulkUpdateCompItemsLoading}] = useSetPayrollEmployeeManyCompItemsMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkCreateBonuses, {loading: bulkCreateBonusesLoading}] = useCreatePayrollEmployeeManyBonusesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkCreateRaises, {loading: bulkCreateRaisesLoading}] = useCreatePayrollEmployeeManyRaisesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkCreateOvertimes, {loading: bulkCreateOvertimesLoading}] = useCreatePayrollEmployeeManyOvertimesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkUpdateBonuses, {loading: bulkUpdateBonusesLoading}] = useUpdatePayrollEmployeeManyBonusesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkUpdateRaises, {loading: bulkUpdateRaisesLoading}] = useUpdatePayrollEmployeeManyRaisesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkUpdateOvertimes, {loading: bulkUpdateOvertimesLoading}] = useUpdatePayrollEmployeeManyOvertimesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkDeleteBonuses, {loading: bulkDeleteBonusesLoading}] = useDeletePayrollEmployeeManyBonusesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkDeleteRaises, {loading: bulkDeleteRaisesLoading}] = useDeletePayrollEmployeeManyRaisesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [bulkDeleteOvertimes, {loading: bulkDeleteOvertimesLoading}] = useDeletePayrollEmployeeManyOvertimesMutation({
        notifyOnNetworkStatusChange: true,
    });

    const [showAnalystPayrollDrawer, setShowAnalystPayrollDrawer] = useState<boolean>(false);
    const [editPayrollEmplloyeeId, setEditPayrollEmployeeId] = useState<string|undefined>(undefined);
    const [payrollEmployeeDataRows, setPayrollEmployeeDataRows] = useState<PayrollEmployeeDataRow[]>();
    const [selectedPayrollEmployees, setSelectedPayrollEmployees] = useState<Set<string>>(new Set());
    const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
    const [bulkUpdateCompItemType, setBulkUpdateCompItemType] = useState<PayrollCompensationItemType>();
    const [showBulkCreateRaises, setShowBulkCreateRaises] = useState(false);
    const [showBulkCreateBonuses, setShowBulkCreateBonuses] = useState(false);
    const [showBulkCreateOvertimes, setShowBulkCreateOvertimes] = useState(false);
    const [showBulkUpdateRaises, setShowBulkUpdateRaises] = useState(false);
    const [showBulkUpdateBonuses, setShowBulkUpdateBonuses] = useState(false);
    const [showBulkUpdateOvertimes, setShowBulkUpdateOvertimes] = useState(false);
    const [showBulkDeleteRaises, setShowBulkDeleteRaises] = useState(false);
    const [showBulkDeleteBonuses, setShowBulkDeleteBonuses] = useState(false);
    const [showBulkDeleteOvertimes, setShowBulkDeleteOvertimes] = useState(false);
    const [employeeNameSearch, setEmployeeNameSearch] = useState<string>();

    const [raiseOnceDateFilter, setRaiseOnceDateFilter] = useState<Date>();
    const [raiseRecurrDateFilter, setRaiseRecurrDateFilter] = useState<{raiseMonth: number, raiseDay: number}>();

    const [bonusOnceDateFilter, setBonusOnceDateFilter] = useState<Date>();
    const [bonusRecurrDateFilter, setBonusRecurrDateFilter] = useState<{bonusMonth: number, bonusDay: number}>();

    const [overtimeDateFilter, setOvertimeDateFilter] = useState<Date>();

    const tableRef = useRef(null);
    const rowsRef = useRef<FixedSizeList>(null);

    useEffect(
        () => {
            props.setSectionReady(AnalystSections.Payroll, true);
            payrollEmployeeDataRefetch();
        }, []
    );

    useEffect(
        () => {
            if (!payrollEmployeeDataLoading && payrollEmployeeData) {
                const selectedPositions = getPositions(payrollEmployeeData);
                const filtered = filterPayrollEmployees({
                    dbData: payrollEmployeeData,
                    propertyId: props.showSingleProperty,
                    nameSearchString: undefined,
                    filterPropertyIds: props.filterPropertyIds,
                    filterPositions: selectedPositions,
                    raiseOnceDateFilter: raiseOnceDateFilter,
                    raiseRecurrDateFilter: raiseRecurrDateFilter,
                    bonusOnceDateFilter: bonusOnceDateFilter,
                    bonusRecurrDateFilter: bonusRecurrDateFilter,
                    overtimeDateFilter: overtimeDateFilter
                });
                const rows = buildPayrollDataRows(filtered);
                setSelectedPositions(selectedPositions);
                setPayrollEmployeeDataRows(rows);
                deselectAll();
            }
        }, [payrollEmployeeDataLoading, payrollEmployeeData]
    );

    useEffect(
        () => {
            if (payrollEmployeeData) {
                const filtered = filterPayrollEmployees({
                    dbData: payrollEmployeeData,
                    propertyId: props.showSingleProperty,
                    nameSearchString: employeeNameSearch,
                    filterPropertyIds: props.filterPropertyIds,
                    filterPositions: selectedPositions,
                    raiseOnceDateFilter: raiseOnceDateFilter,
                    raiseRecurrDateFilter: raiseRecurrDateFilter,
                    bonusOnceDateFilter: bonusOnceDateFilter,
                    bonusRecurrDateFilter: bonusRecurrDateFilter,
                    overtimeDateFilter: overtimeDateFilter
                });
                const rows = buildPayrollDataRows(filtered);
                setPayrollEmployeeDataRows(rows);
                deselectAll();
            }
        }, [
            employeeNameSearch,
            props.filterPropertyIds,
            selectedPositions,
            raiseOnceDateFilter,
            raiseRecurrDateFilter,
            bonusOnceDateFilter,
            bonusRecurrDateFilter,
            overtimeDateFilter
        ]
    );

    const bonusDates = useMemo(
        (): {once: Date[], recurring: {month: number, day: number}[]} => {
            const once: Date[] = [];
            const recurring: {month: number, day: number}[] = [];
            if (payrollEmployeeData) {
                for (const emp of payrollEmployeeData) {
                    for (const b of emp.bonuses) {
                        if (b.frequency == "ANNUALLY" && b.bonusMonth != undefined && b.bonusDay != undefined) {
                            recurring.push({month: b.bonusMonth, day: b.bonusDay})
                        }
                        else if (b.frequency == "ONCE" && b.onetimeBonusDate != undefined) {
                            once.push(new Date(b.onetimeBonusDate));
                        }
                    }
                }
            }
            return {
                once: once.dedupe(d => d.toString()),
                recurring: recurring.dedupe(r => `${r.month}-${r.day}`)
            };
        },
        [payrollEmployeeData]
    );

    const raiseDates = useMemo(
        (): {once: Date[], recurring: {month: number, day: number}[]} => {
            const once: Date[] = [];
            const recurring: {month: number, day: number}[] = [];
            if (payrollEmployeeData) {
                for (const emp of payrollEmployeeData) {
                    for (const r of emp.raises) {
                        if (r.frequency == "ANNUALLY" && r.raiseMonth != undefined && r.raiseDay != undefined) {
                            recurring.push({month: r.raiseMonth, day: r.raiseDay});
                        }
                        else if (r.frequency == "ONCE" && r.onetimeRaiseDate != undefined) {
                            once.push(new Date(r.onetimeRaiseDate));
                        }
                    }
                }
            }
            return {
                once: once.dedupe(d => d.toString()),
                recurring: recurring.dedupe(r => `${r.month}-${r.day}`)
            };
        },
        [payrollEmployeeData]
    );

    const overtimeDates = useMemo(
        (): {once: Date[], recurring: {month: number, day: number}[]} => {
            const once: Date[] = [];
            if (payrollEmployeeData) {
                for (const emp of payrollEmployeeData) {
                    for (const o of emp.overtime) {
                        once.push(new Date(o.effectiveStartDate));
                    }
                }
            }
            return {
                once: once.dedupe(d => d.toString()),
                recurring: []
            };
        },
        [payrollEmployeeData]
    );

    const bonusesDisplayed = useMemo(() => Boolean(payrollEmployeeDataRows?.find(row => selectedPayrollEmployees.has(row.employeeId) && row.bonus != undefined)),
        [payrollEmployeeDataRows, selectedPayrollEmployees]
    );

    const raisesDisplayed = useMemo(() => Boolean(payrollEmployeeDataRows?.find(row => selectedPayrollEmployees.has(row.employeeId) && row.raise != undefined)),
        [payrollEmployeeDataRows, selectedPayrollEmployees]
    );

    const overtimesDisplayed = useMemo(() => Boolean(payrollEmployeeDataRows?.find(row => selectedPayrollEmployees.has(row.employeeId) && row.overtime != undefined)),
        [payrollEmployeeDataRows, selectedPayrollEmployees]
    );

    /**
     * useEffect(): editPayrollEmplloyeeId
     * Called as the first phase of editing an existing employee. Once the employee record is known, the drawer is opened.
     */
    useEffect(
        ()=>{
            if(!editPayrollEmplloyeeId){
                return;
            }
            setShowAnalystPayrollDrawer(true);
        },
        [editPayrollEmplloyeeId]
    );



    function selectAll() {
        if (payrollEmployeeDataRows) {
            setSelectedPayrollEmployees(new Set(payrollEmployeeDataRows.map(e => e.employeeId).dedupe()));
        }
        else {
            setSelectedPayrollEmployees(new Set([]));
        }
    }

    function deselectAll() {
        setSelectedPayrollEmployees(new Set([]));
    }

    function selectEmployee(id: string): void {
        setSelectedPayrollEmployees(prev => {
            const newSet = new Set(prev);
            newSet.add(id);

            return newSet;
        });
    }

    function deselectEmployee(id: string): void {
        setSelectedPayrollEmployees(prev => {
            const newSet = new Set(prev);
            newSet.delete(id);
            return newSet;
        });
    }

    function handlebulkUpdateCompItems(
        what: PayrollCompensationItemType,
        effectiveStartDate: Date | undefined,
        percentOfSalary: number | undefined,
        contributionCap: number | undefined
    ) {
        bulkUpdateCompItems({
            variables: {
                employeeIds: Array.from(selectedPayrollEmployees),
                type: what,
                createCalcType: [PayrollCompensationItemType.Medical, PayrollCompensationItemType.Vision, PayrollCompensationItemType.Dental].includes(what) ?
                        PayrollCompensationItemCalculationType.FixedAmount
                        :
                        PayrollCompensationItemCalculationType.PercentOfPrimaryCompensation,
                input: {
                    annualCostCap: contributionCap ? contributionCap.toString() : null,
                    costPerPayPeriod: percentOfSalary ? percentOfSalary.toString() : null,
                    effectiveStartDate: effectiveStartDate ? effectiveStartDate : null
                }
            }
        })
        .then(
            () => {
                setBulkUpdateCompItemType(undefined);
                toast.success(`Update Complete`);
                payrollEmployeeDataRefetch();
            }
        );
    }

    function handleBulkCreateBonuses(type: BulkUpdateBonusRaiseType, d: Date | undefined, val: number, month: number | undefined, day: number | undefined) {
        if (selectedPayrollEmployees.size > 0) {
            bulkCreateBonuses({
                variables: {
                    employeeIds: Array.from(selectedPayrollEmployees),
                    bonusType: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.recurring_fixed ? PayrollBonusType.FixedAmount : PayrollBonusType.PercentOfSalary,
                    frequency: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.once_percent ? PayrollBonusFrequency.Once : PayrollBonusFrequency.Annually,
                    amount: val.toString(),
                    bonusDay: day,
                    bonusMonth: month,
                    onetimeBonusDate: d,
                    effectiveStartDate: dateUtcNow().toISOString()
                }
            })
            .then(
                () => {
                    setShowBulkCreateBonuses(false);
                    toast.success(`Update Complete`);
                    setBonusOnceDateFilter(undefined);
                    setBonusRecurrDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkCreateRaises(type: BulkUpdateBonusRaiseType, d: Date | undefined, val: number, month: number | undefined, day: number | undefined) {
        if (selectedPayrollEmployees.size > 0) {
            bulkCreateRaises({
                variables: {
                    employeeIds: Array.from(selectedPayrollEmployees),
                    raiseType: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.recurring_fixed ? PayrollRaiseType.FixedAmount : PayrollRaiseType.PercentOfSalary,
                    frequency: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.once_percent ? PayrollRaiseFrequency.Once : PayrollRaiseFrequency.Annually,
                    amount: val.toString(),
                    raiseDay: day,
                    raiseMonth: month,
                    onetimeRaiseDate: d,
                    effectiveStartDate: dateUtcNow().toISOString()
                }
            })
            .then(
                () => {
                    setShowBulkCreateRaises(false);
                    toast.success(`Update Complete`);
                    setRaiseOnceDateFilter(undefined);
                    setRaiseRecurrDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkCreateOvertimes(startDate: Date, endDate: Date | undefined, hoursWeek: number, hourlyRate: number) {
        if (selectedPayrollEmployees.size > 0) {
            bulkCreateOvertimes({
                variables: {
                    employeeIds: Array.from(selectedPayrollEmployees),
                    hourlyRate: hourlyRate.toString(),
                    hoursWeek: hoursWeek.toString(),
                    effectiveStartDate: startDate,
                    effectiveEndDate: endDate
                }
            })
            .then(
                () => {
                    setShowBulkCreateOvertimes(false);
                    toast.success(`Update Complete`);
                    setOvertimeDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkUpdateBonuses(type: BulkUpdateBonusRaiseType, d: Date | undefined, val: number, month: number | undefined, day: number | undefined) {
        if (payrollEmployeeDataRows) {
            const bonusIds = payrollEmployeeDataRows
                .filter(row => selectedPayrollEmployees.has(row.employeeId) && row.bonus != undefined)
                .map(row => row.bonus?.id)
                .filterNull()
                .dedupe();

            bulkUpdateBonuses({
                variables: {
                    bonusIds: bonusIds,
                    bonusType: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.recurring_fixed ? PayrollBonusType.FixedAmount : PayrollBonusType.PercentOfSalary,
                    frequency: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.once_percent ? PayrollBonusFrequency.Once : PayrollBonusFrequency.Annually,
                    amount: val.toString(),
                    bonusDay: day,
                    bonusMonth: month,
                    onetimeBonusDate: d,
                    effectiveStartDate: dateUtcNow().toISOString()
                }
            })
            .then(
                () => {
                    setShowBulkUpdateBonuses(false);
                    toast.success(`Update Complete`);
                    setBonusOnceDateFilter(undefined);
                    setBonusRecurrDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkUpdateRaises(type: BulkUpdateBonusRaiseType, d: Date | undefined, val: number, month: number | undefined, day: number | undefined) {
        if (payrollEmployeeDataRows) {
            const raiseIds = payrollEmployeeDataRows
                .filter(row => selectedPayrollEmployees.has(row.employeeId) && row.raise != undefined)
                .map(row => row.raise?.id)
                .filterNull()
                .dedupe();

            bulkUpdateRaises({
                variables: {
                    raiseIds: raiseIds,
                    raiseType: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.recurring_fixed ? PayrollRaiseType.FixedAmount : PayrollRaiseType.PercentOfSalary,
                    frequency: type == BulkUpdateBonusRaiseType.once_fixed || type == BulkUpdateBonusRaiseType.once_percent ? PayrollRaiseFrequency.Once : PayrollRaiseFrequency.Annually,
                    amount: val.toString(),
                    raiseDay: day,
                    raiseMonth: month,
                    onetimeRaiseDate: d,
                    effectiveStartDate: dateUtcNow().toISOString()
                }
            })
            .then(
                () => {
                    setShowBulkUpdateRaises(false);
                    toast.success(`Update Complete`);
                    setRaiseOnceDateFilter(undefined);
                    setRaiseRecurrDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkUpdateOvertimes(startDate: Date, endDate: Date | undefined, hoursWeek: number, hourlyRate: number) {
        if (payrollEmployeeDataRows) {
            const overtimeIds = payrollEmployeeDataRows
                .filter(row => selectedPayrollEmployees.has(row.employeeId) && row.overtime != undefined)
                .map(row => row.overtime?.id)
                .filterNull()
                .dedupe();

            bulkUpdateOvertimes({
                variables: {
                    overtimeIds: overtimeIds,
                    hourlyRate: hourlyRate.toString(),
                    hoursWeek: hoursWeek.toString(),
                    effectiveStartDate: startDate,
                    effectiveEndDate: endDate
                }
            })
            .then(
                () => {
                    setShowBulkUpdateOvertimes(false);
                    toast.success(`Update Complete`);
                    setOvertimeDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkDeleteBonuses() {
        if (payrollEmployeeDataRows) {
            const bonusIds = payrollEmployeeDataRows
                .filter(row => selectedPayrollEmployees.has(row.employeeId) && row.bonus != undefined)
                .map(row => row.bonus?.id)
                .filterNull()
                .dedupe();

            bulkDeleteBonuses({
                variables: {
                    bonusIds: bonusIds
                }
            })
            .then(
                () => {
                    setShowBulkDeleteBonuses(false);
                    toast.success(`Delete Complete`);
                    setBonusOnceDateFilter(undefined);
                    setBonusRecurrDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkDeleteRaises() {
        if (payrollEmployeeDataRows) {
            const raiseIds = payrollEmployeeDataRows
                .filter(row => selectedPayrollEmployees.has(row.employeeId) && row.raise != undefined)
                .map(row => row.raise?.id)
                .filterNull()
                .dedupe();

            bulkDeleteRaises({
                variables: {
                    raiseIds: raiseIds
                }
            })
            .then(
                () => {
                    setShowBulkDeleteRaises(false);
                    toast.success(`Delete Complete`);
                    setRaiseOnceDateFilter(undefined);
                    setRaiseRecurrDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    function handleBulkDeleteOvertimes() {
        if (payrollEmployeeDataRows) {
            const overtimeIds = payrollEmployeeDataRows
                .filter(row => selectedPayrollEmployees.has(row.employeeId) && row.overtime != undefined)
                .map(row => row.overtime?.id)
                .filterNull()
                .dedupe();

            bulkDeleteOvertimes({
                variables: {
                    overtimeIds: overtimeIds
                }
            })
            .then(
                () => {
                    setShowBulkDeleteOvertimes(false);
                    toast.success(`Delete Complete`);
                    setOvertimeDateFilter(undefined);
                    payrollEmployeeDataRefetch();
                }
            );
        }
    }

    return (
        <div className={css.employeePageWrapper}>
            <ThemeProvider theme={ViziblyTheme}>
                <div className={css.employeePageInner}>
                    <div className={css.tableHeaderActions}>
                        <h5 className={css.tableHeaderActionsLeft}>
                            Employees
                            {
                                payrollEmployeeDataLoading && <Inline size={24} color={COLORS.PRIMARY_500} />
                            }
                        </h5>
                        <div className={css.tableHeaderActionsRight}>
                            <Button
                                isPrimary
                                className={css.tableHeaderActionsButton}
                                onClick={() => {
                                    setEditPayrollEmployeeId("");
                                    setShowAnalystPayrollDrawer(true);
                                }}
                            >
                                Add Staff
                            </Button>
                        </div>
                    </div>

                    <div className={css.employeeTableWrapper} role="grid" aria-rowcount={payrollEmployeeData?.length ?? 0}>
                        <ScrollableTable className={css.employeeTable} size={"large"} ref={tableRef}
                            onScroll={
                                () => {
                                    if (tableRef.current && rowsRef.current) {
                                        const {scrollTop} = (tableRef.current as any);
                                        if (rowsRef.current != null) {
                                            rowsRef.current.scrollTo(scrollTop);
                                        }
                                    }
                                }
                            }
                        >
                            <ScrollableHead isSticky>
                                <ScrollableHeaderRow className={css.headerRowAbove}>
                                    <ScrollableHeaderCell className={`${css.checkboxCell}`} />
                                    <ScrollableHeaderCell className={`${css.numberCell}`} />
                                    <ScrollableHeaderCell className={`${css.nameCell}`}/>
                                    <ScrollableHeaderCell className={`${css.employeeIdCell}`} />
                                    <ScrollableHeaderCell className={`${css.statusCell}`} />
                                    <ScrollableHeaderCell className={`${css.positionTypeCell}`}/>
                                    <ScrollableHeaderCell className={`${css.workClassificationCell}`} />
                                    <ScrollableHeaderCell className={`${css.propertyCell}`} />
                                    <ScrollableHeaderCell className={`${css.dateCell}`} />
                                    <ScrollableHeaderCell className={`${css.dateCell}`} />
                                    <ScrollableHeaderCell colSpan={6} className={css.baseCompHeader}>BASE COMPENSATION</ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={5} className={css.raisesHeader}>
                                        RAISES
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setShowBulkCreateRaises(true)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PlusIcon/>
                                        </Button>
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setShowBulkUpdateRaises(true)}
                                            disabled={selectedPayrollEmployees.size == 0 || !raisesDisplayed}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                        <Button
                                            isBasic
                                            isDanger
                                            size="small"
                                            onClick={() => setShowBulkDeleteRaises(true)}
                                            disabled={selectedPayrollEmployees.size == 0 || !raisesDisplayed}
                                        >
                                            <XIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={5} className={css.bonusesHeader}>
                                        BONUSES
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setShowBulkCreateBonuses(true)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PlusIcon/>
                                        </Button>
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setShowBulkUpdateBonuses(true)}
                                            disabled={selectedPayrollEmployees.size == 0 || !bonusesDisplayed}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                        <Button
                                            isBasic
                                            isDanger
                                            size="small"
                                            onClick={() => setShowBulkDeleteBonuses(true)}
                                            disabled={selectedPayrollEmployees.size == 0 || !bonusesDisplayed}
                                        >
                                            <XIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={5} className={css.overtimeHeader}>
                                        OVERTIME
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setShowBulkCreateOvertimes(true)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PlusIcon/>
                                        </Button>
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setShowBulkUpdateOvertimes(true)}
                                            disabled={selectedPayrollEmployees.size == 0 || !overtimesDisplayed}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                        <Button
                                            isBasic
                                            isDanger
                                            size="small"
                                            onClick={() => setShowBulkDeleteOvertimes(true)}
                                            disabled={selectedPayrollEmployees.size == 0 || !overtimesDisplayed}
                                        >
                                            <XIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={3} className={css.benefitHeader}>
                                        MEDICAL
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.Medical)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={3} className={css.benefitHeader}>
                                        DENTAL
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.Dental)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={3} className={css.benefitHeader}>
                                        VISION
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.Vision)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={4} className={css.taxAndInsuranceHeader}>
                                        401K
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.Retirement_401KMatch)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={4} className={css.taxAndInsuranceHeader}>
                                        MEDICARE
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.MedicareTax)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={4} className={css.taxAndInsuranceHeader}>
                                        SOCIAL SECURITY TAX
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.SocialSecurityTax)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                        </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={4} className={css.taxAndInsuranceHeader}>
                                        FEDERAL UNEMPLOYMENT TAX
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.FederalUnemploymentTax)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={4} className={css.taxAndInsuranceHeader}>
                                        STATE UNEMPLOYMENT TAX
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.StateUnemploymentTax)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell colSpan={4} className={css.taxAndInsuranceHeader}>
                                        WORKERS COMPENSATION
                                        <Button
                                            isBasic
                                            size="small"
                                            onClick={() => setBulkUpdateCompItemType(PayrollCompensationItemType.WorkersCompensation)}
                                            disabled={selectedPayrollEmployees.size == 0}
                                        >
                                            <PencilIcon/>
                                        </Button>
                                    </ScrollableHeaderCell>
                                </ScrollableHeaderRow>

                                <ScrollableHeaderRow className={css.headerRow}>
                                    <ScrollableHeaderCell className={css.checkboxCell}>
                                        <Field>
                                            <Checkbox
                                                className={css.employeeTableCheckbox}
                                                checked={payrollEmployeeDataRows && selectedPayrollEmployees.size == countPayrollEmployees(payrollEmployeeDataRows)}
                                                onChange={() => {
                                                    payrollEmployeeDataRows && selectedPayrollEmployees.size == countPayrollEmployees(payrollEmployeeDataRows) ? deselectAll() : selectAll();
                                                }}
                                                indeterminate={selectedPayrollEmployees.size != 0 && payrollEmployeeDataRows && selectedPayrollEmployees.size != countPayrollEmployees(payrollEmployeeDataRows)}
                                            >
                                                <Label className={css.employeeTableCheckbox} />
                                            </Checkbox>
                                        </Field>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={css.numberCell}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.nameCell}`}>
                                        Staff Name
                                        <Field>
                                            <MediaInput
                                                className={css.tableHeaderActionsSearch}
                                                style={{height: "30px"}}
                                                placeholder="Search"
                                                isCompact
                                                onChange={evt => 
                                                    setEmployeeNameSearch(evt.target.value.toLowerCase())
                                                }
                                                start={
                                                    <SearchRounded />
                                                }
                                            />
                                        </Field>
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.employeeIdCell}`}>Employee ID</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.statusCell}`}>Status</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.positionTypeCell}`}>
                                        Position Type
                                        <CustomZDDropdown
                                            initialSelectedItems={selectedPositions}
                                            applySelectedItems={function (items: any): void {
                                                setSelectedPositions(items);
                                            }}
                                            openDropdownPlaceholder={"Select Position Type(s)"}
                                            closedDropdownPlaceholder={undefined}
                                            options={payrollEmployeeData ? getPositions(payrollEmployeeData).map(v => ({value: v, label: v})) : []}
                                            isError={undefined}
                                            isMulti
                                            allOption
                                        />
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.workClassificationCell}`}>Work Classification</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.propertyCell}`}>Property</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCellMain}`}>Start Date</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCellMain}`}>End Date</ScrollableHeaderCell>
                                    {/* BASE COMPENSATION */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Type</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Annualized Amount</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Hourly Rate</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Hours/Week</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Pay Schedule</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>First Pay Date</ScrollableHeaderCell>
                                    {/* RAISES */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Type</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Percent (%)</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Amount ($)</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>
                                        Effective Date
                                        <DateSelector
                                            once={raiseDates.once}
                                            recurring={raiseDates.recurring}
                                            onSelectOnce={d => {setRaiseOnceDateFilter(d); setRaiseRecurrDateFilter(undefined)}}
                                            onSelectRecurring={(month, day) => {setRaiseOnceDateFilter(undefined); setRaiseRecurrDateFilter({raiseMonth: month, raiseDay: day})}}
                                            onSelectAny={() => {setRaiseOnceDateFilter(undefined); setRaiseRecurrDateFilter(undefined)}}
                                        />
                                    </ScrollableHeaderCell>
                                    {/* BONUSES */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Type</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Percent (%)</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Amount ($)</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>
                                        Bonus Date
                                        <DateSelector
                                            once={bonusDates.once}
                                            recurring={bonusDates.recurring}
                                            onSelectOnce={d => {setBonusOnceDateFilter(d); setBonusRecurrDateFilter(undefined)}}
                                            onSelectRecurring={(month, day) => {setBonusOnceDateFilter(undefined); setBonusRecurrDateFilter({bonusMonth: month, bonusDay: day})}}
                                            onSelectAny={() => {setBonusOnceDateFilter(undefined); setBonusRecurrDateFilter(undefined)}}
                                        />
                                    </ScrollableHeaderCell>
                                    {/* OVERTIME */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Hourly Rate</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Hours/Week</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>
                                        Start Date
                                        <DateSelector
                                            once={overtimeDates.once}
                                            recurring={overtimeDates.recurring}
                                            onSelectOnce={d => setOvertimeDateFilter(d)}
                                            onSelectRecurring={() => { /* no-op */}}
                                            onSelectAny={() => setOvertimeDateFilter(undefined)}
                                        />
                                    </ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>End Date</ScrollableHeaderCell>
                                    {/* MEDICAL */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Cost per Pay Period</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* DENTAL */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Cost per Pay Period</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* VISION */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Cost per Pay Period</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* 401K */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>% of Salary</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Contribution Cap</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* MEDICARE */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>% of Salary</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Contribution Cap</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* SOCIAL SECURITY TAX */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>% of Salary</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Contribution Cap</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* FEDERAL UNEMPLOYMENT TAX */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>% of Salary</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Contribution Cap</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* STATE UNEMPLOYMENT TAX */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>% of Salary</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Contribution Cap</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                    {/* WORKERS COMPENSATION */}
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>#</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>% of Salary</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.valueCell}`}>Contribution Cap</ScrollableHeaderCell>
                                    <ScrollableHeaderCell className={`${css.dateCell}`}>Effective Date</ScrollableHeaderCell>
                                </ScrollableHeaderRow>
                            </ScrollableHead>
                            {payrollEmployeeDataRows != undefined && tableRef.current != null &&
                                <FixedSizeList
                                    itemCount={payrollEmployeeDataRows?.length ?? 0}
                                    ref={rowsRef}
                                    itemSize={65}
                                    height={500}
                                    width="100%"
                                    outerElementType={SuppressExtraDiv}
                                    innerElementType={ScrollableBody}
                                >
                                    {
                                        ({ index, style }) => {
                                            const empRow = payrollEmployeeDataRows[index];
                                            const styleUpdated = {
                                                ...style,
                                            };
                                            if (style.top != undefined) {
                                                if (typeof style.top == "string") {
                                                    styleUpdated.top = `calc(135px + ${style.top})`;
                                                }
                                                else if (typeof style.top == "number") {
                                                    styleUpdated.top = style.top + 135;
                                                }
                                            }
                                            if (!empRow) {
                                                return null;
                                            }
                                            return (
                                                <ScrollableRow key={empRow.employeeId + empRow.empRowNum.toString()} style={styleUpdated} className={css.bodyRow}>
                                                    <ScrollableCell className={css.checkboxCell}>
                                                        {empRow.employee != undefined ?
                                                        <Field>
                                                            <Checkbox
                                                                className={css.employeeTableCheckbox}
                                                                checked={selectedPayrollEmployees.has(empRow.employeeId)}
                                                                onChange={() => {
                                                                    selectedPayrollEmployees.has(empRow.employeeId) ? deselectEmployee(empRow.employeeId) : selectEmployee(empRow.employeeId);
                                                                }}
                                                            >
                                                                <Label className={css.employeeTableCheckbox} />
                                                            </Checkbox>
                                                        </Field>
                                                        :
                                                        null
                                                        }
                                                    </ScrollableCell>
                                                    <ScrollableCell className={`${css.numberCell}`}>{empRow.empNum != undefined ? empRow.empNum : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.nameCell}`}>
                                                        <div style={{display: "flex", flexDirection: "row"}}>
                                                        {empRow.employee != undefined ? empRow.employee.firstName + (empRow.employee.lastName ? " " + empRow.employee.lastName : "") : ""}
                                                        {empRow.employee != undefined ?
                                                        <Button
                                                            isBasic
                                                            size="small"
                                                            className={css.editButton}
                                                            onClick={() => {
                                                                setEditPayrollEmployeeId(empRow.employeeId);
                                                                setShowAnalystPayrollDrawer(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                        :
                                                        null
                                                        }
                                                        </div>
                                                    </ScrollableCell>
                                                    <ScrollableCell className={`${css.employeeIdCell}`}>{empRow.employee?.externalSystemEmployeeId ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.statusCell}`}>{empRow.employee?.type ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.positionTypeCell}`}>{empRow.position?.name ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.workClassificationCell}`}>{empRow.employee?.classification ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.propertyCell}`}>{empRow.property?.name ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.employee?.startDate ? DateFormatter.format(new Date(empRow.employee.startDate)) : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.employee?.terminationDate ? DateFormatter.format(new Date(empRow.employee.terminationDate)) : ""}</ScrollableCell>
                                                    {/* BASE COMPENSATION */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.baseComp?.compensationType ?? "-"}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.baseComp?.annualCompensation ? `$${empRow.baseComp?.annualCompensation}` : "-"}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.baseComp?.hourlyCompensation ? `$${empRow.baseComp?.hourlyCompensation}` : "-"}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.baseComp?.expectedHoursPerWeek}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{/* Pay Schedule (PayrollPayFrequency in schema.gql) */}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.employee?.startDate ? DateFormatter.format(new Date(empRow.employee.startDate)) : ""}</ScrollableCell>
                                                    {/* RAISES */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.raise?.raiseType ?? "-"}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.raise?.raiseType == "PERCENT_OF_SALARY" ? (empRow.raise?.amount ?? "") : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.raise?.raiseType == "FIXED_AMOUNT" ? (empRow.raise?.amount ?? "") : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>
                                                        {empRow.raise?.frequency == "ONCE" && empRow.raise?.onetimeRaiseDate ?
                                                        DateFormatter.format(new Date(empRow.raise?.onetimeRaiseDate))
                                                        :
                                                        empRow.raise?.frequency == "ANNUALLY" && empRow.raise?.raiseDay != undefined && empRow.raise?.raiseMonth != undefined ?
                                                        `0${empRow.raise?.raiseMonth}`.slice(-2) + "/" + `0${empRow.raise?.raiseDay}`.slice(-2)
                                                        :
                                                        ""
                                                        }
                                                    </ScrollableCell>
                                                    {/* BONUSES */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.bonus?.bonusType ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.bonus?.bonusType == "PERCENT_OF_SALARY" ? (empRow.bonus?.amount ?? "") : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.bonus?.bonusType == "FIXED_AMOUNT" ? (empRow.bonus?.amount ?? "") : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>
                                                        {empRow.bonus?.frequency == "ONCE" && empRow.bonus?.onetimeBonusDate ?
                                                        DateFormatter.format(new Date(empRow.bonus?.onetimeBonusDate))
                                                        :
                                                        empRow.bonus?.frequency == "ANNUALLY" && empRow.bonus?.bonusDay != undefined && empRow.bonus?.bonusMonth != undefined ?
                                                        `0${empRow.bonus?.bonusMonth}`.slice(-2) + "/" + `0${empRow.bonus?.bonusDay}`.slice(-2)
                                                        :
                                                        ""
                                                        }
                                                    </ScrollableCell>
                                                    {/* OVERTIME */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.overtime?.hourlyCompensation ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.overtime?.expectedHoursPerWeek ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.overtime?.effectiveStartDate ? DateFormatter.format(new Date(empRow.overtime?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.overtime?.effectiveEndDate ? DateFormatter.format(new Date(empRow.overtime?.effectiveEndDate)) : ""}</ScrollableCell>
                                                    {/* MEDICAL */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.medical?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.medical?.effectiveStartDate ? DateFormatter.format(new Date(empRow.medical?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* DENTAL */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.dental?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.dental?.effectiveStartDate ? DateFormatter.format(new Date(empRow.dental?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* VISION */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.vision?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.vision?.effectiveStartDate ? DateFormatter.format(new Date(empRow.vision?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* 401K */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.r401k?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.r401k?.annualCostCap ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.r401k?.effectiveStartDate ? DateFormatter.format(new Date(empRow.r401k?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* MEDICARE */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.medicare?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.medicare?.annualCostCap ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.medicare?.effectiveStartDate ? DateFormatter.format(new Date(empRow.medicare?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* SOCIAL SECURITY TAX */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.socialSecurity?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.socialSecurity?.annualCostCap ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.socialSecurity?.effectiveStartDate ? DateFormatter.format(new Date(empRow.socialSecurity?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* FEDERAL UNEMPLOYMENT TAX */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.futa?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.futa?.annualCostCap ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.futa?.effectiveStartDate ? DateFormatter.format(new Date(empRow.futa?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* STATE UNEMPLOYMENT TAX */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.suta?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.suta?.annualCostCap ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.suta?.effectiveStartDate ? DateFormatter.format(new Date(empRow.suta?.effectiveStartDate)) : ""}</ScrollableCell>
                                                    {/* WORKERS COMPENSATION */}
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.empRowNum}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.wc?.costPerPayPeriod ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.valueCell}`}>{empRow.wc?.annualCostCap ?? ""}</ScrollableCell>
                                                    <ScrollableCell className={`${css.dateCell}`}>{empRow.wc?.effectiveStartDate ? DateFormatter.format(new Date(empRow.wc?.effectiveStartDate)) : ""}</ScrollableCell>
                                                </ScrollableRow>
                                            );
                                        }
                                }
                                </FixedSizeList>
                            }

                        </ScrollableTable>
                    </div>
                </div>
                <div>
                {
                    showAnalystPayrollDrawer && editPayrollEmplloyeeId !== undefined && props.budgetYear !== undefined && <AnalystPayrollDrawer
                        employeeID={editPayrollEmplloyeeId}
                        show={showAnalystPayrollDrawer}
                        budgetYear={props.budgetYear}
                        lockedProperties={props.lockedProperties}
                        filterPropertyIds={props.filterPropertyIds}
                        handleClose={(wasAnyUpdatesPersistedOnBE) => {
                            setEditPayrollEmployeeId(undefined);
                            setShowAnalystPayrollDrawer(false);
                            if(wasAnyUpdatesPersistedOnBE) {
                                payrollEmployeeDataRefetch();
                            }
                        }}
                    />
                }
                {
                    bulkUpdateCompItemType &&
                    <BulkUpdateBenefitTaxIns
                        onClose={() => setBulkUpdateCompItemType(undefined)}
                        onConfirm={handlebulkUpdateCompItems}
                        loading={bulkUpdateCompItemsLoading}
                        updateWhat={bulkUpdateCompItemType}
                    />
                }
                {
                    showBulkCreateRaises &&
                    <BulkUpdateBonusRaise
                        isCreate
                        onClose={() => setShowBulkCreateRaises(false)}
                        onConfirmOnceFixed={(date, amount) => handleBulkCreateRaises(BulkUpdateBonusRaiseType.once_fixed, date, amount, undefined, undefined)}
                        onConfirmOncePercent={(date, percent) => handleBulkCreateRaises(BulkUpdateBonusRaiseType.once_percent, date, percent, undefined, undefined)}
                        onConfirmRecurringFixed={(month, day, amount) => handleBulkCreateRaises(BulkUpdateBonusRaiseType.recurring_fixed, undefined, amount, month, day)}
                        onConfirmRecurringPercent={(month, day, percent) => handleBulkCreateRaises(BulkUpdateBonusRaiseType.recurring_percent, undefined, percent, month, day)}
                        loading={bulkCreateRaisesLoading}
                        updateWhat="raises"
                    />
                }
                {
                    showBulkCreateBonuses &&
                    <BulkUpdateBonusRaise
                        isCreate
                        onClose={() => setShowBulkCreateBonuses(false)}
                        onConfirmOnceFixed={(date, amount) => handleBulkCreateBonuses(BulkUpdateBonusRaiseType.once_fixed, date, amount, undefined, undefined)}
                        onConfirmOncePercent={(date, percent) => handleBulkCreateBonuses(BulkUpdateBonusRaiseType.once_percent, date, percent, undefined, undefined)}
                        onConfirmRecurringFixed={(month, day, amount) => handleBulkCreateBonuses(BulkUpdateBonusRaiseType.recurring_fixed, undefined, amount, month, day)}
                        onConfirmRecurringPercent={(month, day, percent) => handleBulkCreateBonuses(BulkUpdateBonusRaiseType.recurring_percent, undefined, percent, month, day)}
                        loading={bulkCreateBonusesLoading}
                        updateWhat="bonuses"
                    />
                }
                {
                    showBulkCreateOvertimes &&
                    <BulkUpdateOvertime
                        isCreate
                        onClose={() => setShowBulkCreateOvertimes(false)}
                        onConfirm={(startDate, endDate, hourlyRate, hoursWeek) => handleBulkCreateOvertimes(startDate, endDate, hourlyRate, hoursWeek)}
                        loading={bulkCreateOvertimesLoading}
                    />
                }
                {
                    showBulkUpdateRaises &&
                    <BulkUpdateBonusRaise
                        isCreate
                        onClose={() => setShowBulkUpdateRaises(false)}
                        onConfirmOnceFixed={(date, amount) => handleBulkUpdateRaises(BulkUpdateBonusRaiseType.once_fixed, date, amount, undefined, undefined)}
                        onConfirmOncePercent={(date, percent) => handleBulkUpdateRaises(BulkUpdateBonusRaiseType.once_percent, date, percent, undefined, undefined)}
                        onConfirmRecurringFixed={(month, day, amount) => handleBulkUpdateRaises(BulkUpdateBonusRaiseType.recurring_fixed, undefined, amount, month, day)}
                        onConfirmRecurringPercent={(month, day, percent) => handleBulkUpdateRaises(BulkUpdateBonusRaiseType.recurring_percent, undefined, percent, month, day)}
                        loading={bulkUpdateRaisesLoading}
                        updateWhat="raises"
                    />
                }
                {
                    showBulkUpdateBonuses &&
                    <BulkUpdateBonusRaise
                        onClose={() => setShowBulkUpdateBonuses(false)}
                        onConfirmOnceFixed={(date, amount) => handleBulkUpdateBonuses(BulkUpdateBonusRaiseType.once_fixed, date, amount, undefined, undefined)}
                        onConfirmOncePercent={(date, percent) => handleBulkUpdateBonuses(BulkUpdateBonusRaiseType.once_percent, date, percent, undefined, undefined)}
                        onConfirmRecurringFixed={(month, day, amount) => handleBulkUpdateBonuses(BulkUpdateBonusRaiseType.recurring_fixed, undefined, amount, month, day)}
                        onConfirmRecurringPercent={(month, day, percent) => handleBulkUpdateBonuses(BulkUpdateBonusRaiseType.recurring_percent, undefined, percent, month, day)}
                        loading={bulkUpdateBonusesLoading}
                        updateWhat="bonuses"
                    />
                }
                {
                    showBulkUpdateOvertimes &&
                    <BulkUpdateOvertime
                        onClose={() => setShowBulkUpdateOvertimes(false)}
                        onConfirm={(startDate, endDate, hourlyRate, hoursWeek) => handleBulkUpdateOvertimes(startDate, endDate, hourlyRate, hoursWeek)}
                        loading={bulkUpdateOvertimesLoading}
                    />
                }
                {
                    showBulkDeleteBonuses &&
                    <BulkDeleteConfirm
                        what="bonuses"
                        onClose={() => setShowBulkDeleteBonuses(false)}
                        onConfirm={() => handleBulkDeleteBonuses()}
                        loading={bulkDeleteBonusesLoading}
                    />
                }
                {
                    showBulkDeleteRaises &&
                    <BulkDeleteConfirm
                        what="raises"
                        onClose={() => setShowBulkDeleteRaises(false)}
                        onConfirm={() => handleBulkDeleteRaises()}
                        loading={bulkDeleteRaisesLoading}
                    />
                }
                {
                    showBulkDeleteOvertimes &&
                    <BulkDeleteConfirm
                        what="overtimes"
                        onClose={() => setShowBulkDeleteOvertimes(false)}
                        onConfirm={() => handleBulkDeleteOvertimes()}
                        loading={bulkDeleteOvertimesLoading}
                    />
                }
                </div>
            </ThemeProvider>
        </div>
    );
}