import { ReactElement, useState } from "react";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Field as FormField, Label, Input } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import { PayrollCompensationItemType } from "../../../__generated__/generated_types";
import { PayrollCompensationItemTypeLabel as payrollCompensationItemTypeLabel } from "../util";
import { DateFormatter } from "../../../components/analyst-payroll-drawer/helpers/utils";
import { InputField } from "./InputField";
import { Inline } from "@zendeskgarden/react-loaders";

export type BulkUpdateBenefitTaxInsProps = {
    onClose: () => void;
    onConfirm: (
        what: PayrollCompensationItemType,
        effectiveStartDate: Date | undefined,
        costPerPayPeriod: number | undefined,
        contributionCap: number | undefined
    ) => void;
    loading: boolean;
    updateWhat: PayrollCompensationItemType;
}

export function BulkUpdateBenefitTaxIns(props: BulkUpdateBenefitTaxInsProps): ReactElement {
    const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
    const [costPerPayPeriod, setCostPerPayPeriod] = useState<number>();
    const [contributionCap, setContributionCap] = useState<number>();

    return (
    <Modal onClose={() => props.onClose()} isLarge style={{overflow: "unset"}}>
        <Header>
            Bulk Update {payrollCompensationItemTypeLabel(props.updateWhat)} Cost
        </Header>
        <Body>
            <div style={{flexDirection: "column", display: "flex", height: "500px"}}>
                <FormField>
                    <Label>Effective Start Date</Label>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Datepicker
                            value={effectiveStartDate}
                            onChange={(date) => setEffectiveStartDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                            formatDate={date => {
                                return date ? DateFormatter.format(date) : "";
                            }}
                        >

                            <Input placeholder="No change"/>
                        </Datepicker>
                        <Button onClick={() => setEffectiveStartDate(undefined)}>X</Button>
                    </div>

                </FormField>
                <InputField
                    title={[PayrollCompensationItemType.Medical, PayrollCompensationItemType.Vision, PayrollCompensationItemType.Dental].includes(props.updateWhat) ?
                            "Cost per Pay Period"
                            :
                            "% of Salary"
                        }
                    value={costPerPayPeriod}
                    setValue={setCostPerPayPeriod}
                />
                {
                ![PayrollCompensationItemType.Medical, PayrollCompensationItemType.Vision, PayrollCompensationItemType.Dental].includes(props.updateWhat) &&
                <InputField
                    title="Contribution Cap"
                    value={contributionCap}
                    setValue={setContributionCap}
                />
                }
            </div>
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={() => props.onClose()} isBasic disabled={false}>
                    Cancel
                </Button>
            </FooterItem>
            <FooterItem>
                {
                    <Button
                        isPrimary
                        onClick={() => {
                            props.onConfirm(props.updateWhat, effectiveStartDate, costPerPayPeriod, contributionCap)
                        }}
                        disabled={props.loading}
                    >
                        {
                            props.loading
                                ? <Inline size={24} aria-label="loading"/>
                                :
                                 <span>Confirm</span>
                        }
                    </Button>
                }
            </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
    </Modal>

    );
}