import { ReactElement, useState, useEffect } from "react";
import * as css from "../styles/unitTypeManagement.module.scss";
import { ListUnitTypesQuery } from "../../../__generated__/generated_types";
import { Field, MediaInput, Label, Message } from '@zendeskgarden/react-forms';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import { Inline } from "@zendeskgarden/react-loaders";

export type EditUnitTypeProps = {
    existingUnitTypes: ListUnitTypesQuery["listUnitTypes"];
    initialName?: string;
    unitTypeId?: string;
    title: string;
    onCancel: () => void;
    onSubmit: (name: string) => void;
}
export function EditUnitType(props: EditUnitTypeProps): ReactElement {
    const [name, setName] = useState<string | undefined>(props.initialName);
    const [valid, setValid] = useState({valid: true, error: ""});
    const [applyClicked, setApplyClicked] = useState(false);

    useEffect(() => {
        let valid = true;
        let error = "";

        if (name !== props.initialName) {
            const sameFound = props.existingUnitTypes.find(unitType =>
                unitType.id != props.unitTypeId
                && unitType.name == name
            );
            valid = sameFound == undefined;
            if (!valid) {
                error = "Same Unit Type Exists";
            }
        }

        setValid({valid: valid, error: error});
    }, [name]);

    return (
        <Modal onClose={props.onCancel} isLarge>
            <Header>
                {props.title}
            </Header>
            <Body>
                <form className={css.editForm}>
                    <Field>
                        <Label>Unit Type Name</Label>
                        <MediaInput value={name} onChange={e => setName(e.target.value)} />
                    </Field>
                    {!valid.valid &&
                    <Message validation="error">{valid.error}</Message>
                    }
                </form>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onCancel} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        disabled={!valid.valid || name == props.initialName || applyClicked}
                        onClick={() => { if (name) {setApplyClicked(true); props.onSubmit(name) }}}
                    >
                        {
                            applyClicked
                                ? <Inline size={24} aria-label="loading"/>
                                : <span>Apply</span>
                        }
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

