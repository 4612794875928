import {ReactElement, useState} from "react";
import {
    useCreateUserMutation,
    UserRole,
} from "../../../__generated__/generated_types";

import {Close, Modal} from "@zendeskgarden/react-modals";
import {toast} from "react-toastify";
import UserForm, {TUserRecord} from "./components/user-form/UserForm";

export interface ICreateUser {
    formVisible: boolean,
    onModalClose: () => void,
    onCreate: () => void,
}

export function UserCreateModal(props: ICreateUser): ReactElement {

    const {formVisible, onModalClose, onCreate} = props;

    const [saveUser, {loading: updateUserLoading}] = useCreateUserMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data) {
                toast.success(`${data.createUser.firstName} ${data.createUser.lastName} created`);
                onCreate();
            }
            reset();
        },
    });

    const [user, setUser] = useState<TUserRecord>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: UserRole.Manager,
        isReadOnly: false,
        authorizations: [],
        properties: []
    });


    function updateUser(update: Partial<TUserRecord>): void {
        if (user) {
            let adminOverrides = {};
            if (user.role == UserRole.Admin) {
                adminOverrides = {
                    isReadOnly: false,
                    authorizations: [],
                    properties: [],
                };
            }

            setUser({
                ...user,
                ...update,
                ...adminOverrides,
            });
        }
    }

    function doSaveUser(): void {
        if (user && user.email != undefined && user.password != undefined) {
            if (user.role == UserRole.Admin) {
                user.isReadOnly = false;
                user.authorizations = [];
                user.properties = [];
            }

            saveUser({
                variables: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    role: user.role,
                    email: user.email,
                    password: user.password,
                    isReadOnly: user.isReadOnly,
                    authorizations: user.authorizations,
                    properties: user.properties.map(p => p.id)
                }
            }).catch(() => { /* Do Nothing */ });
        }
    }

    function reset(): void {
        setUser({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: UserRole.Manager,
            isReadOnly: false,
            authorizations: [],
            properties: []
        });
        onModalClose();
    }

    if (!formVisible) {
        return <></>;
    }

    return (
        <Modal onClose={reset}>
            <UserForm
                isCreate
                user={user}
                loading={updateUserLoading}
                onChange={updateUser}
                onSubmit={doSaveUser}
                onClose={reset}
            />
            <Close aria-label="Close modal" />
        </Modal>
    );
}
