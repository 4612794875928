import { ReactElement, useRef, useState } from "react";
// Import ExcelIO module
import * as ExcelIO from "@grapecity/spread-excelio";
import GC from '@grapecity/spread-sheets';
import { SpreadSheets, Worksheet } from '@grapecity/spread-sheets-react';

const SpreadJSKey = process.env['REACT_APP_SPREADJS_LICENSE'] ?? 'no license found in ENV';
// const SpreadJSKey = 'hff.vizibly.io,399469387581693#B0LJJQw2iTMtiZvFnaBlVWzRWVv4WeklTZkdERTx4dlhUWRJ6KLhmMxgzcB5GO6Q4artiYztkWJpUaQ3UR994Rp9kath6QudkYjlTV9lFdF96TjJDdMRFTaljbrt4dpdGdC3CUo96SRdGMJF6R8hEcEpHNpZnRXRFOrF4QvQWT5xGZwF6L7dnV4VDW62mbOpWe7gDMwMzaIRlSVNkRrsGZzlXY9sGOulmbxtSURJTRs3CRs9mVQFleyEHaDVnQ6FVSRtmYxM7Nu3GbwVUbHJkWTV4aLNnMF5Ge5QnTYZmW8h5bysiVkN5T4NzbzcVNNVmI0IyUiwiI4MDMxEzN7MjI0ICSiwCNxcDO7YDN7QTM0IicfJye35XX3JSRFlkViojIDJCLiQTMuYHIgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiMDN4IDMxACMzgDMxIDMyIiOiQncDJCLi2WaukHbilmepZnLmZGaiojIz5GRiwiIuMmbJBSesJWa0lmViojIh94QiwiIzkjNxgTN7gzM9YDN9kzMiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQy3yT9I7TwI5VKR7SzxkMyQTQkNFN8ZFayUEb4VXYIp4R8ZEM8I6N6pmZzx6UvFlQ8J4TklUQQRUVFlUVn3ybKlkcvAlM5wmevREb4kEOjl4LzY4ToBlQ0tmUzpGdsJ5YtVnefdNZ';
// const SpreadJSKey = 'alexei@upstack.co,E191938467649785#B0EUvXBFbqNzRpNlWr4mY4MzQr3EWWRUekR7ZydzLnpUb8o7MHFWMHRWQGZ7MxUVWHJnTzVkRGtmVONHaGhmT9IlcUNUV9VkeF5UVChHZkBDcvdWShtkSkRXQYdkRh3GS49UR8R7V69kdv4kayskQNlmYzlTR8dWaVNVelNnS5AjW4ITSxwWV8ZFNSZFTzdzbiB5b6oUdl3mQvt4Q6tWWFVzUalkMCF5KQR5LrYjQpFEU6UUVLNmQRF4TCdnUipVe9EFdLpXaGVmS9hEW8JWWlJ5cCh6ZGd4N94kZnVHSV34UlREULNXW9oHercnSwZESzhHe9MkI0IyUiwiI6kDO9U4Q8YjI0ICSiwSO4kjM7YjMwgTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI5QDOwATMgQDM8ATMyAjMiojI4J7QiwiIzATOwEjMwIjI0ICc8VkIsIybj9yajFGdzBXdAlWZ8VGbhJiOiEmTDJCLlVnc4pjIsZXRiwiI5gzN9QjN7YDN8MTOxkTMiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQwcDMYZUN55Ue7E5UMplRHhGZyt4KRJnNZR6djVWZYBnNSJmVtJ6SjZWaOljR7FHa4p7K84EbYRXYNdlZvpWZ8IUdC9GVYNUOHFUQzpVczt6UwEDSPR4KxAXNj34LyDWzK';
GC.Spread.Sheets.LicenseKey = SpreadJSKey;

(ExcelIO as any).LicenseKey = SpreadJSKey;

const hostStyle: Partial<CSSStyleDeclaration> =
{
    width: '1100px',
    height: '300px'
};

interface TabInfo {
    name: string;
    index: number;
}

export function BudgetingModelMulti(): ReactElement {
    const inputFile = useRef<HTMLInputElement>(null);
    const [spread, setSpread] = useState<GC.Spread.Sheets.Workbook>();
    const [spread2, setSpread2] = useState<GC.Spread.Sheets.Workbook>();
    const [tabs, setTabs] = useState<TabInfo[]>();
    const [activeTabIndex, setActiveTabIndex] = useState<number>();
    // Handling workbook initialized event
    const workbookInit = (spread: GC.Spread.Sheets.Workbook) => {
        setSpread(spread);
        // Use IE to see the console log text
        spread.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, function (_sender: any, _args: any) {
            console.log("Active sheet has been switched.");
        });
        spread.bind(GC.Spread.Sheets.Events.SheetChanged, function (_sender: any, _args: any) {
            console.log("SheetChanged");
        });
    };

    const workbookInit2 = (spread: GC.Spread.Sheets.Workbook) => {
        setSpread2(spread);
    };

    const chooseFile = () => {
        if (inputFile.current != null) {
            if (inputFile.current.value != null) {
                inputFile.current.value = "";
            }
            inputFile.current.click();
        }
    };

    // Import Excel
    const importFile = () => {
        const obj = inputFile.current;
        if (obj != null && obj.files != null) {
            const excelFile = obj.files[0];
            // Get an instance of IO class
            const excelIO = new ExcelIO.IO();
            excelIO.open(excelFile as Blob, (json: any) => {
                spread?.fromJSON(json);
                spread2?.fromJSON(json);
                if (spread) {
                    spread.options.tabStripVisible = false;
                    spread.options.showVerticalScrollbar = false;
                    spread.options.showHorizontalScrollbar = false;
                    spread.setActiveSheetIndex(6);
                    spread.getActiveSheet().options.colHeaderVisible = false;
                    spread.getActiveSheet().options.rowHeaderVisible = false;
                    spread.bind(GC.Spread.Sheets.Events.RangeChanged, function (_sender: any, args: { sheetName: string; action: GC.Spread.Sheets.RangeChangedAction }) {
                        if (spread2) {
                            spread2.fromJSON(spread.toJSON());
                            spread2.setActiveSheetIndex(5);
                            spread2.getActiveSheet().options.colHeaderVisible = false;
                            spread2.getActiveSheet().options.rowHeaderVisible = false;
                        }
                        console.log(args.sheetName, args.action);
                    });
                }
                if (spread2) {
                    spread2.options.tabStripVisible = false;
                    spread2.options.showVerticalScrollbar = false;
                    spread2.options.showHorizontalScrollbar = false;
                    spread2.setActiveSheetIndex(5);
                    spread2.getActiveSheet().options.colHeaderVisible = false;
                    spread2.getActiveSheet().options.rowHeaderVisible = false;
                }
            }, (e: any) => {
                console.log(e);
            });
        }
    };

    return (
        <>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column w-25">
                    <div className="d-flex w-100 p-2">
                        <input type='file' id='file' ref={inputFile} onChange={importFile} style={{ display: 'none' }} />
                        <input type="button" id="loadExcel" value="Import" onClick={chooseFile} />
                    </div>
                </div>
                <div className="flex-column">
                    <h2 className="p2">Itemized</h2>
                    <SpreadSheets tabNavigationVisible={false} newTabVisible={false} tabStripVisible={false} backColor={"#FFFFFF"} hostStyle={hostStyle as unknown as ElementCSSInlineStyle} workbookInitialized={workbookInit}>
                        <Worksheet>
                        </Worksheet>
                    </SpreadSheets>
                    <h2 className="p2">R&M</h2>
                    <SpreadSheets tabNavigationVisible={false} newTabVisible={false} tabStripVisible={false} backColor={"#FFFFFF"} hostStyle={hostStyle as unknown as ElementCSSInlineStyle} workbookInitialized={workbookInit2}>
                        <Worksheet>
                        </Worksheet>
                    </SpreadSheets>
                </div>
            </div>
        </>
    );
}