import { ReactElement, useRef, useState } from "react";
// Import ExcelIO module
import * as ExcelIO from "@grapecity/spread-excelio";
import GC from '@grapecity/spread-sheets';
import { SpreadSheets, Worksheet } from '@grapecity/spread-sheets-react';

// const SpreadJSKey = process.env['REACT_APP_SPREADJS_LICENSE'] ?? 'no license found in ENV';
// const SpreadJSKey = 'hff.vizibly.io,399469387581693#B0LJJQw2iTMtiZvFnaBlVWzRWVv4WeklTZkdERTx4dlhUWRJ6KLhmMxgzcB5GO6Q4artiYztkWJpUaQ3UR994Rp9kath6QudkYjlTV9lFdF96TjJDdMRFTaljbrt4dpdGdC3CUo96SRdGMJF6R8hEcEpHNpZnRXRFOrF4QvQWT5xGZwF6L7dnV4VDW62mbOpWe7gDMwMzaIRlSVNkRrsGZzlXY9sGOulmbxtSURJTRs3CRs9mVQFleyEHaDVnQ6FVSRtmYxM7Nu3GbwVUbHJkWTV4aLNnMF5Ge5QnTYZmW8h5bysiVkN5T4NzbzcVNNVmI0IyUiwiI4MDMxEzN7MjI0ICSiwCNxcDO7YDN7QTM0IicfJye35XX3JSRFlkViojIDJCLiQTMuYHIgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiMDN4IDMxACMzgDMxIDMyIiOiQncDJCLi2WaukHbilmepZnLmZGaiojIz5GRiwiIuMmbJBSesJWa0lmViojIh94QiwiIzkjNxgTN7gzM9YDN9kzMiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQy3yT9I7TwI5VKR7SzxkMyQTQkNFN8ZFayUEb4VXYIp4R8ZEM8I6N6pmZzx6UvFlQ8J4TklUQQRUVFlUVn3ybKlkcvAlM5wmevREb4kEOjl4LzY4ToBlQ0tmUzpGdsJ5YtVnefdNZ';
// const SpreadJSKey = 'alexei@upstack.co,E191938467649785#B0EUvXBFbqNzRpNlWr4mY4MzQr3EWWRUekR7ZydzLnpUb8o7MHFWMHRWQGZ7MxUVWHJnTzVkRGtmVONHaGhmT9IlcUNUV9VkeF5UVChHZkBDcvdWShtkSkRXQYdkRh3GS49UR8R7V69kdv4kayskQNlmYzlTR8dWaVNVelNnS5AjW4ITSxwWV8ZFNSZFTzdzbiB5b6oUdl3mQvt4Q6tWWFVzUalkMCF5KQR5LrYjQpFEU6UUVLNmQRF4TCdnUipVe9EFdLpXaGVmS9hEW8JWWlJ5cCh6ZGd4N94kZnVHSV34UlREULNXW9oHercnSwZESzhHe9MkI0IyUiwiI6kDO9U4Q8YjI0ICSiwSO4kjM7YjMwgTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI5QDOwATMgQDM8ATMyAjMiojI4J7QiwiIzATOwEjMwIjI0ICc8VkIsIybj9yajFGdzBXdAlWZ8VGbhJiOiEmTDJCLlVnc4pjIsZXRiwiI5gzN9QjN7YDN8MTOxkTMiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQwcDMYZUN55Ue7E5UMplRHhGZyt4KRJnNZR6djVWZYBnNSJmVtJ6SjZWaOljR7FHa4p7K84EbYRXYNdlZvpWZ8IUdC9GVYNUOHFUQzpVczt6UwEDSPR4KxAXNj34LyDWzK';
// GC.Spread.Sheets.LicenseKey = SpreadJSKey;

// (ExcelIO as any).LicenseKey = SpreadJSKey;

const hostStyle: Partial<CSSStyleDeclaration> =
{
    width: '1100px',
    height: '800px'
};

interface TabInfo {
    name: string;
    index: number;
}

export function BudgetingModel(): ReactElement {
    const inputFile = useRef<HTMLInputElement>(null);
    const [spread, setSpread] = useState<GC.Spread.Sheets.Workbook>();
    const [tabs, setTabs] = useState<TabInfo[]>();
    const [activeTabIndex, setActiveTabIndex] = useState<number>();
    // Handling workbook initialized event
    const workbookInit = (spread: GC.Spread.Sheets.Workbook) => {
        setSpread(spread);
    };

    const chooseFile = () => {
        if (inputFile.current != null) {
            if (inputFile.current.value != null) {
                inputFile.current.value = "";
            }
            inputFile.current.click();
        }
    };

    const selectTab = (index: number) => {
        spread?.setActiveSheetIndex(index);
        setActiveTabIndex(index);
    };

    const selectRent = () => {

        const sheet = spread?.getActiveSheet();
        if (!sheet) {
            return;
        }

        for (let row = 0; row <= 15; row++) {
            sheet.setRowVisible(row, false, GC.Spread.Sheets.SheetArea.viewport);
        }
        // const range = new GC.Spread.Sheets.CellRange(sheet, 1,1,15,13);
        // range.visible(false);
        for (let row = 2; row <= 6; row++) {
            sheet.setRowVisible(row, true, GC.Spread.Sheets.SheetArea.viewport);
        }
        sheet.showRow(2, GC.Spread.Sheets.VerticalPosition.top);
    };

    const selectVacancy = () => {

        const sheet = spread?.getActiveSheet();
        if (!sheet) {
            return;
        }

        for (let row = 0; row <= 15; row++) {
            sheet.setRowVisible(row, false, GC.Spread.Sheets.SheetArea.viewport);
        }

        for (let row = 10; row <= 15; row++) {
            sheet.setRowVisible(row, true, GC.Spread.Sheets.SheetArea.viewport);
        }

        sheet.showRow(10, GC.Spread.Sheets.VerticalPosition.top);
        // spread?.getActiveSheet().getRange(1, 1, 15, 13).visible(false);
        // spread?.getActiveSheet().getRange(11, 1, 5, 13).visible(true);
    };

    const showAll = () => {

        const sheet = spread?.getActiveSheet();
        if (!sheet) {
            return;
        }

        for (let row = 0; row <= 15; row++) {
            sheet.setRowVisible(row, true, GC.Spread.Sheets.SheetArea.viewport);
        }

        sheet.showRow(0, GC.Spread.Sheets.VerticalPosition.top);

    };

    // Import Excel
    const importFile = () => {
        const obj = inputFile.current;
        if (obj != null && obj.files != null) {
            const excelFile = obj.files[0];
            // Get an instance of IO class
            const excelIO = new ExcelIO.IO();
            excelIO.open(excelFile as Blob, (json: any) => {
                spread?.fromJSON(json);
                if (spread) {
                    spread.options.tabStripVisible = false;
                    spread.options.showVerticalScrollbar = false;
                    spread.options.showHorizontalScrollbar = false;
                    spread.getActiveSheet().options.colHeaderVisible = false;
                    spread.getActiveSheet().options.rowHeaderVisible = false;
                    const tabs = spread.sheets.map((sheet, index) => ({ name: sheet.name(), index: index }));
                    setTabs(tabs);
                    setActiveTabIndex(spread.getActiveSheetIndex());
                }
            }, (e: any) => {
                console.log(e);
            });
        }
    };

    return (
        <>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column w-25">
                    <div className="d-flex w-100 p-2">
                        <input type='file' id='file' ref={inputFile} onChange={importFile} style={{ display: 'none' }} />
                        <input type="button" id="loadExcel" value="Import" onClick={chooseFile} />
                    </div>
                    <h2 className="p-2">Choose tab</h2>
                    <div className="d-flex flex-column">
                        {tabs &&
                            tabs.map(tab => <button className="p-2 m-1" key={tab.index} onClick={() => selectTab(tab.index)}>{tab.name}</button>)
                        }
                    </div>
                </div>
                <div className="flex-column">
                    <SpreadSheets tabNavigationVisible={false} newTabVisible={false} tabStripVisible={false} backColor={"#FFFFFF"} hostStyle={hostStyle as unknown as ElementCSSInlineStyle} workbookInitialized={workbookInit}>
                        <Worksheet>
                        </Worksheet>
                    </SpreadSheets>
                </div>
                <div className="d-flex flex-column">
                    {activeTabIndex != undefined && activeTabIndex == 4 &&
                        <>
                            <button onClick={selectRent}>Rent</button>
                            <button onClick={selectVacancy}>Vacancy</button>
                            <button onClick={showAll}>Show all</button>
                        </>
                    }
                </div>
            </div>
        </>
    );
}