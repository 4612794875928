import FileSaver from "file-saver";
import { BudgetingType } from "../../../../../BudgetingType";
import { AppConfig } from "../../../../../hooks/useConfig";
import { DataRow, formatForExcel } from "./formatters";

type ExportExcel = (
    tab: BudgetingType,
    data: DataRow[],
    config: AppConfig
) => void;

export function useExportExcelHoook(): ExportExcel {
    const exportExcel = (
        tab: BudgetingType,
        data: DataRow[],
        config: AppConfig
    ): void => {
        const formattedDataPromise = formatForExcel({
            tab: tab,
            data: data,
            config: config
        });
        if (formattedDataPromise) {
            formattedDataPromise.then(saveData => {
                let year = config.year;
                if (tab == BudgetingType.BUDGET) {
                    year = config.year + 1;
                }

                const fileName = `${year} Revenue Assumptions - ${config.properties.currentProperty?.name ?? ""}`;
                FileSaver.saveAs(saveData, `${fileName}.xlsx`);
            });
        }
    };

    return exportExcel;
}