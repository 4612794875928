import { UserRole } from "../../../../__generated__/generated_types";
import { TUserRecord } from "../components/user-form/UserForm";

export type TFormError = {
    field: string,
    message: string,
}

export default class UserValidator {

    // eslint-disable-next-line
    emailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")

    // 8 char minimum, at least 1 uppercase char, at least 1 lowercase char, at least one number, at least one special char
    passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")

    validate(user: TUserRecord, isCreate: boolean): TFormError[] {
        if (isCreate) {
            return this.validateCreatableUser(user);
        }

        return this.validateEditableUser(user);
    }

    private validateCreatableUser(user: Partial<TUserRecord>): TFormError[] {
        const errors = this.getSharedValidationErrors(user);

        if (!this.assertStringPresence(user.email)) {
            errors.push({
                field: "email",
                message: "Email cannot be blank",
            });
        } else if (user.email == undefined || !this.emailRegex.test(user.email)) {
            errors.push({
                field: "email",
                message: "Please enter a valid email address",
            });
        }

        if (!this.assertStringPresence(user.password)) {
            errors.push({
                field: "password",
                message: "Password cannot be blank",
            });
        } else if (user.password == undefined || !this.passwordRegex.test(user.password)) {
            errors.push({
                field: "password",
                message: "Password must be at least 8 characters, have both uppercase and lowercase letters, at least one number, and at least one special character",
            });
        }

        return errors;
    }

    private validateEditableUser(user: Partial<TUserRecord>): TFormError[] {
        return this.getSharedValidationErrors(user);
    }

    private getSharedValidationErrors(user: Partial<TUserRecord>): TFormError[] {
        const errors = [];
        const isAdmin = user.role?.includes(UserRole.Admin);

        if (!this.assertStringPresence(user?.firstName)) {
            errors.push({
                field: "firstName",
                message: "First name cannot be blank",
            });
        }

        if (!this.assertStringPresence(user.lastName)) {
            errors.push({
                field: "lastName",
                message: "Last name cannot be blank",
            });
        }

        if (!this.assertStringPresence(user.role)) {
            errors.push({
                field: "role",
                message: "Role cannot be blank",
            });
        }

        if (!isAdmin && !this.assertArrayPresence(user.properties)) {
            errors.push({
                field: "properties",
                message: "Must select at least one property",
            });
        }

        return errors;
    }

    private assertStringPresence(value: string | undefined) {
        return value != undefined && value.trim() && value.trim() != "" && typeof value == 'string';
    }

    private assertArrayPresence(value: any[] | undefined) {
        return value != undefined && value != undefined && Array.isArray(value) && value.length > 0;
    }
}
