import React, {ReactElement} from 'react';
import ProgressList from '../../../components/progress-list/ProgressList';
import Footer from '../../../components/footer/Footer';
import { useRoutingUtils } from '../../../routes/RoutingUtils';

/**
 * Basic setup component
 * @return {ReactElement}
 */
export default function BasicSetUp(): ReactElement {

  const {goto} = useRoutingUtils();

  return (
    <div>
      <ProgressList selected={0}/>
      <div className="container">
          <div className="basic-details bg-white py-4 ps-5 mb-4">
              <div className="text-start pt-2 pb-4 fs-4"> Basic Details </div>
              <div className="mb-3 w-25 small">
                  <label className="form-label text-darkliver fs-5"> Chart of Accounts Name (required) </label>
                  <input type="text" className="form-control fs-5" placeholder="Enter Chart of Accounts Name" required/>
              </div>
              <div className="mb-3 w-25 small bg-white">
                  <label className="form-label text-darkliver"> Properties applied to (required) </label>
                  <select className="form-select text-medium-grey fs-5" required>
                      <option selected>Select Properties</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                  </select>
              </div>
          </div>
      </div>
      <Footer
        backText="Cancel"
        nextText="Next step"
        needGoBack={goto("/admin/start")}
        needGoNext={goto("/admin/setup/budget")}
      />
    </div>
  );
}
