import GC from '@grapecity/spread-sheets';
import { AnalystTimeBasedLayout, AnalystTimeBasedLayoutConfig } from "../helpers/AnalystTimeBasedLayout";
import { AnalystCfg } from "../AnalystCfg";
import { colCode } from "../../../utils/excel";
import { RenovationData } from "./AnalystRenovations";
import { AnalystStyles } from "../AnalystStyles";

import {
    AddRenovationRowMapEntryAction,
    RenovationRowMapAction,
    RenovationRowMapActionTypes,
    RenovationRowMapEntry
} from "./AnalystRenovationState";
import { AnalystRenovationEntityType } from "./types";
import { FinancialEntityCell } from "../../../components/spreadsheet/spreadjs-custom-cell-types/FinancialEntityCell";
import { RENO_PACKAGE_INFO, RENO_PROPERTY_CELL_INFO, UNIT_CELL_INFO, UNIT_CELL_INFO_W_PACKAGES } from "./constants";
import {
    RenovationNumbersModel,
    RenovationPackageModel,
    UnitTypeScheduleValue
} from "../../../__generated__/generated_types";
import {
    AnalystRenovationsSheetStyles as renoStyles
} from "./AnalystRenovationsSheetStyles";
import { RowEntityType } from "../enums";
import { Dispatch } from "react";
import { isFinancialEntityCell } from "../helpers/CustomCellHelpers";

export class AnalystRenovationsLayout extends AnalystTimeBasedLayout{
    public constructor(config: AnalystTimeBasedLayoutConfig) {
        super(config);
    }

    private initializeRenoDataRow(row:number, entityType:RowEntityType, sheet:GC.Spread.Sheets.Worksheet, propertyId: string):void{
        // Confirm borders for Financial Entity and Modeling Method columns are in place
        sheet.getRange(
            row,
            AnalystCfg.FINANCIAL_ENTITY_COL,
            1, 2
        ).setBorder(AnalystStyles.CELL_BORDER, { all: true });

        // Lock every cell if this row isn't for a renovation package
        if(entityType != RowEntityType.RENOVATION_PACKAGE){
            sheet.getRange(row, AnalystCfg.FIRST_DATA_COL, AnalystCfg.BUDGET_TOTALS_COL - AnalystCfg.FIRST_DATA_COL).locked(true);
        }

        // Note: All Unit Type cells are editable in the Renovations Manager
        sheet.getRange(row, AnalystCfg.FIRST_DATA_COL, 1, this._firstReforecastMonth - 1).setStyle(renoStyles.MANUAL_ENTRY);
        sheet.setStyle(row, AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1, renoStyles.LAST_MANUAL_ENTRY);

        // Reforecast cells for this year
        this.getRefoRange(sheet, row).setStyle(renoStyles.MANUAL_ENTRY);

        sheet.setStyle(row, AnalystCfg.THIS_YEAR_TOTALS_COL, renoStyles.TOTAL);

        sheet.getRange(row, AnalystCfg.FIRST_BUDGET_DATA_COL, 1, 12).setStyle(renoStyles.MANUAL_ENTRY);

        sheet.setStyle(row, AnalystCfg.BUDGET_TOTALS_COL, renoStyles.TOTAL);

        // actuals should not be editable, so lock them
        sheet.getRange(
            row, AnalystCfg.FIRST_DATA_COL,
            1, this._firstReforecastMonth,
        ).locked(true).setStyle(renoStyles.VALUE_BASE);

        if (this._lockedProperties.budgetLocked.includes(propertyId)) {
            this.getBudgetRange(sheet, row).locked(true).setStyle(renoStyles.VALUE_BASE);
        }

        if (this._lockedProperties.reforecastLocked.includes(propertyId)) {
            this.getRefoRange(sheet, row).locked(true).setStyle(renoStyles.VALUE_BASE);
        }
    }

    private initializeSummaryRow(row:number, entityType:RowEntityType, sheet:GC.Spread.Sheets.Worksheet):void{

        // Default totals style to level 0
        let dataStyle = renoStyles.PROPERTY_ROLLUP;
        let lastDataStyle = renoStyles.LAST_PROPERTY_ROLLUP;
        let totalStyle =  renoStyles.TOTAL;

        if(entityType == RowEntityType.UNIT_TYPE){
            dataStyle = renoStyles.UNIT_ROLLUP;
            lastDataStyle = renoStyles.LAST_UNIT_ROLLUP;
            totalStyle =  renoStyles.UNIT_TOTAL;
        }

        if(entityType == RowEntityType.TOTAL_EXPIRED_MOVE_OUTS){
            dataStyle = renoStyles.EXPIRED_MOVEOUTS_ROLLUP;
            lastDataStyle = renoStyles.LAST_EXPIRED_MOVEOUTS_ROLLUP;
            totalStyle =  renoStyles.EXPIRED_MOVEOUTS_TOTAL;
        }

        // Confirm borders for Financial Entity and Modeling Method columns are in place
        sheet.getRange(
            row,
            AnalystCfg.FINANCIAL_ENTITY_COL,
            1, 2
        ).setBorder(AnalystStyles.CELL_BORDER, { all: true });

        // Note: All cells are editable in the Renovations Manager
        sheet.getRange(row, AnalystCfg.FIRST_DATA_COL, 1, 9).setStyle(dataStyle);
        sheet.setStyle(row,AnalystCfg.FIRST_DATA_COL + 9, lastDataStyle);

        // Reforecast cells for this year
        this.getRefoRange(sheet, row).setStyle(dataStyle);

        sheet.setStyle(row, AnalystCfg.THIS_YEAR_TOTALS_COL, totalStyle);

        sheet.getRange(row, AnalystCfg.FIRST_BUDGET_DATA_COL, 1, 12).setStyle(dataStyle);

        sheet.setStyle(row, AnalystCfg.BUDGET_TOTALS_COL, totalStyle);
    }

    private renderRenoPackageInfo(
        _unitRow:number,
        row:number,
        renoPackage:RenovationPackageModel,
        propIdx:number,
        pkgIdx:number,
    ):void{

        const parseYear = (yearData:RenovationNumbersModel[], findYear:number):(number|undefined)[] =>{
            const thisYear = yearData.find(
                entry => entry.year == findYear
            );
            let parsed:(number|undefined)[] = [];
            if(thisYear && thisYear.expected){
                parsed = thisYear.expected.map(month => {
                    if(month.value){
                        return month.value;
                    } else {
                        return undefined;
                    }
                });
            }
            return parsed;
        };

        const year:(number|undefined)[] = parseYear(renoPackage.renovationNumbers, this._year);
        const nextYear:(number|undefined)[] = parseYear(renoPackage.renovationNumbers, this._year + 1);

        this._ssapi.directAccess( spread => {
            const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);

            sheet.setArray(row, AnalystCfg.FIRST_DATA_COL, [year]);
            sheet.setArray(row, AnalystCfg.FIRST_BUDGET_DATA_COL, [nextYear]);

            // Apply reno data to the PropertyUtil sheet
            const propUtilSheet = spread.getSheetFromName(AnalystCfg.PROPERTY_UTIL_TAB_NAME);
            const yearFormulas = this.referenceYearSpan(AnalystCfg.MAIN_TAB_NAME, row, AnalystCfg.FIRST_DATA_COL);
            const budgetFormulas = this.referenceYearSpan(AnalystCfg.MAIN_TAB_NAME, row, AnalystCfg.FIRST_BUDGET_DATA_COL);
            propUtilSheet.setArray(pkgIdx, propIdx * 24, [yearFormulas.concat(budgetFormulas)], true);
        });
    }

    public applyRowHoverTreatment(row:number, sheet:GC.Spread.Sheets.Worksheet):void{
        // Note: All Unit Type cells are editable in the Renovations Manager
        sheet.getRange(row, AnalystCfg.FIRST_DATA_COL, 1, 12).setStyle(renoStyles.MANUAL_ENTRY);
        sheet.setStyle(row, AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1, renoStyles.LAST_MANUAL_ENTRY);
        sheet.getRange(row, AnalystCfg.FIRST_BUDGET_DATA_COL, 1, 12).setStyle(renoStyles.MANUAL_ENTRY);
    }

    public removeRowHoverTreatment(row:number, sheet:GC.Spread.Sheets.Worksheet, propertyId: string):void{
        this.initializeRenoDataRow(row, RowEntityType.RENOVATION_PACKAGE, sheet, propertyId);
    }

    public renderRenovationMetrics(renovationPackageData: RenovationData, year:number, dispatch: Dispatch<RenovationRowMapAction>): void {

        this._initialized = true;

        const properties = renovationPackageData.propertyData?.sortBy("name");

        const renovationPackageByProperyAndUnitId = renovationPackageData.renovationPackageData?.
            groupBy((row)=>{
            return `${row.propertyId}#${row.unitTypeId}`;
        })??{};
        const propertiesCount = properties?.length || 0;
        // const unitTypeCount = unitTypes.length;
        const packagesCount = renovationPackageData.renovationPackageData?.length || 0;

        let currentRowNum = AnalystCfg.FIRST_DATA_ROW;
        // TODO - This needs to go to another private function
        this._ssapi.directAccess(spread => {
            // spread.options.tabStripVisible = true; // used for debugging
            const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);
            const propUtilSheet = spread.getSheetFromName(AnalystCfg.PROPERTY_UTIL_TAB_NAME);

            // Confirm we have enough columns in the PropertyUtil sheet (24 = 24 months, 2 years worth of values for each property)
            // Note: There need to be enough rows per property to accommodate its unit types
            propUtilSheet.setColumnCount(propertiesCount * 24);

            properties?.forEach((property, propIdx) => {
                sheet.setRowHeight(currentRowNum, AnalystCfg.DATA_CELL_HEIGHT);

                sheet.getCell(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL).cellType(
                    new FinancialEntityCell(RENO_PROPERTY_CELL_INFO, currentRowNum, true, RowEntityType.PROPERTY)
                );

                sheet.setValue(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, property.name);
                this.initializeRenoDataRow(currentRowNum, RowEntityType.PROPERTY, sheet, property.id);

                const propRow = currentRowNum;

                const propEntryInRenovationState: RenovationRowMapEntry = {
                    idx: currentRowNum,
                    entityID: property.id,
                    entityName: property.name,
                    entityType: AnalystRenovationEntityType.PROPERTY,
                    level: 0,
                    parent: {
                       id: null,
                       name: null
                    }
                };
                dispatch({
                    kind: RenovationRowMapActionTypes.AddRenovationRowMapEntryAction,
                    entries: [propEntryInRenovationState]
                } as AddRenovationRowMapEntryAction);

                currentRowNum++;

                // pkgIdxOffset used for offsetting row in the propertyUtil tab
                // propertyUtil tab gets populated with references to main tab such
                // that for all unit types and all packages under each of them
                // we reference the value
                //
                // why we need this? - sum up all renovation counts for the property
                // why not do it in the same worksheet where we have values?
                //    - there is a row with lease move outs which needs to be excluded from the sum
                //      so having separate tab is the way to acheive it DUH
                let pkgIdxOffset = 0;

                property.unitTypesCustom.sortBy("name").forEach(unitType => {
                    // TODO: Replace this with improved approach in PHub > Financials. This breaks wordWrap
                    sheet.setRowHeight(currentRowNum, AnalystCfg.DATA_CELL_HEIGHT);

                    const currPackages = renovationPackageByProperyAndUnitId[`${property.id}#${unitType.id}`];
                    const hasChildren = currPackages !== undefined && currPackages.length > 0;

                    if(hasChildren){
                        sheet.getCell(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL).cellType(
                            new FinancialEntityCell(UNIT_CELL_INFO_W_PACKAGES, currentRowNum, true, RowEntityType.UNIT_TYPE)
                        );
                    } else {
                        sheet.getCell(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL).cellType(
                            new FinancialEntityCell(UNIT_CELL_INFO, currentRowNum, true, RowEntityType.UNIT_TYPE)
                        );
                    }

                    sheet.setValue(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, unitType.name);
                    this.initializeRenoDataRow(currentRowNum, RowEntityType.UNIT_TYPE, sheet, property.id);

                    const unitRow = currentRowNum;

                    const unitTypeEntryInRenovationState: RenovationRowMapEntry = {
                        idx: currentRowNum,
                        entityID: unitType.id,
                        entityName: unitType.name,
                        entityType: AnalystRenovationEntityType.UNIT_TYPE,
                        level: 1,
                        parent: {
                            id: property.id,
                            name: property.name
                        }
                    };
                    dispatch({ kind: RenovationRowMapActionTypes.AddRenovationRowMapEntryAction, entries: [unitTypeEntryInRenovationState] } as AddRenovationRowMapEntryAction);

                    currentRowNum++;

                    // TODO: Create ticket to have UTSV data at the unit level instead of package leve
                    let foundUTSVData = false;
                    let totalExpiringLeaseMoveouts:{
                        thisYearUTSV: number[],
                        nextYearUTSV: number[],
                    } = {thisYearUTSV:[], nextYearUTSV:[]};

                    currPackages?.forEach(currPackage => {

                        if(!foundUTSVData){
                            totalExpiringLeaseMoveouts = this.parseUnitTypeScheduleValue(currPackage, year);
                            foundUTSVData = true;
                        }

                        // TODO: Replace setRowHeight with upgraded approach in PHub > Financials. This breaks wordWrap.
                        sheet.setRowHeight(currentRowNum, AnalystCfg.DATA_CELL_HEIGHT);

                        const renoPackageCellInfo = {
                            ...RENO_PACKAGE_INFO,
                            ...{ renovationPackageId: currPackage.id }
                        };
                        sheet.getCell(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL).cellType(
                            new FinancialEntityCell(renoPackageCellInfo, currentRowNum, false, RowEntityType.RENOVATION_PACKAGE)
                        );

                        sheet.setValue(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, currPackage.name);
                        this.initializeRenoDataRow(currentRowNum, RowEntityType.RENOVATION_PACKAGE, sheet, property.id);

                        const packageEntryInRenovationState: RenovationRowMapEntry = {
                            idx: currentRowNum,
                            entityID: currPackage.id,
                            entityName: currPackage.name,
                            entityType: AnalystRenovationEntityType.PACKAGE,
                            level: 2,
                            parent: {
                                id: unitType.id,
                                name: unitType.name
                            }
                        };

                        if(currPackage){
                            this.renderRenoPackageInfo(unitRow, currentRowNum, currPackage as RenovationPackageModel, propIdx, pkgIdxOffset);
                            pkgIdxOffset++;
                        }

                        // Add row total formula
                        this.addRowTotalsFormulas(currentRowNum, sheet);

                        dispatch({ kind: RenovationRowMapActionTypes.AddRenovationRowMapEntryAction, entries: [packageEntryInRenovationState] } as AddRenovationRowMapEntryAction);
                        currentRowNum++;
                    });

                    if(hasChildren){
                        // Add Unit Summary Row ___________________________________________________
                        // TODO: Replace setRowHeight with upgraded approach in Planning Hub > Financials. This breaks wordWrap.
                        sheet.setRowHeight(currentRowNum, AnalystCfg.DATA_CELL_HEIGHT);
                        sheet.setStyle(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, AnalystStyles.LEVEL_1_ROLLUP_ROW_LABEL);
                        sheet.setValue(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, `Total Renovations - ${unitType.name}`);

                        this.initializeSummaryRow(currentRowNum, RowEntityType.UNIT_TYPE, sheet);

                        // Populate the unit summary row with formulas
                        // reforecast year
                        const unitTotalsReforecast = new Array(12).fill(null).map(
                            (_, monthIdx):(string|null) => {
                                return this.totalFormulaToUtilSheet(
                                    AnalystCfg.FIRST_DATA_COL + monthIdx,
                                    unitRow + 2,
                                    currentRowNum,
                                    AnalystCfg.MAIN_TAB_NAME,
                                );
                            });

                        sheet.setArray(unitRow, AnalystCfg.FIRST_DATA_COL, [unitTotalsReforecast], true);
                        sheet.setArray(currentRowNum, AnalystCfg.FIRST_DATA_COL, [unitTotalsReforecast], true);

                        // budget year
                        const unitTotalsBudget = new Array(12).fill(null).map(
                            (_, monthIdx):(string|null) => {
                                return this.totalFormulaToUtilSheet(
                                    AnalystCfg.FIRST_BUDGET_DATA_COL + monthIdx,
                                    unitRow + 2,
                                    currentRowNum,
                                    AnalystCfg.MAIN_TAB_NAME,
                                );
                            });

                        sheet.setArray(unitRow, AnalystCfg.FIRST_BUDGET_DATA_COL, [unitTotalsBudget], true);
                        sheet.setArray(currentRowNum, AnalystCfg.FIRST_BUDGET_DATA_COL, [unitTotalsBudget], true);

                        this.addRowTotalsFormulas(unitRow, sheet);
                        this.addRowTotalsFormulas(currentRowNum, sheet);

                        currentRowNum++;

                        // Add Total Lease Expiring Moveouts Summary Row __________________________
                        sheet.setRowHeight(currentRowNum, AnalystCfg.DATA_CELL_HEIGHT);
                        sheet.setStyle(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, AnalystStyles.LEVEL_1_ROLLUP_ROW_LABEL);
                        sheet.setValue(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, `Total Expiring Lease Move Outs - ${unitType.name}`);

                        this.initializeSummaryRow(currentRowNum, RowEntityType.TOTAL_EXPIRED_MOVE_OUTS, sheet);

                        if(totalExpiringLeaseMoveouts){
                            sheet.setArray(currentRowNum, AnalystCfg.FIRST_DATA_COL, [totalExpiringLeaseMoveouts.thisYearUTSV]);
                            sheet.setArray(currentRowNum, AnalystCfg.FIRST_BUDGET_DATA_COL, [totalExpiringLeaseMoveouts.nextYearUTSV]);
                        }

                        // Group this unit type's packages
                        sheet.rowOutlines.group(unitRow + 1, currentRowNum - unitRow);

                        // TODO: Need to populate Total Lease Expiring Moveouts Summary Row
                        this.addRowTotalsFormulas(currentRowNum, sheet);

                        currentRowNum++;
                    }
                });

                // Add Property Summary Row _______________________________________________________
                sheet.setRowHeight(currentRowNum, AnalystCfg.DATA_CELL_HEIGHT);
                sheet.setStyle(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, AnalystStyles.LEVEL_0_ROLLUP_ROW_LABEL);
                sheet.setValue(currentRowNum, AnalystCfg.FINANCIAL_ENTITY_COL, `Total Renovations - ${property.name}`);

                this.initializeSummaryRow(currentRowNum, RowEntityType.PROPERTY, sheet);

                // Render the property renovation package totals formulas to the Property Util sheet
                const totalsFormulaArray = new Array(24).fill(null).map(
                    (_, monthIdx):(string|null) => {
                        return this.totalFormulaToUtilSheet(
                            (propIdx * 24) + monthIdx,
                            1,
                            packagesCount,
                            AnalystCfg.PROPERTY_UTIL_TAB_NAME
                        );
                    });

                if(packagesCount > 0){
                    // Set rollup formulas to the property row and the summary row
                    propUtilSheet.setArray(
                        packagesCount,
                        propIdx * 24,
                        [
                            totalsFormulaArray
                        ],
                        true
                    );
                }

                // Add row total formula
                this.addRowTotalsFormulas(currentRowNum, sheet);

                // Get references to the totals formulas we just rendered in the rollup and summary rows for this property
                const yearTotals: string[] = [];
                const budgetTotals: string[] = [];
                for(let i = 0; i < 12; i++) {
                    const colStart = (propIdx * 24) + i;
                    const yearColCode = colCode(colStart);
                    const budgetColCode = colCode(colStart + 12);
                    yearTotals.push(`=SUM(${AnalystCfg.PROPERTY_UTIL_TAB_NAME}!${yearColCode}1:${AnalystCfg.PROPERTY_UTIL_TAB_NAME}!${yearColCode}${packagesCount})`);
                    budgetTotals.push(`=SUM(${AnalystCfg.PROPERTY_UTIL_TAB_NAME}!${budgetColCode}1:${AnalystCfg.PROPERTY_UTIL_TAB_NAME}!${budgetColCode}${packagesCount})`);
                }

                sheet.setArray(propRow, AnalystCfg.FIRST_DATA_COL, [yearTotals], true);
                sheet.setArray(currentRowNum, AnalystCfg.FIRST_DATA_COL, [yearTotals], true);

                sheet.setArray(propRow, AnalystCfg.FIRST_BUDGET_DATA_COL, [budgetTotals], true);
                sheet.setArray(currentRowNum, AnalystCfg.FIRST_BUDGET_DATA_COL, [budgetTotals], true);

                // Add row total formula
                this.addRowTotalsFormulas(propRow, sheet);
                this.addRowTotalsFormulas(currentRowNum, sheet);

                // Group this property's unit types
                // -2 magic number: -1 for the summary row, -1 since `row` has advanced beyond the number of units
                sheet.rowOutlines.group(propRow + 1, currentRowNum - propRow);

                currentRowNum++;
            });
            // Eliminate extra rows
            sheet.setRowCount(currentRowNum);

            // Close all sections that can close
            let row = -1;
            while (row++ < currentRowNum) {

                if(isFinancialEntityCell(row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)){
                    const thisCell = sheet.getCell(
                        row,
                        AnalystCfg.FINANCIAL_ENTITY_COL,
                    );
                    const cellRef: FinancialEntityCell = (thisCell.cellType() as FinancialEntityCell);

                    if(cellRef.canOpen){
                        this.toggleSectionOpenState(row);
                    }
                }
            }

            // sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount()).locked(false); // used for debugging
            // Apply data validation
            const nv = GC.Spread.Sheets.DataValidation.createNumberValidator(
                GC.Spread.Sheets.ConditionalFormatting.ComparisonOperators.greaterThan,
                0,
                0,
                true);
            nv.errorMessage("This value must be a number.");
            nv.showErrorMessage(true);
            nv.ignoreBlank(true);
            nv.errorStyle(GC.Spread.Sheets.DataValidation.ErrorStyle.stop);
            sheet.setDataValidator(
                AnalystCfg.FIRST_DATA_ROW,
                AnalystCfg.FIRST_DATA_COL,
                sheet.getRowCount() - AnalystCfg.FIRST_DATA_ROW,
                12,
                nv
            );
        });

        return;
    }

    protected parseUnitTypeScheduleValue(
        currPackage: any,
        year:number
    ):{thisYearUTSV:[], nextYearUTSV:[]}{
        const utsv = currPackage.unitTypeScheduleValues.reduce((acc:any, utsv:any) => {
            if(utsv.version.year == year && utsv.version.type == 'ACTUALS'){
                acc.thisYearUTSV.push(utsv as UnitTypeScheduleValue);
                return acc;
            } else if(utsv.version.year == year && utsv.version.type == 'REFORECAST'){
                acc.thisYearForecastUTSV.push(utsv as UnitTypeScheduleValue);
                return acc;
            } else if(utsv.version.year == year + 1 && utsv.version.type == 'BUDGET'){
                acc.nextYearUTSV.push(utsv as UnitTypeScheduleValue);
                return acc;
            }
            return acc;
        }, {
            thisYearUTSV:<any>[],
            thisYearForecastUTSV:<any>[],
            nextYearUTSV:<any>[],
        });

        // Mask this year actual values, w/ this year reforecast values, based on MonthIndex
        utsv.thisYearForecastUTSV.forEach( (entry: { monthIndex: string | number; }) => {
            utsv.thisYearUTSV[entry.monthIndex] = entry;
        });

        const getValue = (entry: { moveOutRate: number; leaseExpirationCount: number; }):number => {
            return entry.moveOutRate * entry.leaseExpirationCount;
        };

        return {
            thisYearUTSV: utsv.thisYearUTSV.map(getValue),
            nextYearUTSV: utsv.nextYearUTSV.map(getValue),
        };
    }

    // TODO: DRY these up. They can be shared, so should be in a file in helpers/.
    protected addRowTotalsFormulas(row:number, sheet:GC.Spread.Sheets.Worksheet):void{
        // Add row total formula
        sheet.setFormula(
            row,
            AnalystCfg.THIS_YEAR_TOTALS_COL,
            `=SUM(${colCode(AnalystCfg.FIRST_DATA_COL)}${row + 1}:${colCode(AnalystCfg.THIS_YEAR_TOTALS_COL - 1)}${row + 1})`
        );

        // Add row total formula
        sheet.setFormula(
            row,
            AnalystCfg.BUDGET_TOTALS_COL,
            `=SUM(${colCode(AnalystCfg.FIRST_BUDGET_DATA_COL)}${row + 1}:${colCode(AnalystCfg.BUDGET_TOTALS_COL - 1)}${row + 1})`
        );
    }

    protected referenceYearSpan(referenceSheetName:string, row:number, firstCol:number):string[] {
        return new Array(12).fill(null).map(
            (_, idx) => {
                const refCol = colCode(firstCol + idx);
                return `=${referenceSheetName}!${refCol}${row + 1}`;
            }
        );
    }

    protected getPropertyRenosSum(firstCol:number, totalUnits:number):string[]{
        return new Array(12).fill(null).map(
            (_, monthIdx) => {
                const thisColCode = colCode(monthIdx + firstCol);
                return `=${AnalystCfg.UTIL_TAB_NAME}!${thisColCode}${totalUnits + 1}`;
            });
    }

    protected getMetricYearTotals(firstMonthCol:number, totalsCol:number, row:number, metricRow:number):string[]{
        return new Array(row - metricRow + 1).fill(null).map(
            (_, monthIdx) => {
                const firstMonthColCode = colCode(firstMonthCol);
                const totalsColCode = colCode(totalsCol);
                return `=SUM(${firstMonthColCode}${metricRow + monthIdx + 1}:${totalsColCode}${metricRow + monthIdx + 1})`;
            }
        );
    }

    protected totalFormulaToUtilSheet(col: number, rowStart: number, rowEnd: number, utilSheet: string): string {
        const valColCode = colCode(col);
        return `=SUM(${utilSheet}!${valColCode}${rowStart}:${valColCode}${rowEnd})`;
    }

    protected getTotalsFormulaArray(col: number, rowStart: number, rowEnd: number, utilSheet: string):(string|null)[]{
        return new Array(24).fill(null).map(
            (_, monthIdx):(string|null) => {
                return this.totalFormulaToUtilSheet(
                    col + monthIdx,
                    rowStart,
                    rowEnd,
                    utilSheet
                );
            });
    }
}
