import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BudgetingType} from '../../../../BudgetingType';
import {allowRightClickCopy, NewSpreadsheet} from '../../../../components/spreadsheet/NewSpreadsheet';
import {useNewSpreadsheetAPI} from '../../../../components/spreadsheet/NewSpreadsheetHooks';
import {UnitType, useProperties} from '../../../../contexts/properties/PropertiesContext';
import {useSettings} from '../../../../contexts/settings/SettingsContext';
import useGetMonths from '../../../../hooks/UseGetMonths';
import {
    BudgetComponentType,
    EntityType,
    FinancialEntityType,
    FinancialValueO,
    OperationalMetric,
    UnitTypeScheduleValue,
    useGetFinancialValuesLazyQuery,
    useGetForecastLocksLazyQuery,
    useGetRevenueSummaryQuery,
    VersionType
} from '../../../../__generated__/generated_types';
import {
    average,
    DEFAULT_BOLD_STYLE,
    DEFAULT_STYLE,
    PERCENTAGE_BOLD_STYLE_CENTERED,
    PERCENTAGE_STYLE_CENTERED
} from '../base';
import "../operational.scss";
import viewTemplate from "./revenue_summary_view.json";
import ShimmerRenderer, {ShimmerPageKey} from '../../../../components/shimmer/ShimmerRenderer';
import {createRequestVariables} from '../../../../contexts/chartofaccounts/CreateFinancialValuesQueryVariables';
import {useVersions} from '../../../../contexts/versions/VersionsContext';
import {FinancialEntity} from '../../../../contexts/chartofaccounts/ChartOfAccountsContext';
import {weightFnRenewalRate} from '../renewal-rates/renewal-increase/RenewalIncrease';

import * as GC from "@grapecity/spread-sheets";
import {weightFnRenewalRatio} from '../renewal-ratios/RenewalRatios';
import {weightFnMoveOutRate} from '../move-outs/MoveOutRates';
import {weightFnOccupancyRate} from '../occupancy-rates/OccupancyRates';
import {nanSafeParseFloat} from '../../../../utils/excel';
import {Button} from '@zendeskgarden/react-buttons';
import {ReactComponent as Download} from '@zendeskgarden/svg-icons/src/16/download-stroke.svg';
import {useExportExcelHoook} from './export/exportExcelHook';
import {FormatHint} from './export/formatters';
import {useConfig} from '../../../../hooks/useConfig';

import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../../../styles/zendesk-garden/ViziblyZDGTheme";

import {Tabs} from "@zendeskgarden/react-tabs";

import * as workflowCSS from "../../../../styles/workflows/workflowCSS.module.scss";
import * as workflowHeaderCSS from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import {headerRowNoBottomMargin} from "../../../../components/workflows/workflow-header/styles/css.module.scss";

import WorkflowHeader from "../../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowNavToggle from "../../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import WorkflowPageLabel from "../../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../../components/workflows/workflow-step-nav/WorkflowStepNav";

import WorkflowYearTabs, {
    WORKFLOW_YEAR_TAB_BUDGET
} from "../../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import {IWorkflowPageProps, shouldUpdateWorkflowTab} from "../../logic/workflows";


type AverageResult = ReturnType<typeof getAverages>;
interface RowDescriptor {
    title: string;
    key?: keyof AverageResult;
    rentalRevenueCaptureKey?: string;
    defaultStyle: GC.Spread.Sheets.Style,
    defaultTotalStyle: GC.Spread.Sheets.Style,
    aggregate: "SUM" | "AVERAGE"
    formula?: (unitCount: number) => string;
    height?: number;
}

type EntityTypesSubset = EntityType.Account | EntityType.BudgetCategory | EntityType.BudgetComponent;

const ENTITY_TYPE_2_FIN_ENTITY_TYPE: Record<EntityTypesSubset, FinancialEntityType> = {
    [EntityType.Account]: FinancialEntityType.Account,
    [EntityType.BudgetCategory]: FinancialEntityType.Category,
    [EntityType.BudgetComponent]: FinancialEntityType.Component
}

const ROWS: RowDescriptor[] = [
    // { title: "Average Market Rent", key: "averageMarketRents", defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "AVERAGE" },
    // {
    //     title: "In Place Rents",
    //     key: "residentialRents",
    //     defaultStyle: DEFAULT_STYLE,
    //     defaultTotalStyle: DEFAULT_BOLD_STYLE,
    //     aggregate: "AVERAGE"
    // },
    { title: "Expirations", key: "expirations", defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "SUM" },
    { title: "Renewal %", key: "renewalRatios", defaultStyle: PERCENTAGE_STYLE_CENTERED, defaultTotalStyle: PERCENTAGE_BOLD_STYLE_CENTERED, aggregate: "AVERAGE" },
    // { title: "Renewals", formula: () => "=R[-1]C*R[-2]C", defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "SUM" },

    { title: "Renewal Increase", key: "renewalRates", defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "AVERAGE" },

    { title: "Move Out %", key: "moveOutRates", defaultStyle: PERCENTAGE_STYLE_CENTERED, defaultTotalStyle: PERCENTAGE_BOLD_STYLE_CENTERED, aggregate: "AVERAGE" },
    // { title: "Move Outs", key: "moveOutCounts", formula: () => `=R[-1]C*R[-5]C`, defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "SUM" },


    { title: "Early Terminations", key: "earlyTerminationCounts", defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "SUM" },
    { title: "MTM Move Outs", key: "monthToMonthMoveOutCounts", defaultStyle: DEFAULT_STYLE, defaultTotalStyle: DEFAULT_BOLD_STYLE, aggregate: "SUM" },
    {
        title: "Occupancy %",
        key: "occupancyRates",
        defaultStyle: PERCENTAGE_STYLE_CENTERED,
        defaultTotalStyle: PERCENTAGE_BOLD_STYLE_CENTERED,
        aggregate: "AVERAGE"
    },
    {
        title: "Total Move Ins",
        key: "moveIns",
        defaultStyle: DEFAULT_STYLE,
        defaultTotalStyle: DEFAULT_BOLD_STYLE,
        aggregate: "SUM"
    },

];


const MONTHS_ROW = 4, MONTHS_FIRST_COL = 6;
const VIEW_CALCULATED_ROW_COUNT = 5; // 4 calculated plus 1 header row
const UNITS_COUNT_ROW = 1, UNITS_COUNT_COL = 7;
const INPUTS_TAB = "Inputs";
const INPUTS_FIRST_ROW = 1, INPUTS_FIRST_COL = 0; // zero-based
const RENTAL_REVENUE_CAPTURE_KEYS_TAB = "RentalRevenueKeys";
const RENTAL_REVENUE_CAPTURE_FIRST_ROW = 1; // zero-based


function getAverages(unitTypes: UnitTypeScheduleValue[], operational: OperationalMetric[], rentalRevenueCaptures: FinancialValueO[], unitsCount: number) {
    const unitTypeGroups = Object.entries(unitTypes.groupBy(row => row.monthIndex.toString()));
    const operationalMetrics = Object.entries(operational.groupBy(row => row.monthIndex.toString()));

    const expirations = unitTypeGroups.toIdMap(([key]) => key, value => value[1].map(row => row.leaseExpirationCount).sum());

    const renewalRates = unitTypeGroups.toIdMap(([key]) => key, value => average(value[1], "renewalRate", weightFnRenewalRate, "WEIGHTED_AVERAGE"));
    const renewalRatios = unitTypeGroups.toIdMap(([key]) => key, value => average(value[1], "renewalRatio", weightFnRenewalRatio, "WEIGHTED_AVERAGE"));
    const moveOutRates = unitTypeGroups.toIdMap(([key]) => key, value => average(value[1], "moveOutRate", weightFnMoveOutRate, "WEIGHTED_AVERAGE"));
    const moveOutCounts = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => nanSafeParseFloat(row.moveOutCount)).average());
    const totalMoveOuts = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => nanSafeParseFloat(row.totalMoveOutCount)).average());

    const earlyTerminationCounts = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => row.earlyTerminationCount).average());
    const monthToMonthMoveOutCounts = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => row.monthToMonthMoveOutCount).average());
    const occupancyRates = unitTypeGroups.toIdMap(([key]) => key, value => average(value[1], "occupancyRate", weightFnOccupancyRate, "WEIGHTED_AVERAGE"));
    const moveIns = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => nanSafeParseFloat(row.moveInCount)).average());


    const averageMarketRents = unitTypeGroups.toIdMap(([key]) => key, value => value[1].sum(row => (nanSafeParseFloat(row.averageMarketRent) * row.unitCount)) / unitsCount);

    // const averageMarketRents = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => parseFloat(row.averageMarketRent)).average());
    const residentialRents = operationalMetrics.toIdMap(([key]) => key, value => value[1].map(row => nanSafeParseFloat(row.averageResidentialRent)).average());


    const staticRet = {
        averageMarketRents,
        expirations,
        renewalRatios,
        renewalRates,
        moveOutRates,
        moveOutCounts,
        totalMoveOuts,
        earlyTerminationCounts,
        monthToMonthMoveOutCounts,
        occupancyRates,
        moveIns,
        residentialRents,
    };

    let dynamicRet = {};

    const rrcGrouped = rentalRevenueCaptures.groupBy("entityId");

    for (const rrcKey in rrcGrouped) {

        dynamicRet = {
            ...dynamicRet,
            [rrcKey]: rrcGrouped[rrcKey]?.toIdMap("monthIndex", "value")
        };

    }

    return {
        ...staticRet,
        ...dynamicRet,
    };
}

export default function RevenueSummary(props:IWorkflowPageProps): ReactElement {
    const { type } = useParams() as { type: BudgetingType; propertyId: string };
    const { propertyId, unitsCount, unitTypesById } = useProperties();
    const { year, startReforecastMonthIndex } = useSettings();
    const config = useConfig();
    const { getModelVersions } = useVersions();
    const months = useGetMonths();

    const exportExcel = useExportExcelHoook();

    const [tableHasRendered, setTableHasRendered] = React.useState(false);

    const spreadsheet = useNewSpreadsheetAPI();

    const isBudget = type == BudgetingType.BUDGET;
    const currentVersionYear = isBudget ? year + 1 : year;

    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        getForecastLocks({
            variables: {
                propertyId,
                budgetYear: year + 1,
            }
        });
    }, []);

    const { loading, error, data } = useGetRevenueSummaryQuery({
        variables: {
            propertyId,
            versionYear: currentVersionYear,
            versionType: isBudget ? VersionType.Budget : VersionType.Reforecast,
            actualsYear: year,
            budgetYear: year + 1,
            previousVersionYear: isBudget ? year : year - 1,
            previousVersionType: isBudget ? VersionType.Reforecast : VersionType.Actuals
        },
        fetchPolicy: "network-only"
    });
    const [getFinancialValues, { loading: loadingFinancials, data: dataFinancials }] = useGetFinancialValuesLazyQuery();

    useEffect(
        () => {
            if (lockDataLoading) {
                return;
            }

            if (spreadsheet.isSpreadsheetReady && unitsCount != undefined && data != undefined && !loading && dataFinancials != undefined && !loadingFinancials) {
                // ## unitCount monthly value
                // we have unit Count actual values received from Property Management System up until last month before reforecast start
                // yet there is no data as to the months coming forward (in reality we just do not pull this data but let's not overcomplicate)
                // unit Count is needed for calculating weighted average for certain metrics aggregating unit type level values to the property level
                // Hence we make an assumption that unit Count does not change and use last known (pre reforecast month) unit count as value for all the remaining
                // months in the budgeting season
                let unitCountYear = year;
                let unitCountMonth = startReforecastMonthIndex - 1;
                if (unitCountMonth < 0) {
                    unitCountYear = year - 1;
                    unitCountMonth = 11;
                }
                const unitCountLastKnownActualPerUnitType = data.actuals
                    .find(row => row.year == unitCountYear)
                    ?.unitTypeScheduleValues
                    ?.filter(row => row.monthIndex == unitCountMonth)
                    ?.toIdMap(row => row.unitType.id, row => row.unitCount);
                let previousVersionUpdated = data.previousVersion;
                if (data.previousVersion.firstElement?.type && data.previousVersion.firstElement?.type != VersionType.Actuals) {
                    previousVersionUpdated = data.previousVersion
                    .map(row => ({
                        ...row,
                        unitTypeScheduleValues: row.unitTypeScheduleValues.map(utsv => {
                            const utsvCopy = {
                                ...utsv,
                                unitType: {
                                    ...utsv.unitType
                                }
                            };
                            const unitCountUnitTypeValue = unitCountLastKnownActualPerUnitType?.[utsv.unitType.id];
                            if (row.type == VersionType.Reforecast || row.type == VersionType.Budget) {
                                if (unitCountUnitTypeValue != undefined) {
                                    utsvCopy.unitCount = unitCountUnitTypeValue;
                                }
                            }

                            return utsvCopy;
                        })
                    }));
                }
                const currentVersionUpdated = data.currentVersion
                    .map(row => ({
                        ...row,
                        unitTypeScheduleValues: row.unitTypeScheduleValues.map(utsv => {
                            const utsvCopy = {
                                ...utsv,
                                unitType: {
                                    ...utsv.unitType
                                }
                            };
                            const unitCountUnitTypeValue = unitCountLastKnownActualPerUnitType?.[utsv.unitType.id];
                            if (row.type == VersionType.Reforecast || row.type == VersionType.Budget) {
                                if (unitCountUnitTypeValue != undefined) {
                                    utsvCopy.unitCount = unitCountUnitTypeValue;
                                }
                            }
                            return utsvCopy;
                        })
                    }));


                spreadsheet.api.setTemplate({ template: viewTemplate });
                spreadsheet.api.setColumnVisible({ colIdx: MONTHS_FIRST_COL - 1, isVisible: false });
                spreadsheet.api.setValue({ row: UNITS_COUNT_ROW, col: UNITS_COUNT_COL, value: unitsCount });
                spreadsheet.api.setArray({ row: MONTHS_ROW, col: MONTHS_FIRST_COL, array: [months] });

                const currentVersion = currentVersionUpdated.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? currentVersionUpdated.firstElement;
                const previousVersion = {
                    ...previousVersionUpdated.firstElement,
                    operationalMetrics: previousVersionUpdated.firstElement.operationalMetrics.map(m => ({ ...m, monthIndex: -1 })),
                    unitTypeScheduleValues: previousVersionUpdated.firstElement.unitTypeScheduleValues.map(m => ({ ...m, monthIndex: -1 }))
                };
                const { actualVersionId, reforecastVersionId, nextYearBudgetVersionId } = getModelVersions(year);
                const actuals = data.actuals.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? data.actuals.firstElement;

                const ROWS_FULL = ROWS.concat(data.rentalRevenueCaptures.sortBy("order")
                        .filter(row => row.accountV2Id != undefined && row.accountV2Id != null)
                        .map(row => ({
                    title: row.displayName,
                    rentalRevenueCaptureKey: row.accountV2Id ?? "accountV2Id undefined",
                    defaultStyle: DEFAULT_STYLE,
                    defaultTotalStyle: DEFAULT_BOLD_STYLE,
                    aggregate: "SUM"
                })));

                const rentalRevenueCaptureKeys = data.rentalRevenueCaptures.map(row => row.accountV2Id ?? "accountV2Id undefined");

                const actualAverages = getAverages(
                    [...previousVersion.unitTypeScheduleValues, ...actuals.unitTypeScheduleValues] as UnitTypeScheduleValue[],
                    [...previousVersion.operationalMetrics, ...actuals.operationalMetrics] as OperationalMetric[],
                    dataFinancials.financialValues.financialValues.filter(row => row.versionId == actualVersionId) as FinancialValueO[],
                    unitsCount
                );
                const currentAverages = getAverages(
                    [...previousVersion.unitTypeScheduleValues, ...currentVersion.unitTypeScheduleValues] as UnitTypeScheduleValue[],
                    [...previousVersion.operationalMetrics, ...currentVersion.operationalMetrics] as OperationalMetric[],
                    dataFinancials.financialValues.financialValues.filter(row => row.versionId == (type == BudgetingType.BUDGET ? nextYearBudgetVersionId : reforecastVersionId)) as FinancialValueO[],
                    unitsCount
                );

                const dataArray: (string | number | null)[][] = ROWS_FULL.map(row => {
                    return [
                        row.key ?? row.rentalRevenueCaptureKey ?? "",
                        row.title,
                        ...new Array(13).fill(null).map((_, idx) => {
                            const monthIndex = idx - 1;
                            const shouldShowActuals = monthIndex < startReforecastMonthIndex && type !== BudgetingType.BUDGET;
                            const values = (shouldShowActuals ? actualAverages : currentAverages);

                            return values[row.key as keyof AverageResult]?.[monthIndex] ?? values[row.rentalRevenueCaptureKey as keyof AverageResult]?.[monthIndex] ?? 0;
                        }),
                    ];
                });

                spreadsheet.api.setArray({ row: INPUTS_FIRST_ROW, col: INPUTS_FIRST_COL, array: dataArray, sheetName: INPUTS_TAB });

                spreadsheet.api.setArray({ row: RENTAL_REVENUE_CAPTURE_FIRST_ROW, col: 0, array: rentalRevenueCaptureKeys, sheetName: RENTAL_REVENUE_CAPTURE_KEYS_TAB });

                spreadsheet.api.showRows({ startRow: MONTHS_ROW, rows: ROWS_FULL.length + VIEW_CALCULATED_ROW_COUNT });

                spreadsheet.api.recalculate();

                spreadsheet.api.directAccess(spread => {
                    spread.options.allowUserDragDrop = false;
                    spread.options.showDragFillSmartTag = false;
                    allowRightClickCopy(spread);
                });

            }
        },
        [spreadsheet.isSpreadsheetReady, unitsCount, data, loading, dataFinancials, loadingFinancials, lockDataLoading]
    );

    useEffect(() => {
        if (!data) return;
        if (data && !loading) {
            getFinancialValues({
                variables: createRequestVariables(data.rentalRevenueCaptures.map(row => {
                    const fe: FinancialEntity = {
                        id: row.accountV2Id ?? "accountV2Id undefined",
                        name: row.displayName,
                        number: "",
                        order: row.order,
                        budgetComponentType: BudgetComponentType.Undefined,
                        type: ENTITY_TYPE_2_FIN_ENTITY_TYPE[row.entityType as EntityTypesSubset] ?? FinancialEntityType.Account,
                        children: [],
                        parentId: undefined // TODO: parentId makes no sence in this context
                    };

                    return fe;

                }), propertyId, startReforecastMonthIndex, year, getModelVersions)
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => { };
    }, [data]);

    useEffect(
        () => {
            if (!dataFinancials) {
                return;
            }

            if (dataFinancials && !loadingFinancials) {
                setTableHasRendered(true);
            }
        },
        [dataFinancials]
    );

    const exportToXLSX = () => {
        if (!spreadsheet.isSpreadsheetReady) {
            return;
        }

        spreadsheet.api.directAccess(spread => {
            const dataRows = spread.getActiveSheet().getArray(5, 4, 12, 15, false) as string[][];
            exportExcel(
                type,
                dataRows.map(row => {
                    let formatHint = FormatHint.NUMBER;
                    if (row[0]?.includes("%")) {
                        formatHint = FormatHint.PERCENT;
                    } else if (row[0]?.includes("Move In")) {
                        formatHint = FormatHint.DECIMALS;
                    } else if(row[0]?.includes("Move Out") && !row[0]?.includes("MTM Move Out")) {
                        formatHint = FormatHint.DECIMALS;
                    }
                    return {
                        elements: [row[0] ?? "",...row.slice(2) ?? []],
                        formatHint: formatHint
                    };
                }),
                config
            );
        });
    };

    /**
     * Update selected tab, which updates display, on type change
     */
    useEffect(
            () => {
                const updateWorkflowTab = shouldUpdateWorkflowTab(type, selectedTab);
                if(updateWorkflowTab != false){
                    setSelectedTab(updateWorkflowTab as string);
                }
            },
            [type]
    );

    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_BUDGET);
    const [accountDataLoading, setAccountDataLoading] = useState<boolean>(true);

    useEffect(
            () => {
                if(!tableHasRendered){
                    return;
                }
                setAccountDataLoading(false);
            },
            [tableHasRendered]
    );

    return (
            <ThemeProvider theme={ViziblyTheme}>
                {/* TODO: Update to <WorkflowLoadingOverlay loading={accountDataLoading}/> */}
                {accountDataLoading ? <ShimmerRenderer modal={true} pageKey={ShimmerPageKey.ACCOUNT_VIEW}  /> : null}
                <div className={workflowCSS.workflowContainer}>
                    <WorkflowHeader className={headerRowNoBottomMargin}>
                        <WorkflowHeader.LeftCol>
                            <div className={workflowHeaderCSS.rowItemsContainer}>
                                <WorkflowNavToggle/>
                                <WorkflowPageLabel label="Revenue Summary"/>
                            </div>
                        </WorkflowHeader.LeftCol>
                        <WorkflowHeader.RightCol>
                            <div className={workflowHeaderCSS.columnItemsContainer}>
                                <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick}/>
                                <div style={{textAlign: 'right'}}>
                                    <Button onClick={() => exportToXLSX()}>
                                        <Button.StartIcon>
                                            <Download />
                                        </Button.StartIcon>
                                        Export
                                    </Button>
                                </div>
                            </div>
                        </WorkflowHeader.RightCol>
                    </WorkflowHeader>
                    <div className={workflowCSS.bodyContainer}>
                        <Tabs selectedItem={selectedTab} onChange={setSelectedTab} className={workflowCSS.accountYearTabNav}>
                            <WorkflowYearTabs year={year}
                                selectedTab={selectedTab}
                                rawForecastLocks={dataForecastLocks}
                            />

                            {/*
                                        TODO: Improve data service, load both tabs and financials at once.
                                        Then, update content and replace w/ Tab panels (e.g. workflows/account/Account.tsx)
                                        https://vizibly.atlassian.net/browse/APP-936
                                    */}
                            <div className={workflowCSS.tabContent}>
                                <div className={workflowCSS.dataColumn}>
                                    <div className={`${workflowCSS.accountTableContainer}`}>
                                        {(!lockDataLoading && dataForecastLocks?.forecastLocks?.property) &&
                                            <NewSpreadsheet
                                                    startRow={MONTHS_ROW}
                                                    startCol={MONTHS_FIRST_COL - 2}
                                                    rows={19}
                                                    cols={15}
                                                    handlers={spreadsheet.handlers}
                                                    subscribeToMouse={false}
                                                    readOnly={
                                                        type === BudgetingType.REFORECAST ? dataForecastLocks.forecastLocks.property.reforecastLocked : dataForecastLocks.forecastLocks.property.budgetLocked
                                                    }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </ThemeProvider>
    );
}
