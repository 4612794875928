import { ReactElement, useEffect, useRef, useState } from "react";
import { formatterEnumToTitle } from "../../../../utils/formatters";
import { TopCardTagType } from "../../../../__generated__/generated_types";
import { Dropdown, Field, Item, Menu, Multiselect } from "@zendeskgarden/react-dropdowns";
import { Tag } from "@zendeskgarden/react-tags";
import Downshift from "downshift";

import * as css from "../styles/topCardTagSettings.module.scss";
import { FinancialEntity } from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";

export interface ITopCardTagRowProps {
    type: TopCardTagType,
    accounts: FinancialEntity[],
    selectedAccounts: string[],
    onChangeCallback: (accountIds: string[], topCardTagType: TopCardTagType) => void,
}

export default function TopCardTagRow(props: ITopCardTagRowProps): ReactElement {
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState<FinancialEntity[]>(props.accounts);
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>(props.selectedAccounts);
    const initialized = useRef(false);

    const stateReducer = (state: any, changes: any) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                };
            default:
                return changes;
        }
    };

    useEffect(
        () => {
            const matchedOptions = props.accounts.filter(option => {
                return option.name.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1;
            });

            setMatchingOptions(matchedOptions);
        }, [inputValue]
    );

    useEffect(
        () => {
            if (selectedAccounts == undefined || props.selectedAccounts == undefined) {
                return;
            }

            if (!initialized.current) {
                initialized.current = true;
                return;
            }

            props.onChangeCallback(selectedAccounts, props.type);
        }, [selectedAccounts]
    );

    const renderOptions = () => {
        if (inputValue == null || inputValue == '') {
            return props.accounts.map(option => (
                <Item key={option.id} value={option.id}>
                    <span>{option.name}</span>
                </Item>
            ));
        }

        if (matchingOptions.length === 0) {
            return <Item disabled>No account found</Item>;
        }

        return matchingOptions.map(option => (
            <Item key={option.id} value={option.id}>
                <span>{option.name}</span>
            </Item>
        ));
    };

    return (
        <div className={css.settingsTableRow}>
            <div className={css.settingsTableRowColumn}>{formatterEnumToTitle(props.type)}</div>
            <div className={css.settingsTableRowColumn}>
                <Dropdown
                    inputValue={inputValue}
                    selectedItems={selectedAccounts}
                    onSelect={items => {
                        setSelectedAccounts(items);
                    }}
                    downshiftProps={{ defaultHighlightedIndex: 0, stateReducer }}
                    onInputValueChange={value => setInputValue(value)}
                >
                    <Field>
                        <Multiselect
                            isCompact
                            placeholder={"Select GL Accounts"}
                            renderItem={({ value, removeValue }) => (
                                <Tag>
                                    <span>{props.accounts.find(x => x.id == value)?.name}</span>
                                    <Tag.Close onClick={removeValue}
                                    />
                                </Tag>
                            )}
                        />
                    </Field>
                    <Menu>
                        {renderOptions()}
                    </Menu>
                </Dropdown>
            </div>
        </div>
    );
}