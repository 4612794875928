import { ReactElement, useRef, useState } from "react";
import { Field as FormField, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";

export type InputFieldProps = {
    title: string,
    value: number | undefined,
    setValue: (val: number | undefined) => void,
    disabled?: boolean
}

export function InputField(props: InputFieldProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string>();
    return (
        <FormField>
            <Label>{props.title}</Label>
            <div style={{display: "flex", flexDirection: "row"}}>
                <Input
                    placeholder="No change"
                    type={"number"}
                    defaultValue={props.value}
                    ref={inputRef}
                    disabled={props.disabled}
                    onBlur={(evt) => {
                        const newVal = Number.parseFloat(evt.target.value);
                        if (!Number.isNaN(newVal)) {
                            props.setValue(newVal);
                            setError(undefined);
                        }
                        else if (evt.target.value != ""){
                            setError("Must be a number or empty");
                        }
                    }}
                    validation={error ? "error" : undefined}
                />
                <Button
                    onClick={() => {
                        props.setValue(undefined);
                        setError(undefined);
                        if (inputRef.current) {
                            inputRef.current.value = "";
                        }
                    }}
                    disabled={props.disabled}
                >X</Button>
            </div>
            {
            error &&
            <Message validation="error">{error}</Message>
            }

        </FormField>

    );
}