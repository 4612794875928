import { ReactElement, useEffect, useState } from "react";
import { Dropdown, Multiselect, Field, Menu, Item } from '@zendeskgarden/react-dropdowns';
import { ReactComponent as LockIcon } from '@zendeskgarden/svg-icons/src/16/lock-locked-stroke.svg';
import { Property } from "../../../contexts/properties/PropertiesContext";
import Downshift from "downshift";
import { Button } from "@zendeskgarden/react-buttons";
import * as css from "../styles/bulkUpdate.module.scss";
import { Tag } from "@zendeskgarden/react-tags";

export interface IPropertyDropdownProps {
    selectedProperties: string[];
    properties: Property[];
    lockedProperties: {
        reforecastLocked: string[],
        budgetLocked: string[]
    };
    showLockTags?: boolean;
    onPropertySelect: (selected: string[]) => void;
}

export default function PropertyDropdown({selectedProperties, properties, lockedProperties, showLockTags, onPropertySelect}: IPropertyDropdownProps): ReactElement {
    const [inputValue, setInputValue] = useState("");
    const [filteredProperties, setFilteredProperties] = useState<Property[]>(properties);

    useEffect(() => {
        setFilteredProperties(properties.filter(p => p.name.toLowerCase().includes(inputValue.trim().toLowerCase())))
    }, [inputValue])

    const stateReducer = (state: any, changes: any) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                };
            default:
                return changes;
        }
    };

    return (
        <Dropdown
            inputValue={inputValue}
            selectedItems={selectedProperties}
            onSelect={items => onPropertySelect(items)}
            downshiftProps={{ defaultHighlightedIndex: 0, stateReducer }}
            onInputValueChange={value => setInputValue(value)}
        >
            <Field>
                <Multiselect
                    placeholder="Select Properties"
                    renderItem={({ value, removeValue }: any) => (
                        <Tag>
                            <span>{properties.find(p => p.id == value)?.name}</span>
                            <Tag.Close onClick={() => removeValue()} />
                        </Tag>
                    )}
                />
            </Field>
            <Menu>
                {inputValue == "" &&
                    <Button
                        className={css.propertyDropdownSelectAll}
                        isBasic
                        onClick={() => {
                            onPropertySelect(
                                selectedProperties.length === properties.length ? [] : properties.map(p => p.id)
                            );
                        }}
                    >
                        {selectedProperties.length === properties.length ? "Deselect All" : "Select All"}
                    </Button>
                }

                {(inputValue != "" && filteredProperties.length == 0) &&
                    <Item className={css.propertyDropdownItem} disabled>No items found</Item>
                }

                {filteredProperties.map(p => (
                    <Item
                        key={p.id}
                        value={p.id}
                        className={css.propertyDropdownItem}
                    >
                        <span>{p.name}</span>
                        {showLockTags &&
                            <div className={css.propertyDropdownLockedTags}>
                                <Tag className={`${css.tag} ${lockedProperties.reforecastLocked.includes(p.id) ? css.isActive : ""}`}>
                                    <Tag.Avatar>
                                        <LockIcon />
                                    </Tag.Avatar>
                                    <span>Reforecast</span>
                                </Tag>

                                <Tag className={`${css.tag} ${lockedProperties.budgetLocked.includes(p.id) ? css.isActive : ""}`}>
                                    <Tag.Avatar>
                                        <LockIcon />
                                    </Tag.Avatar>
                                    <span>Budget</span>
                                </Tag>
                            </div>
                        }
                    </Item>
                ))}
            </Menu>
        </Dropdown>
    );
}
